import React, { useContext, useEffect, useState, useRef } from 'react';
import {
    Spinner,
    Image,
    Text, 
    Box, 
    Center, 
    Flex, 
    Link, 
    Tag,
    Avatar,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    List,
    ListItem,
} from "@chakra-ui/react"

import Header from '../Header';
import useUser from '../../context/useUser';
import useToken from '../useToken';
import { useQuery, useMutation, useQueryClient} from 'react-query'
import axios from 'axios';
import {useParams} from "react-router-dom";
import {BsFilePdf} from 'react-icons/bs'

import {GoPrimitiveDot} from 'react-icons/go'

import Moment from 'react-moment';
import 'moment-timezone';


import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses  } from '@mui/base/TabUnstyled';
import { motion, AnimatePresence } from "framer-motion"
import * as AWS from 'aws-sdk'
import {BiSlideshow} from 'react-icons/bi'
import {GrAttachment} from 'react-icons/gr'
import {IoMdAdd, IoMdHelpCircle, IoMdSend, IoMdShareAlt} from 'react-icons/io'
import {GiConsoleController, GiOrganigram} from 'react-icons/gi'
import {BsTelephoneFill} from 'react-icons/bs'
import {FaWhatsappSquare} from 'react-icons/fa'
import { nanoid } from 'nanoid';
import { now } from 'moment';
import HomePic from '../../assets/home.svg'
import Empty from '../../assets/empty.svg'
import Botones from '../Cliente/Buttons'
import {RenderIf} from '../../hooks/renderIf'
import { connect } from 'react-redux';
import { get_contacto, get_proyecto, get_presentaciones, get_propuestas, get_contratos } from '../../actions/index'
import { DataContext } from '../../context/DataContext';
 //AWS.config.update({ region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' }); //DESARROLLO
AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIARVGY33F62XNKJJK3' }); //Producción
//AWS.config.update({ region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' });
const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: '2022-01-01' })


  const gray = {
    50: '#EDF2F7',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923'
  };

  
  const Tab = styled(TabUnstyled)`
    font-family: system-ui, sans-serif;
    color: ${gray[600]};
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    padding: 12px 16px;
    margin: 6px 6px;
    border: none;
    border-radius: 25px;
    display: flex;
    justify-content: center;
  
    &:hover {
      background-color: ${gray[400]};
      color: white;
    }
  
    &:focus {
      color: #fff;
      border-radius: 25px;
      outline: 2px solid ${gray[300]};
      outline-offset: 2px;
    }
  
    &.${tabUnstyledClasses.selected} {
      background-color: ${gray[400]};
      color: white;
    }
  
    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;
  
  const TabPanel = styled(TabPanelUnstyled)`
    width: 100%;
    font-family: system-ui, sans-serif;
    font-size: 0.875rem;
  `;
  
  const TabsList = styled(TabsListUnstyled)`
    min-width: 320px;
    background-color: ${gray[50]};
    border-radius: 25px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
  `;

const _Home = (props) => {
    let { id, token, proyectoParametro, nombre, proyecto, proyectoId, vendedor, meid, vendedorName} = useParams();
    const { user, setUser } = useUser();
    const form = useRef();
    const [cliente, setCliente] = useState();
    const queryClient = useQueryClient();
    const [datos, setDatos] = useState([])
    const {
        isOpen: isOrganigramaModalOpen,
        onOpen: onOpenOrganigramaModal,
        onClose: onCloseOrganigramaModal,
      } = useDisclosure();
    const { isOpen: isHelpOpen, onToggle } = useDisclosure()
    const { isOpen: isShareOpen, onToggle: onToggleShare } = useDisclosure()
    const [proyectoData, setProyectoData] = useState([])
    const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI)
    //const { token, setToken } = useToken();
   
    const [datos_c, setDatos_c] = useState([])
    const [datos_d, setDatos_d] = useState([])
    const [datos_e, setDatos_e] = useState([])
    const [datos_f, setDatos_f] = useState([])
    const [datos_archivos, setDatos_archivos] = useState([])
    const [datos_propuestas, setDatos_propuestas] = useState([])
    const [datos_videos, setDatos_videos] = useState([])
    const [datos_videos_menu, setDatos_videos_menu] = useState([])
    const [mensaje, setMensaje] = useState()
    const [notificacion, setNotificacion] = useState()
    const [to, setTo] = useState()
    const [value, setValue] = useState()
    const [color, setColor] = useState('blue.50')
    const [colorBoton, setColorBoton] = useState('blue')
    const [openProyect, setOpenProyect] = useState(proyecto)
    
    const [fuente] = useState("ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji")
    const { proyectoRData, presentacionRData, propuestaRData, contratosRData } =  props || {};
    const { dataContacto } = useContext(DataContext)
    
    function getQueryParams(qs) {
        qs = qs.split('+').join(' ');
    
        var params = {},
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;
    
        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }
    
        return params;
    }
    async function fetchClientes() {
        
        const clientes = fetch(urlStrapi + '/clientes/' + proyectoId)
        .then(response => response.json())
        //console.log("[CLIENTES]", clientes);
        return clientes
    }


    async function fetchVendedor() {
        const query = getQueryParams(document.location.search);
        const vendedor = query.token.substring(query.token.indexOf('*') + 1)
        const ven = vendedor.split('-')[0]
        //console.log("vendedor a :", ven);
        const clientes = fetch(urlStrapi + '/users/' + ven)
        .then(response => response.json())
        //console.log(clientes);
        return clientes
    }

    // async function fetchProyectos() {
      
    //         const query = getQueryParams(document.location.search);
    //         console.log("parametro:",  query.token.substring(query.token.indexOf('_') + 1));
    //         console.log("parametroProyecto:",  query.proyecto);
    //         //hJsb8vmBsBhQ9bPq3KLIy
    //         //const urlC = `${urlCassandra}collections/proyectos?where={"token":{"$eq":"${query.token.split('_')[0]}"}}&page-size=20`
            
    //         const proyecto = query.token.substring(query.token.indexOf('*') + 1).replace(",", "")
            
            
    //         const urlC = `${urlCassandra}collections/proyectos${proyecto}/${query.proyecto}?page-size=20`
    //         const ventasData = await axios.get(urlC, {
    //             method: 'GET',
    //             headers: {
    //                 'accept': 'application/json',
    //                 'Content-Type': 'application/json',
    //                 'X-Cassandra-Token': tokenCasandra
    //             }
    //         })
    //         const proyectos =  await Object.keys(ventasData.data.data).map((item) => ({id: item, ...ventasData.data.data[item]}))
    //         console.log("Proyectos", proyectos)
    //         return proyectos

       
    // }
    async function fetchProyectos() {
        // const query = getQueryParams(document.location.search);
        // console.log("QueryProyecto:", proyecto)
    
        // try {
        //   var params = {
        //       TableName: 'Proyectos',
        //       FilterExpression: "#id = :id",
        //       ExpressionAttributeNames: {
        //           "#id": "id",
        //       },
        //       ExpressionAttributeValues: {
        //           ":id": proyecto
        //       }
        //   }
  
  
        //   docClient.scan(params, function (err, data) {
        //       if (err) {
        //           console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        //       } else {
        //           console.log("Query succeeded--PROYECTOS.", data.Items[0]);
        //          // setDatos(data.Items[0])
        //          setProyectoData(data.Items[0])
        //       }
        //   });
        // } catch (error) {
        //   console.log("error")
        // }
              
        props.get_proyecto(proyecto)
         
        
      }
    useEffect(() => {
        
        //setCliente(id);
        setTimeout(()=>{
            const query = getQueryParams(document.location.search);
            //setTo(query.to)
            console.log(query)
        },5000)
        
        


    } , [])

    const fetchChat = () => {
        //console.log("proyecto1:", proyecto)
        var params = {
            TableName: 'Chat',
            FilterExpression: "#nom > :name",
             ExpressionAttributeNames: {
                 "#nom": "timestamp",
             },
             ExpressionAttributeValues: {
                 ":name": 1609459200
             },
             ScanIndexForward: false
        }
       
        docClient.scan(params, function(err, data) {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                console.log("Query succeeded.");
                
                // data.Items.forEach(function(item) {
                    const sortedActivities = data.Items.slice().sort((a, b) =>  a.timestamp - b.timestamp)
                    setDatos_e(sortedActivities)
                console.log(sortedActivities);
                    
                // });
            }
        });
    }

    const fetchNotificacion = () => {
        //console.log("proyecto1:", proyecto)
        var params = {
            TableName: 'Notificaciones',
            //  FilterExpression: "#nom != null",
            //  ExpressionAttributeNames: {
            //      "#nom": "nombre",
            //  },
            //  ExpressionAttributeValues: {
            //      ":name": id
            //  }
        }
        docClient.scan(params, function(err, data) {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                console.log("Query succeeded.");
                setDatos_f(data.Items)
                // data.Items.forEach(function(item) {
                //console.log(data.Items);
                    
                // });
            }
        });
    }

    const fetchContactos = () => {
        //console.log("proyecto1:", proyecto)
        var params = {
            TableName: 'Contactos',
            //  FilterExpression: "#nom != null",
            //  ExpressionAttributeNames: {
            //      "#nom": "nombre",
            //  },
            //  ExpressionAttributeValues: {
            //      ":name": id
            //  }
        }
        docClient.scan(params, function(err, data) {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                console.log("Query succeeded.");
                setDatos_d(data.Items)
                // data.Items.forEach(function(item) {
                //console.log(data.Items);
                    
                // });
            }
        });
    }

    const fetchData = () => {
        //const query = getQueryParams(document.location.search);
        //console.log("Query:", query)
        //const proyectoRsul = query.token.substring(query.token.indexOf(',') + 1)
        console.log("proyecto1:", proyectoId)



        // var params = {
        //     TableName: 'Presentaciones',
        //      FilterExpression: "#nom = :name and #estado = :estado and #proyecto = :proyecto",
        //      ExpressionAttributeNames: {
        //          "#nom": "cliente",
        //          "#estado": "estatus",
        //          "#proyecto": "proyecto"
        //      },
        //      ExpressionAttributeValues: {
        //          ":name": proyectoId,
        //          ":estado": "visible",
        //          ":proyecto": proyecto
        //      }
        // }
        // docClient.scan(params, function(err, data) {
        //     if (err) {
        //         console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        //     } else {
        //         console.log("Query succeeded.", data.Items);
        //         setDatos_c(data.Items)
        //     }
        // });

        props.get_presentaciones(proyectoId, proyecto)

    }
    const fetchDataPropuestas = () => {
        // const query = getQueryParams(document.location.search);
        // console.log("Query:", query)
        // const proyecto = query.token.substring(query.token.indexOf(',') + 1)
        // console.log("proyecto1:", proyecto)
        // var params = {
        //     TableName: 'Propuestas',
        //      FilterExpression: "#nom = :name and #estado = :estado and #proyecto = :proyecto",
        //      ExpressionAttributeNames: {
        //          "#nom": "cliente",
        //          "#estado": "estatus",
        //          "#proyecto": "proyecto"
        //      },
        //      ExpressionAttributeValues: {
        //          ":name": proyectoId,
        //          ":estado": "visible",
        //          ":proyecto": proyecto
        //      }
        // }
        // docClient.scan(params, function(err, data) {
        //     if (err) {
        //         console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        //     } else {
        //         console.log("Query succeeded.", data.Items);
        //         setDatos_propuestas(data.Items)
        //     }
        // });

        props.get_propuestas(proyectoId, proyecto)

    }
    const fetchDataContratos = () => {
        props.get_contratos(proyectoId, proyecto)
    }
    const fetchDataVideos = () => {
        // const query = getQueryParams(document.location.search);
        // console.log("Query:", query)
        // const proyecto = query.token.substring(query.token.indexOf(',') + 1)
        // console.log("proyecto1:", proyecto)
        var params = {
            TableName: 'Media',
             FilterExpression: "#nom = :name and #estado = :estado and #proyecto = :proyecto",
             ExpressionAttributeNames: {
                 "#nom": "cliente",
                 "#estado": "estatus",
                 "#proyecto": "proyecto"
             },
             ExpressionAttributeValues: {
                 ":name": proyectoId,
                 ":estado": "visible",
                 ":proyecto": proyecto
             }
        }
        docClient.scan(params, function(err, data) {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                console.log("Query succeeded.", data.Items);
                setDatos_videos(data.Items)
            }
        });
    }


    const fetchDataVideosEstructura = () => {

         //console.log("PROYECTO", proyectoId, proyecto)
        var params = {
            TableName: 'Video_Menu_Proyecto',
             FilterExpression: "#nom = :name and #estado = :estado and #proyecto = :proyecto",
             ExpressionAttributeNames: {
                 "#nom": "cliente",
                 "#estado": "estatus",
                 "#proyecto": "proyecto"
             },
             ExpressionAttributeValues: {
                 ":name": proyectoId,
                 ":estado": "visible",
                 ":proyecto": proyecto
             }
        }
        docClient.scan(params, function(err, data) {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                //console.log("Query succeeded.", data.Items);
                setDatos_videos_menu(data.Items)
            }
        });
    }


    const fetchDataArchivos = () => {
        // const query = getQueryParams(document.location.search);
        // console.log("Query:", query)
        // const proyecto = query.token.substring(query.token.indexOf(',') + 1)
        // console.log("proyecto1:", proyecto)
        var params = {
            TableName: 'archivos',
             FilterExpression: "#nom = :name and #estado = :estado  and #proyecto = :proyecto",
             ExpressionAttributeNames: {
                 "#nom": "cliente",
                 "#estado": "estatus",
                 "#proyecto": "proyecto"
             },
             ExpressionAttributeValues: {
                 ":name": proyectoId,
                 ":estado": "visible",
                 ":proyecto": proyecto
             }
        }
        docClient.scan(params, function(err, data) {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                console.log("Query succeeded.", data.Items);
                setDatos_archivos(data.Items)
            }
        });
    }
    

    const putData = (tableName , data) => {
        var params = {
            TableName: tableName,
            Item: data
        }
        
        docClient.put(params, function (err, data) {
            if (err) {
                console.log('Error', err)
            } else {
                //console.log('Success', data)
               //refetch_b()
               enviarNotificaciones()
               setMensaje("")
               
            }
        })
    }

    const addChat = async (ids, nombre) => {
        console.log( "id:", ids , "nombre:", nombre)
        const chatData = {
            id: nanoid(),
            fechaCreacion: new Date().toISOString(),
            timestamp: Date.now(),
            proyecto: ids,
            nombreCliente: nombre,
            mensaje: mensaje
        }

        await putData('Chat', chatData)

    }

    const putData_a = (tableName , data) => {
        var params = {
            TableName: tableName,
            Item: data
        }
        
        docClient.put(params, function (err, data) {
            if (err) {
                console.log('Error', err)
            } else {
                //console.log('Success', data)
               refetch_c()

               
            }
        })
    }

    const addNotificacion = async (ids, nombre) => {
        console.log( "id:", ids , "nombre:", nombre)
        const notificacionesData = {
            id: nanoid(),
            fechaCreacion: new Date().toISOString(),
            proyecto: ids,
            nombreCliente: nombre,
            notificacion: mensaje,
            url: "url",
            type: "Cliente",
            componente: "Chat"
        }

        await putData_a('Notificaciones', notificacionesData)

    }


    const putData_b = (tableName , data) => {
        var params = {
            TableName: tableName,
            Item: data
        }
        
        docClient.put(params, function (err, data) {
            if (err) {
                console.log('Error', err)
            } else {
                //console.log('Success', data)
               refetch_c()

               
            }
        })
    }

    const updatePresentacion = async (idp) => {
        const query = getQueryParams(document.location.search);
        console.log("Query:", query)
        const proyecto = query.token.substring(query.token.indexOf(',') + 1)
        const vendedor = query.token.substring(query.token.indexOf('*') + 1)
        const ven = vendedor.split(',')[0]
            const presentacionData = {
                id: nanoid(),
                presentacionId: idp,
                proyectoId: proyecto,
                open: true,
                vendedor: vendedor,
                fechaApertura: Date.now(),
                tipo: "Presentacion"
            }
            putData_b('Bitacora', presentacionData)
    }
    const updatePresentacionInicio = async (data) => {
        // const query = getQueryParams(document.location.search);
        // const vendedor = query.token.substring(query.token.indexOf('*') + 1)
        // const ven = vendedor.split(',')[0]
        // console.log("Vendedor:", ven)
        // const proyecto = query.token.substring(query.token.indexOf(',') + 1)
            const presentacionData = {
                id: nanoid(),
                proyectoId: proyecto,
                open: true,
                fechaApertura: Date.now(),
                vendedor: vendedorName,
                tipo: "Proyecto",
                correo: meid
            }
            putData_b('Bitacora', presentacionData)
    }
    const updatePropuesta = async (id) => {
        // const query = getQueryParams(document.location.search);
        // const vendedor = query.token.substring(query.token.indexOf('*') + 1)
        // const ven = vendedor.split(',')[0]
        // console.log("Vendedor:", ven)
        // const proyecto = query.token.substring(query.token.indexOf(',') + 1)
            const propuestaData = {
                id: id,
                proyectoId: proyecto,
                open: true,
                fechaApertura: Date.now(),
                vendedor: vendedor,
                tipo: "Propuesta"
            }
            putData_b('Bitacora', propuestaData)
    }
    const updateContrato = async (id) => {
        // const query = getQueryParams(document.location.search);
        // const vendedor = query.token.substring(query.token.indexOf('*') + 1)
        // const ven = vendedor.split(',')[0]
        // console.log("Vendedor:", ven)
        // const proyecto = query.token.substring(query.token.indexOf(',') + 1)
            const contratoData = {
                id: id,
                proyectoId: proyecto,
                open: true,
                fechaApertura: Date.now(),
                vendedor: vendedor,
                tipo: "Contrato"
            }
            putData_b('Bitacora', contratoData)
    }
    
    function actualizaPresentacion(idp){
        mutation_b.mutate(idp)
    }
    function actualizaPropuesta(idp){
        mutation_d.mutate(idp)
    }
    function actualizaContrato(idp){
        mutation_e.mutate(idp)
    }
    function actualizaPresentacionInicio(){
        mutation_c.mutate()
    }
    useEffect(() => {
        setTimeout(async () => {
            actualizaPresentacionInicio()
        }, 5000)
    }, [])
    function enviarChat(){
        mutation.mutate()

    }
    function enviarNotificaciones(){
        mutation_a.mutate()

    }

    function handlerChange(e){
        setMensaje(e.target.value)
        //console.log(e.target.value)
    }

    const mutation = useMutation(addChat, {
        onSuccess: () => {
            //console.log("Eliminado")
            queryClient.invalidateQueries('CHAT')
            //setDatos_e(data)
            
        },
        onError: (error) => {
            console.log(error)
        }
    })
    const mutation_a = useMutation(addNotificacion, {
        onSuccess: () => {
            //console.log("Notificacion enviada correctamente")
            //queryClient.invalidateQueries('NOTIFICACIONES')
            //setDatos_e(data)
            
        },
        onError: (error) => {
            console.log(error)
        }
    })
    const mutation_c = useMutation(updatePresentacionInicio, {
        onSuccess: () => {
            //console.log("Notificacion enviada correctamente")
            //queryClient.invalidateQueries('NOTIFICACIONES')
            //setDatos_e(data)
            
        },
        onError: (error) => {
            console.log(error)
        }
    })
    const mutation_b = useMutation(updatePresentacion, {
        onSuccess: () => {
            //console.log("Notificacion enviada correctamente")
            //queryClient.invalidateQueries('NOTIFICACIONES')
            //setDatos_e(data)
            
        },
        onError: (error) => {
            console.log(error)
        }
    })
    const mutation_d = useMutation(updatePropuesta, {
        onSuccess: () => {
            //console.log("Notificacion enviada correctamente")
            //queryClient.invalidateQueries('NOTIFICACIONES')
            //setDatos_e(data)
            
        },
        onError: (error) => {
            console.log(error)
        }
    })
    const mutation_e = useMutation(updateContrato, {
        onSuccess: () => {
            //console.log("Notificacion enviada correctamente")
            //queryClient.invalidateQueries('NOTIFICACIONES')
            //setDatos_e(data)
            
        },
        onError: (error) => {
            console.log(error)
        }
    })
    const {refetch: refetch_c} = useQuery('NOTIFICACIONES', fetchNotificacion, {

    } )
    // const {refetch: refetch_b} = useQuery('CHAT', fetchChat, {
    // } )
    const {isLoading, data_c, refetch: refetch_a} = useQuery('PRESENTACIONES', fetchData)
    const {refetch: refetch_propuestas} = useQuery('PROPUESTAS', fetchDataPropuestas)
    const {refetch: refetch_contratos} = useQuery('CONTRATOS', fetchDataContratos)
    const {refetch: refetch_videos} = useQuery('VIDEOS', fetchDataVideos)
    const {refetch: refetch_videos_menu} = useQuery('VIDEOS_MENU', fetchDataVideosEstructura)
    const query_contactos = useQuery('CONTACTOS', fetchContactos)
    const {refetch: refetch_archivos} = useQuery('ARCHIVOS', fetchDataArchivos)
    // const {data: vendedores} = useQuery('VENDEDORES', fetchVendedor, {
    //     onSuccess: (data) => {
    //         //console.log("Datos-Vendedores:", data)
    //         setVendedor(data)
    //     },
    // })

    useQuery('CLIENTES', fetchClientes, {
        onSuccess: (data) => {
            //console.log("data-clientes:", data)
            setCliente(data.Logo)
        },
        //cacheTime: Infinity
    })
    const { data: project} = useQuery('PROYECTOS', fetchProyectos, {
        onSuccess: (data) => {
            //console.log("Resultado", data)
            //setProyecto(data[0])
            // if(data[0].estatus == "Activo"){
            //     const query = getQueryParams(document.location.search);
            //     window.location = `/error?token=${query.token}`
            // }
        }
    })
    
    if (isLoading) {
        return <Center mt={'10%'}><Spinner color={'green.700'} /></Center>
    }
   
    

    //console.log("ProyectoDataRedux", props.proyectoRData)
    //console.log("PresentacionesDataRedux", props.presentacionRData.map(o =>  o.novisible.filter( n => n !== meid) ))
    //console.log("propuestaDataRedux", props.propuestaRData.map(o => o.novisible.filter(n => n === meid)))
    //console.log("Propuestas", props.propuestaRData)
    //console.log("http://localhost:3001/preview/juancarlos.santiago@mostrilio.mx/12/Juan%20Carlos%20Sanchez/cKd5Sau13jZfKfPIVTeyB/26/Machote.propuesta_Mostrilio_Hello_webcorporativa.pptx/presentacion")
    //console.log("[PORYECTO]",proyectoRData)
    console.log({vendedorName}) 
    return (
        <Box bg={'gray.100'} h={'100vh'}>
            <Modal size={'lg'} closeOnOverlayClick={false} isOpen={isOrganigramaModalOpen} onClose={onCloseOrganigramaModal} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontFamily={fuente} fontSize={'15pt'} fontWeight={'medium'}>Organigrama NC Tech</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Center >
                            <Box borderWidth={1} borderRadius={'12px'} zIndex={1} py={3} w='100%' h={'90vh'}>
                            <List spacing={3} >
                            {datos_d
                             .map((p,i) => {
                                 return(
                                    <ListItem mx={5} my={5} key={i} >
                                        <Flex>
                                                <Flex my={2} cursor={'pointer'}>
                                                    <Box mx={2} alignSelf={'center'}>
                                                        <Avatar name={p.nombre} size={'md'} />
                                                    </Box>
                                                    <Flex direction={'column'}>
                                                        <Box fontSize={'11pt'} color={'blue.400'} fontWeight={'bold'} fontFamily={fuente}>
                                                            {p.nombre}
                                                        </Box>
                                                        <Flex>
                                                            <Box fontSize={'11pt'} color={'gray.600'} fontWeight={'normal'} fontFamily={fuente}>
                                                            
                                                                {p.puesto}
                                                           
                                                                
                                                            </Box>
                                                        </Flex>
                                                        
                                                    </Flex>
                                                </Flex>
                                        </Flex>

                                    </ListItem>
                                 )
                           
                           })}
                            </List>


                                {/* {datos_d
                                        .map((p) => {
                                            const url = p.url
                                            return (
                                                <Tbody size={'sm'}>
                                                    <Tr key={p.id}>
                                                        <Td fontSize={'sm'} fontWeight={'medium'} fontFamily={fuente} color={'gray.700'} >
                                                            {p.nombre}
                                                        </Td>
                                                        <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}><Tag colorScheme={'green'}>{p.puesto}</Tag></Td>
                                                        <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>
                                                            {p.telefono}
                                                        </Td>
                                                        <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>
                                                            {p.email}
                                                        </Td>
                                                        <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>
                                                            {p.whatsapp}
                                                        </Td>
                                                    </Tr>
                                                </Tbody>)
                                        })} */}

                            </Box>
                        </Center>  
                    </ModalBody>
                    <ModalFooter>
                        
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Flex direction={{base:'column', lg:'row'}}  h={'100vh'} w={{base:'100%', lg: '100%'}}  bg={'gray.100'} >    
                <Box w={{base:'100%', lg: '100%'}} h={{base: '100vh', lg:'100vh'}} ml={{lg:'0', base:0}} overflow={'hidden'}>
                <Flex direction={'column'} w={{base:'100%', lg: '100%'}} >
                    <Box display={{base:'none', lg:'flex'}} bg={'gray.900'} h={{base:'0', lg:'10vh'}} w={{base:'100%', lg:'100%'}} ml={0} position={'fixed'} zIndex={'9999'}>
                        <Header datos={datos_f} zIndex={'0'}/>
                    </Box>       
                    <Box  
                        h={{lg:'100vh', base:'100vh'}} 
                        w={{base:'100%', lg:'100%'}} 
                        bg={'gray.100'} 
                        position={{base:'absolute', lg:'flex'}} 
                        top={{base:0 , lg:4}}
                    >
                        <Flex direction={'column'}  w={{base:'100%', lg: '100%'}} >
                            <Box  w={{base:'100%', lg: '100%'}}  >
                                <Flex direction={{base:'column', lg:'row'}}  pt={10} w={{base:'100%', lg: '100%'}} >
                                    <Box display={{base:'none', lg:'flex'}}  w={{base:'0', lg:'20%'}} h={{base: '35vh', lg:'35vh'}}></Box> {/* Box 1 */}
                                    <Box w={{base:'100%', lg:'100%'}} h={{base: '25vh', lg:'35vh'}} >
                                    
                                           
                                            <Flex direction={'column'} w={{base:'100%', lg:'100%'}} >
                                                <Box w={'100%'} >
                                                   <Flex direction={'row'} mb={5} w={{base:'100%', lg:'100%'}}>
                                                        <Box  w={{base:'100%', lg:'70%'}} >
                                                            <Flex direction={'column'}>
                                                               
                                                                <Flex direction={'column'} ml={{ lg: '0', base: '0' }} >
                                                               
                                                                        <Box ml={{base:5, lg:0}} alignSelf={'start'} textAlign={{base:'center'}} fontSize={{ lg: '21pt', base: '21pt', '2xl': '21pt' }} color={'gray.700'} fontWeight={'bold'} fontFamily={fuente} mt={{ lg: '9', base: '5' }} mb={5}>¡{nombre}! </Box>

                                                                        <Box alignSelf={'start'} fontSize={{ lg: '25pt', base: '20pt', '2xl': '25pt' }} color={'gray.700'} fontWeight={'medium'} fontFamily={fuente}  ></Box>
                                                                        {/* <Box color={'gray.600'} fontWeight={'bold'} fontSize={'15pt'} fontFamily={fuente} mt={2} mb={2}>Porque veo esto?</Box> */}
                                                                        <Flex px={{base:5, lg:0}} direction={'row'} >
                                                                            <Box color={'gray.600'} fontWeight={'normal'} fontSize={'sm'} fontFamily={fuente}>
                                                                                <Flex direction={'column'}>
                                                                                    
                                                                                </Flex>
                                                                                <Box>
                                                                                Aquí tienes la información que necesitas sobre tu proyecto, junto con todo el material de apoyo.
                                                                                <strong> {proyectoRData.vendedorName}</strong> lo ha compartido contigo para que lo revises.
                                                                                </Box>
                                                                                <Box fontWeight={'bold'} mt={5}>
                                                                                  Su aliado en negocios NC Tech.
                                                                                </Box>

                                                                            </Box>

                                                                        </Flex>
                                                                    
                                                                    <Flex mr={5} mt={{ base: 7, lg: 5 }} wrap={'wrap'} w={'100%'} >  
                                                                        <Flex direction={'row'} mx={{ base: 0, lg: 1 }} mt={{ base: 0, lg: 0 }} px={{base:5, lg:0}}>

                                                                            <Flex justify={'start'}>
                                                                                <Tag bg={'gray.200'}>
                                                                                    <Box h={'2vh'} justifySelf={'center'} fontSize={'15px'} mt={'1'} mr={1} color={'green.500'}>
                                                                                        <GoPrimitiveDot color={'green.400'} />
                                                                                    </Box>
                                                                                    <Text fontFamily={fuente} fontWeight={'medium'} fontSize={'15px'} color={'gray.700'}>{proyectoRData.estatus} </Text></Tag>
                                                                            </Flex>
                                                                        </Flex>
                                                                    

                                                                    </Flex>
                                                                 
                                                                    <Flex mt={3} boxShadow={{base:'dark-xl', lg:'lg'}} bg={'white'} h={'50vh'} rounded='20px' overflow={'hidden'}>
                                                                        <Flex direction={'column'} w={'100%'} >
                                                                        <Box textAlign={'center'} my={{base: 10, lg:10}}  w={{base:'100%', lg: '100%'}} fontSize={{base:'20pt', lg:'20pt'}} color={'gray.700'} fontWeight={'bold'} fontFamily={fuente}>{proyectoRData.nombreProyecto}</Box>
                                                                        <Center >
                                                                        {/* <RenderIf isTrue={proyectoRData.tipo == "Público"}>
                                                                            <TabsUnstyled defaultValue={0}>
                                                                                <Box   pl={4} pr={5}>
                                                                                    <TabsList>
                                                                                        <Tab w={{ base: '90%', lg: '100%' }} onClick={refetch_a}>Archivos</Tab>
                                                                                        
                                                                                    </TabsList>
                                                                                </Box>
                                                                                <TabPanel value={0} >
                                                                                    <Center>
                                                                                        <Flex direction={'column'} w={{ base: '90%', lg: '100%' }} >
                                                                                            
                                                                                            <Center>
                                                                                                <Box borderWidth={1} borderRadius={'12px'} zIndex={1}  bg={'white'} overflow={'hidden'} boxShadow={'sm'}>

                                                                                                    {datos_archivos.length == 0 ? <Flex direction={'column'} justify={'center'}>
                                                                                                        <Center p={3}>
                                                                                                            <Image src={Empty} w={'20%'} />
                                                                                                        </Center>
                                                                                                        <Center>
                                                                                                        <Box bg={'white'} mx={2} my={2} textAlign={'center'} fontSize={'11pt'} color={'gray.800'} fontWeight={'normal'} fontFamily={fuente}>No podemos mostrar ninguna presentación en este momento, <strong>vuelve más tarde.</strong></Box></Center></Flex> :

                                                                                                            <Flex  direction={'column'}>
                                                                                                            {datos_archivos.map((p, i) => {
                                                                                                                    const url = p.url
                                                                                                                    const query = getQueryParams(document.location.search);
                                                                                                                    return (     
                                                                                                                            <Link key={i} _hover={{textDecorationLine:'none'}} href={`preview?token=${query.token}&url=${p.url}&proyecto=${query.proyecto}`}>
                                                                                                                                <Flex w={'380px'} bg={'gray.50'} mb={1} boxShadow={'sm'} _hover={{bg: 'gray.100', textDecorationLine:'none'}} >
                                                                                                                                
                                                                                                                                    <Box fontSize={'20pt'} mx={2} my={2} p={3} bg={'gray.100'} color={'white'} rounded={'full'}><GrAttachment  /></Box>
                                                                                                                                    
                                                                                                                                    <Flex direction={'column'} w={'100%'} justify='center'>
                                                                                                                                        <Box ml={3} textAlign={'start'} fontSize={'12pt'} fontWeight={'medium'} fontFamily={fuente} color={'gray.700'}>{p.nombreArchivo}</Box>
                                                                                                                            
                                                                                                                                    </Flex>
                                                                                                                                    
                                                                                                                                </Flex>
                                                                                                                            </Link>
                                                                                                                            
                                                                                                                            )
                                                                                                                })}
                                                                                                                </Flex>
                                                                                                            }
                                                                                                </Box>
                                                                                            </Center>

                                                                                        </Flex>
                                                                                    </Center>

                                                                                </TabPanel>
                                                                             
                                                                            </TabsUnstyled>
                                                                        </RenderIf> */}
                                                                        <RenderIf isTrue={proyectoRData.tipo != "Todo"}>
                                                                            <TabsUnstyled defaultValue={0}>
                                                                                <Box   pl={4} pr={5}>
                                                                                    <TabsList>
                                                                                        <Tab w={{ base: '90%', lg: '100%' }} onClick={refetch_a}>Presentaciones</Tab>
                                                                                        <Tab w={{ base: '90%', lg: '100%' }} onClick={refetch_propuestas}>Propuestas</Tab>
                                                                                        <Tab w={{ base: '90%', lg: '100%' }} onClick={refetch_contratos}>Contratos</Tab>
                                                                                        <Tab w={{ base: '90%', lg: '100%' }} onClick={refetch_videos_menu}>Videos</Tab>
                                                                                    </TabsList>
                                                                                </Box>
                                                                                <TabPanel value={0} >
                                                                                    <Center>
                                                                                        <Flex direction={'column'} w={{ base: '90%', lg: '100%' }} >
                                                                                            
                                                                                            <Center>
                                                                                                <Box borderWidth={1} borderRadius={'12px'} zIndex={1}  bg={'white'} overflow={'hidden'} boxShadow={'sm'}>

                                                                                                    {props.presentacionRData.length == 0 ? <Flex direction={'column'} justify={'center'}>
                                                                                                        <Center p={3}>
                                                                                                            <Image src={Empty} w={'20%'} />
                                                                                                        </Center>
                                                                                                            <Center>
                                                                                                                <Box bg={'white'} 
                                                                                                                    mx={2} 
                                                                                                                    my={2} 
                                                                                                                    textAlign={'center'} 
                                                                                                                    fontSize={'11pt'} 
                                                                                                                    color={'gray.800'} 
                                                                                                                    fontWeight={'normal'} 
                                                                                                                    fontFamily={fuente}>
                                                                                                                        No podemos mostrar ninguna presentación en este momento, <strong>vuelve más tarde.</strong>
                                                                                                                </Box>
                                                                                                            </Center>
                                                                                                        </Flex> :
                                                                                                        <Flex  direction={'column'}>
                                                                                                                        {meid && props.presentacionRData
                                                                                                                        .filter(p => p.novisible.indexOf(meid) === -1) // Aplicamos el filtro para incluir solo las propuestas donde el meid es inválido
                                                                                                                        .map((p) => {
                                                                                                                            const url = p.url;                                                                                                                  
                                                                                                                            const nombreArchivo = url.substring(url.lastIndexOf("/") + 1);
                                                                                                                            return (     
                                                                                                                            <Link key={p.presentacionId} _hover={{textDecorationLine:'none'}} href={`/preview/${meid}/${proyectoId}/${nombre}/${proyecto}/${vendedor}/${nombreArchivo}/presentacion`} onClick={() => actualizaPresentacion(p.presentacionId)}>
                                                                                                                                <Flex w={'380px'} bg={'gray.50'} mb={1} boxShadow={'sm'} _hover={{bg: 'gray.100', textDecorationLine:'none'}} >
                                                                                                                                <Box fontSize={'20pt'} mx={2} my={2} p={3} bg={'green.400'} color={'white'} rounded={'full'}><BiSlideshow /></Box>
                                                                                                                                <Flex direction={'column'} w={'100%'} justify='center'>
                                                                                                                                    <Box ml={3} textAlign={'start'} fontSize={'12pt'} fontWeight={'medium'} fontFamily={fuente} color={'gray.700'}>{p.nombre}</Box>
                                                                                                                                </Flex>
                                                                                                                                </Flex>
                                                                                                                            </Link>
                                                                                                                            )
                                                                                                                        })}
                                                                                                        </Flex>
                                                                                                    }
                                                                                                </Box>
                                                                                            </Center>

                                                                                        </Flex>
                                                                                    </Center>

                                                                                </TabPanel>
                                                                                <TabPanel value={1}>
                                                                                    <Center>
                                                                                        <Flex direction={'column'} w={{ base: '90%', lg: '100%' }} >                                        
                                                                                            <Center>
                                                                                                <Box borderWidth={1} borderRadius={'12px'} zIndex={1}  bg={'white'} overflow={'hidden'} boxShadow={'sm'}>
                                                                                                    {props.propuestaRData.length == 0 ? <Flex direction={'column'} justify={'center'}>
                                                                                                        <Center p={3}>
                                                                                                            <Image src={Empty} w={'20%'} />
                                                                                                        </Center>
                                                                                                        <Center>
                                                                                                        <Box mx={2} my={2} textAlign={'center'} fontSize={'11pt'} color={'gray.700'} fontWeight={'normal'} fontFamily={fuente}>No podemos mostrar ninguna propuesta en este momento, <strong>vuelve más tarde.</strong></Box></Center></Flex> :
                                                                                                            <Flex  direction={'column'}>
                                                                                                            {/* {props.propuestaRData.map((p, i) => {
                                                                                                                    const url = p.url
                                                                                                                    const query = getQueryParams(document.location.search);
                                                                                                                    const guid = nanoid()
                                                                                                                    return (     
                                                                                                                        <Link key={i} _hover={{textDecorationLine:'none'}} href={`preview?token=${query.token}&url=${p.url}&proyecto=${query.proyecto}&id=${guid}`} onClick={() => actualizaPropuesta(guid)}>
                                                                                                                                            <Flex w={'380px'} bg={'gray.50'} mb={1} boxShadow={'sm'} _hover={{bg: 'gray.100', textDecorationLine:'none'}} >
                                                                                                                                            
                                                                                                                                                <Box fontSize={'20pt'} mx={2} my={2} p={3} bg={'red.400'} color={'white'} rounded={'full'}><BsFilePdf /></Box>
                                                                                                                                                
                                                                                                                                                <Flex direction={'column'} w={'100%'} justify='center'>
                                                                                                                                                    <Box ml={3} textAlign={'start'} fontSize={'12pt'} fontWeight={'medium'} fontFamily={fuente} color={'gray.700'}>{p.nombre}</Box>
                                                                                                                                        
                                                                                                                                                </Flex>
                                                                                                                                                
                                                                                                                                            </Flex>
                                                                                                                            </Link>
                                                                                                                            
                                                                                                                            )
                                                                                                                })

                                                                                                                } */}


                                                                                                                {meid && props.propuestaRData
                                                                                                                    .filter(p => p.novisible.indexOf(meid) === -1) // Aplicamos el filtro para incluir solo las propuestas donde el meid es inválido
                                                                                                                    .map((p) => {
                                                                                                                        const url = p.url;                                                                                                                  
                                                                                                                        const nombreArchivo = url.substring(url.lastIndexOf("/") + 1);
                                                                                                                        return (     
                                                                                                                        <Link key={p.propuestaId} _hover={{textDecorationLine:'none'}} href={`/preview/${meid}/${proyectoId}/${nombre}/${proyecto}/${vendedor}/${nombreArchivo}/propuesta`} onClick={() => actualizaPropuesta(p.propuestaId)}>
                                                                                                                            <Flex w={'380px'} bg={'gray.50'} mb={1} boxShadow={'sm'} _hover={{bg: 'gray.100', textDecorationLine:'none'}} >
                                                                                                                            <Box fontSize={'20pt'} mx={2} my={2} p={3} bg={'red.400'} color={'white'} rounded={'full'}><BsFilePdf /></Box>
                                                                                                                            <Flex direction={'column'} w={'100%'} justify='center'>
                                                                                                                                <Box ml={3} textAlign={'start'} fontSize={'12pt'} fontWeight={'medium'} fontFamily={fuente} color={'gray.700'}>{p.nombre}</Box>
                                                                                                                            </Flex>
                                                                                                                            </Flex>
                                                                                                                        </Link>
                                                                                                                        )
                                                                                                                })}
                                                                                                                </Flex>
                                                                                                            }
                                                                                                </Box>
                                                                                            </Center>
                                                                                        </Flex>
                                                                                    </Center>
                                                                                </TabPanel>
                                                                                <TabPanel value={2} >
                                                                                    <Center>
                                                                                        <Flex direction={'column'} w={{ base: '90%', lg: '100%' }} >
                                                                                            
                                                                                            <Center>
                                                                                                <Box borderWidth={1} borderRadius={'12px'} zIndex={1}  bg={'white'} overflow={'hidden'} boxShadow={'sm'}>

                                                                                                    {props.contratoRData.length == 0 ? <Flex direction={'column'} justify={'center'}>
                                                                                                        <Center p={3}>
                                                                                                            <Image src={Empty} w={'20%'} />
                                                                                                        </Center>
                                                                                                            <Center>
                                                                                                                <Box bg={'white'} 
                                                                                                                    mx={2} 
                                                                                                                    my={2} 
                                                                                                                    textAlign={'center'} 
                                                                                                                    fontSize={'11pt'} 
                                                                                                                    color={'gray.800'} 
                                                                                                                    fontWeight={'normal'} 
                                                                                                                    fontFamily={fuente}>
                                                                                                                        No podemos mostrar ninguna contrato en este momento, <strong>vuelve más tarde.</strong>
                                                                                                                </Box>
                                                                                                            </Center>
                                                                                                        </Flex> :
                                                                                                        <Flex  direction={'column'}>
                                                                                                                        {meid && props.contratoRData
                                                                                                                        .filter(p => p.novisible.indexOf(meid) === -1) // Aplicamos el filtro para incluir solo las propuestas donde el meid es inválido
                                                                                                                        .map((p) => {
                                                                                                                            const url = p.url;                                                                                                                  
                                                                                                                            const nombreArchivo = url.substring(url.lastIndexOf("/") + 1);
                                                                                                                            return (     
                                                                                                                            <Link key={p.contratoId} _hover={{textDecorationLine:'none'}} href={`/preview/${meid}/${proyectoId}/${nombre}/${proyecto}/${vendedor}/${nombreArchivo}/contrato`} onClick={() => actualizaContrato(p.contratoId)}>
                                                                                                                                <Flex w={'380px'} bg={'gray.50'} mb={1} boxShadow={'sm'} _hover={{bg: 'gray.100', textDecorationLine:'none'}} >
                                                                                                                                <Box fontSize={'20pt'} mx={2} my={2} p={3} bg={'green.400'} color={'white'} rounded={'full'}><BiSlideshow /></Box>
                                                                                                                                <Flex direction={'column'} w={'100%'} justify='center'>
                                                                                                                                    <Box ml={3} textAlign={'start'} fontSize={'12pt'} fontWeight={'medium'} fontFamily={fuente} color={'gray.700'}>{p.nombre}</Box>
                                                                                                                                </Flex>
                                                                                                                                </Flex>
                                                                                                                            </Link>
                                                                                                                            )
                                                                                                                        })}
                                                                                                        </Flex>
                                                                                                    }
                                                                                                </Box>
                                                                                            </Center>

                                                                                        </Flex>
                                                                                    </Center>

                                                                                </TabPanel>
                                                                                <TabPanel value={5}>
                                                                                    <Center>
                                                                                        <Flex direction={'column'} w={{ base: '90%', lg: '100%' }} >
                                                                                            <Center>
                                                                                                <Box borderWidth={1} borderRadius={'12px'} zIndex={1}  bg={'white'} overflow={'hidden'} boxShadow={'sm'}>

                                                                                                    {datos_videos.length == 0 ? <Flex direction={'column'} justify={'center'}>
                                                                                                        <Center p={3}>
                                                                                                            <Image src={Empty} w={'20%'} />
                                                                                                        </Center>
                                                                                                        <Center>
                                                                                                        <Box mx={2} my={2} textAlign={'center'} fontSize={'11pt'} color={'gray.700'} fontWeight={'normal'} fontFamily={fuente}>No podemos mostrar ningún video en este momento, <strong>vuelve más tarde.</strong></Box></Center></Flex> :
                                                                                                            <Flex  direction={'column'}>
                                                                                                            {datos_videos.map((p, i) => {
                                                                                                                    const url = p.url
                                                                                                                    const query = getQueryParams(document.location.search);
                                                                                                                    return (     
                                                                                                                        // <Link key={i} _hover={{textDecorationLine:'none'}} href={`preview?token=${query.token}&url=${p.url}`} onClick={() => actualizaPresentacion(p.videoId)}>
                                                                                                                        //                     <Flex w={'380px'} bg={'gray.50'} mb={1} boxShadow={'sm'} _hover={{bg: 'gray.100', textDecorationLine:'none'}} >
                                                                                                                        //                         <Box fontSize={'20pt'} mx={2} my={2} p={3} bg={'green.400'} color={'white'} rounded={'full'}><BiSlideshow /></Box>
                                                                                                                        //                         <Flex direction={'column'} w={'100%'} justify='center'>
                                                                                                                        //                             <Box ml={3} textAlign={'start'} fontSize={'12pt'} fontWeight={'medium'} fontFamily={fuente} color={'gray.700'}>{p.nombreVideo}</Box>
                                                                                                                        //                         </Flex>
                                                                                                                        //                     </Flex>
                                                                                                                        // </Link>
                                                                                                                        <Box overflow={'hidden'} rounded={'lg'} w={'200px'}>
                                                                                                                            <video controls preload="auto" width="100%" height="40%" 
                                                                                                                                >
                                                                                                                                <source src={p.url} controls />
                                                                                                                                
                                                                                                                            </video>
                                                                                                                        </Box> 
                                                                                                                            )
                                                                                                                })}
                                                                                                                </Flex>
                                                                                                            }
                                                                                                </Box>
                                                                                            </Center>

                                                                                        </Flex>
                                                                                    </Center>

                                                                                </TabPanel>
                                                                                <TabPanel value={3}>
                                                                                    <Center>
                                                                                        <Flex direction={'column'} w={{ base: '90%', lg: '100%' }} >
                                                                                            <Center>
                                                                                                <Box borderWidth={1} borderRadius={'12px'} zIndex={1}  bg={'white'} overflow={'hidden'} boxShadow={'sm'}>

                                                                                                    {datos_videos_menu.length == 0 ? <Flex direction={'column'} justify={'center'}>
                                                                                                        <Center p={3}>
                                                                                                            <Image src={Empty} w={'20%'} />
                                                                                                        </Center>
                                                                                                        <Center>
                                                                                                        <Box mx={2} my={2} textAlign={'center'} fontSize={'11pt'} 
                                                                                                        color={'gray.700'} fontWeight={'normal'} 
                                                                                                        fontFamily={fuente}>No podemos mostrar ningún video en este momento, <strong>vuelve más tarde.</strong></Box></Center></Flex> :
                                                                                                            <Flex  direction={'column'}>
                                                                                                            {datos_videos_menu.map((p, i) => {
                                                                                                                    const url = p.url
                                                                                                                    const query = getQueryParams(document.location.search);
                                                                                                                    return (     
                                                                                                                        <Link key={p.videoId} _hover={{textDecorationLine:'none'}} href={`/previewVideo/${meid}/${proyectoId}/${nombre}/${proyecto}/${vendedor}/${nombre}/video`} onClick={() => actualizaPresentacion(p.videoId)}>
                                                                                                                            <Flex w={'380px'} bg={'gray.50'} mb={1} boxShadow={'sm'} _hover={{bg: 'gray.100', textDecorationLine:'none'}} >
                                                                                                                            <Box fontSize={'20pt'} mx={2} my={2} p={3} bg={'green.400'} color={'white'} rounded={'full'}><BiSlideshow /></Box>
                                                                                                                            <Flex direction={'column'} w={'100%'} justify='center'>
                                                                                                                                <Box ml={3} textAlign={'start'} fontSize={'12pt'} fontWeight={'medium'} fontFamily={fuente} color={'gray.700'}>{p.nombreVideo}</Box>
                                                                                                                            </Flex>
                                                                                                                            </Flex>
                                                                                                                        </Link>
                                                                                                                            )
                                                                                                                })}
                                                                                                                </Flex>
                                                                                                            }
                                                                                                </Box>
                                                                                            </Center>

                                                                                        </Flex>
                                                                                    </Center>

                                                                                </TabPanel>
                                                                            </TabsUnstyled>
                                                                        </RenderIf>
                                                                        </Center>
                                                                        </Flex>
                                                                    </Flex>
                                                                    

                                                                </Flex>
                                                                {/* <Box bg={'white'} w={'100%'} my={5}>
                                                                    <Box fontSize={{ lg: '13px', base: '15px', '2xl': '15px' }} color={'gray.600'} fontWeight={'bold'} fontFamily={fuente} ml={{ lg: '0', base: '5' }}>

                                                                    </Box>
                                                                </Box> */}
                                                            </Flex>
                                                        </Box>
                                                        <Box w={'30%'} ml={10} mt={'30%'} display={{base: 'none', lg:'flex'}}>
                                                            {/* <Image src={cliente} w={'150px'}  h={'60px'} /> */}
                                                        </Box>
                                                </Flex>
                                                </Box>
                                                
                                               
                                            </Flex>
                                    </Box>
                                    <Box display={{base:'none', lg:'flex'}} bg={'gray.100'} w={{base:'0', lg:'20%'}} h={{base: '25vh', lg:'25vh'}}>
                                    </Box> {/* Box 3 */}
                                    
                                </Flex>
                            </Box>
                            
                            {/* aqui va el contenido */}
                            <Botones />                                 
                        </Flex>
                    </Box>
                </Flex>
                </Box>
            </Flex>
</Box>
    );
};

const mapStateProps =(state) => ({
    contacto: state.contactoReducer.contacto,
    isLoading: state.contactoReducer.isLoading,
    error: state.contactoReducer.error,
    proyectoRData: state.proyectoReducer.proyectoRData,
    isLoadingProyecto: state.proyectoReducer.isLoading,
    errorProyecto: state.proyectoReducer.error,
    presentacionRData: state.presentacionesReducer.presentacionRData,
    isLoadingpresentacion: state.presentacionesReducer.isLoading,
    errorpresentacion: state.presentacionesReducer.error,
    propuestaRData: state.propuestasReducer.propuestaRData,
    isLoadingpropuesta: state.propuestasReducer.isLoading,
    errorpropuesta: state.propuestasReducer.error,
    contratoRData: state.contratosReducer.contratoRData,
    isLoadingcontrato: state.contratosReducer.isLoading,
    errorcontrato: state.contratosReducer.error,
    })
     
  const Home = connect(mapStateProps,{get_contacto, get_proyecto, get_presentaciones, get_propuestas, get_contratos})(_Home)
  
  export default Home;


