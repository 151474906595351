import { Box } from '@chakra-ui/layout';
import React from 'react';
import Header from '../components/Header';
import PropuestasComponent from '../components/Propuestas/Propuestas';

const Propuestas = () => {
    return (
        <Box bg={'gray.50'} h={'100vh'}>
            <Header/>
            <PropuestasComponent />
        </Box>
    );
};

export default Propuestas;