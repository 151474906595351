
import React from 'react';
import {
    Input, VStack, Toast, SimpleGrid, LinkOverlay, HStack, Image, Stack, useToast, Button,
    Text, Heading, Container, Box, Center, Collapse, Fade, ScaleFade, Slide, SlideFade,
    useDisclosure, Flex, Spacer, Link, Divider,  Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuIcon,
    MenuCommand,
    MenuDivider
} from "@chakra-ui/react"
import {  ChevronDownIcon, BellIcon } from '@chakra-ui/icons'

import Logo from '../assets/logo-nctech-1-1.svg';
import useUser from '../context/useUser';
import Notificaciones from './Cliente/Notificaciones';
const salir = () => {
    localStorage.removeItem('token');
    window.location = "/"
}
const Header = (props) => {
    const { user, setUser } = useUser()
    return (
        <HStack w={'100%'} h={'10vh'} borderWidth={'0px'} bg={'gray.50'} boxShadow={'sm'} zIndex={'-1'}>
            <Image src={Logo} w={'132px'} mt={'0'} ml={'10'} />
            <Spacer></Spacer>
            <Center height="50px" pr={5}>
                <Divider orientation="vertical" />
            </Center>
            <Menu pr={10} bg={'white'}>
            {user ? 
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg={'white'}>
            {user.username}
            </MenuButton> : 
            <Box />
            }
            <MenuList>
                <MenuItem><Link  color={'gray.700'} fontWeight={'medium'} fontSize={'15px'} href="#" onClick={salir} className="font"  w={'100%'}>Salir</Link></MenuItem>
            </MenuList>
            </Menu>
            
            
        </HStack>
    );
};

export default Header;