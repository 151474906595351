
import React from 'react'
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query'
import { ChakraProvider } from "@chakra-ui/react"
import  Routes  from  './components/routes'
import { DataProvider } from './context/DataContext';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
    retry: 3,
    refetchOnWindowFocus: false,
    }
  }
})


function App() {

  return (
      <DataProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider>
        <div>
            <Routes />
        </div>
        </ChakraProvider>
      </QueryClientProvider>
      </DataProvider>
    
    
  );
}

export default App;
