import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login/Login';
import Home from '../pages/Home';
import ForgotPassword from '../pages/ForgotPassword';
import Propuestas from '../pages/Propuestas';
import Presentaciones from '../pages/Presentaciones';
import Proyectos from './Cliente/Proyectos';
import LinkedinAuthCallback from '../components/Login/LoginLinkedin'
import Validador from './Login/Validador';
import Preview from '../components/Cliente/Preview'
import PreviewVideo from '../components/Cliente/PreviewVideo'
import PreviewPublic from '../components/Cliente/PreviewPublic'
import Error from '../components/Cliente/Error'
import LoginOnAccess from './Login/LoginOnAccess';
import LoginBusiness from './Login/LoginBusiness';
function Application(params) {
    return(
        <Router>
            <Routes>
                {/* <Route path="/" element={<Proyectos />} /> */} 
                <Route path="/oneaccess/:proyecto/:proyectoId/:vendedor/:vendedorName" element={<LoginOnAccess />} />
                <Route path="/publicbusiness/:proyecto/:proyectoId/:vendedor/:vendedorName" element={<LoginBusiness />} />
            </Routes>
            <Routes>
                <Route path="/auth/linkedin/callback" element={<LinkedinAuthCallback />} />
            </Routes>
            <Routes>
                <Route path="/login" element={<Login />} />
            </Routes>
            <Routes>
                <Route path="/forgotpassword" element={<ForgotPassword />} />
            </Routes>
               {/* Rutas para las Propuestas */}
            <Routes>
                <Route path="/propuestas" element={<Propuestas />} />
            </Routes>
            {/* Rutas para las Presentaciones */}
            <Routes>
                <Route path="/presentaciones" element={<Presentaciones />} />
            </Routes>
            <Routes>
                <Route path="/proyectos" element={<Proyectos />} />
            </Routes>
            <Routes>
                <Route path="/me/:meid/:proyectoId/:nombre/:proyecto/:vendedor/:vendedorName" element={<Home />} /> {/*  cambio public por me */}
            </Routes>
            <Routes>
                <Route path="/validar" element={<Validador />} />
            </Routes>
            <Routes>
                <Route path="/preview/:meid/:proyectoId/:nombre/:proyecto/:vendedor/:url/:type" element={<Preview />} />
                <Route path="/previewvideo/:meid/:proyectoId/:nombre/:proyecto/:vendedor/:url/:type" element={<PreviewVideo />} />
            </Routes>
            <Routes>
                <Route path="/public/:file/:type/:documento/:usuario" element={<PreviewPublic />} />
            </Routes>
            <Routes>
                <Route path="/error" element={<Error />} />
            </Routes>
           
          
        </Router>
    )
}
export default Application;
