import React, {createContext, useState} from "react";

export const DataContext = createContext();



export const DataProvider = ({ children }) => {
    const [dataContacto, setDataContacto] = useState()

    return(
        <DataContext.Provider value={{
            dataContacto, 
            setDataContacto
        }}>
            { children }
        </DataContext.Provider>
        
    )

} 