import React from 'react';
import {Box, Flex, LinkBox, LinkOverlay, Spacer, Center, Tooltip, Divider} from "@chakra-ui/react"
import { HamburgerIcon, SettingsIcon, CopyIcon, DragHandleIcon, StarIcon } from '@chakra-ui/icons'
import { FaLinkedin, FaLinkedinIn, FaWhatsappSquare } from 'react-icons/fa';
import { BiHomeAlt } from 'react-icons/bi';
import {AiOutlineFundProjectionScreen} from 'react-icons/ai'
import {HiDocumentText} from 'react-icons/hi'
import {BsFillCameraVideoFill} from 'react-icons/bs'
import {FaFileSignature} from 'react-icons/fa'
import {useParams} from "react-router-dom";
function getQueryParams(qs) {
    qs = qs.split('+').join(' ');

    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
}
const MenuCliente = () => {
    let { id, token } = useParams();
    const query = getQueryParams(document.location.search);
    const url = `/proyectos?token=${query.token}`
    //console.log(query.token)
    
    return (
        <Box  borderRightWidth={1} bg={'gray.50'} w={{base:'100%', lg: '10%'}} h={{base: '10vh', lg:'100vh'}} position={'fixed'} zIndex={'9999'}> 
            <Flex direction={'column'} h={'100vh'} display={{base: 'none', lg:'flex'}}>
               
                        <Box as='h3' fontFamily={'roboto'} fontSize={'25px'} textAlign={'center'} h={'10vh'}>
                            
                        </Box>
                
                
                <Tooltip label="Está opción regresa al inicio" aria-label='A tooltip' placement='right' borderRadius={'sm'}>
                <LinkBox bg={'gray.50'} w={'100%'} pt={{lg:4, '2xl': 8}} pb={8} _hover={{'bg':'gray.200'}} h={'10vh'}>
                
                    <Flex w={'100%'}  justify={'center'}>
                        <Center>
                            <LinkOverlay href={url}>
                                    <Center>
                                        <Box as='h3' fontFamily={'roboto'} fontSize={'20px'} align={'center'}>
                                            <BiHomeAlt /> 
                                        </Box>
                                    </Center>
                            </LinkOverlay>
                            <Box mx={7} fontSize={'14pt'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'}>Inicio</Box>
                        </Center>
                    </Flex>
                 
                </LinkBox>
                
                </Tooltip>
               
                <Spacer h={'40vh'} />
                <Divider />
                <Tooltip label="Configuración" aria-label='A tooltip' placement='right' borderRadius={'sm'}>
                    <LinkBox bg={'gray.50'} w={'100%'} h={'10vh'} pt={{lg:4, '2xl': 8}} pb={8} _hover={{ 'bg': 'gray.100', 'color':'gray.700' }}>
                        <LinkOverlay href='/perfil'>
                            <Center>
                                <Box as='h3' fontFamily={'roboto'} fontSize={'25px'} align={'center'}>
                                    <SettingsIcon />
                                </Box>
                            </Center>
                        </LinkOverlay>
                    </LinkBox>
                </Tooltip>
            </Flex>
        </Box>
    );
};

export default MenuCliente;