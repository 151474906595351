import React from 'react';
const Validador = () => {

    // let { access_token } = useParams();
    // useEffect(()=>{
    //     setTimeout(()=>{
    //         console.log(access_token)
    //     },1000)
        
    //     //setToken(access_token)
    // },[])

    return (
        <div>
            
        </div>
    );
};

export default Validador;