import React from 'react';
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
} from "@chakra-ui/react"

const Presentaciones = () => {
    return (
        <Box ml={'10'} mt={5}>
            <Breadcrumb>
                <BreadcrumbItem>
                    <BreadcrumbLink href="/">Home</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink href="/presentaciones">Presentaciones</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
        </Box>
    );
};

export default Presentaciones;