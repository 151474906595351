
import * as AWS from 'aws-sdk'
import { customAlphabet } from 'nanoid'
//AWS.config.update({ region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' }); //DESARROLLO
AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIARVGY33F62XNKJJK3' }); //PRODUCCION
const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: '2022-01-01' })
const nanoid = customAlphabet('0123456789', 30);   
const code = customAlphabet('0123456789', 5);  
export const get_video = (proyecto) => async (dispatch) => {
    dispatch({ type: 'LOAD_VIDEOS_REQUEST' });
    try {
      const videoData = await getVideoData(proyecto);
      const childVideos = await hijos(videoData[0].videoId);
  
      const menu = groupVideosByPadre(childVideos);
      const finalMenu = Object.values(menu);
      dispatch({ type: 'GET_VIDEOS', payload: finalMenu});
      // También puedes hacer algo con childVideos si es necesario
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const groupVideosByPadre = (childVideos) => {
    const menu = {};
  
    childVideos.forEach(parentVideo => {
      if (parentVideo.tipo === 'Padre') {
        menu[parentVideo.id] = {
          ...parentVideo,
          hijos: []
        };
  
        const relatedChildren = childVideos.filter(childVideo => childVideo.subPadre === parentVideo.id);
        menu[parentVideo.id].hijos = relatedChildren;
      }
    });
  
    return menu;
  };
  
  
  const getVideoData = async (proyecto) => {
    return new Promise((resolve, reject) => {
      const params = {
        TableName: 'Video_Menu_Proyecto',
        FilterExpression: "#proyecto = :proyecto",
        ExpressionAttributeNames: {
          "#proyecto": "proyecto"
        },
        ExpressionAttributeValues: {
          ":proyecto": proyecto
        }
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data.Items);
        }
      });
    });
  };
const hijos = async (proyecto) => {
    return new Promise((resolve, reject) => {
      const params = {
        TableName: 'Video_Menu',
        FilterExpression: "#padre = :padre",
        ExpressionAttributeNames: {
          "#padre": "padre"
        },
        ExpressionAttributeValues: {
          ":padre": proyecto
        }
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data.Items);
        }
      });
    });
  };

  export const get_video_default = (proyecto) => async (dispatch) => {
    dispatch({ type: 'LOAD_VIDEOS_REQUEST' });
    try {

      const videoData = await getVideoData(proyecto);
      const childVideos = await hijos(videoData[0].videoId);
         dispatch({ type: 'GET_VIDEO', payload: childVideos[0].url});
      // También puedes hacer algo con childVideos si es necesario
    } catch (error) {
      console.error('Error:', error);
    }
  }
