import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import * as AWS from 'aws-sdk'
import {
    Button,
    Box, Flex, Image,
    Center,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Avatar, AvatarBadge, AvatarGroup,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    AspectRatio
} from "@chakra-ui/react"
import { ChevronDownIcon, StarIcon, ArrowUpIcon } from "@chakra-ui/icons";
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useParams } from "react-router-dom";
import Botones from '../Cliente/Buttons'
import { useMediaQuery } from '../../hooks/mediaQuery'
import { RenderIf } from '../../hooks/renderIf';
import LogoNctech from '../../assets/LOGOS_NCTECH-01.svg'
import { motion } from "framer-motion";
import { connect } from 'react-redux'
import { get_avatar, get_proyecto } from '../../actions';
import { get_video, get_video_default } from '../../actions/videos';
import Fondo from '../../assets/FONDOAZUL.png'
import EMBLEMA from '../../assets/EMBLEMA.png'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ReactPlayer from 'react-player'
import { useWindowSize } from 'usehooks-ts'

//AWS.config.update({region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId:'AKIAWLUS3YRU4LTKLQHG'}); // DESARROLLO
AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIARVGY33F62XNKJJK3' });
function getQueryParams(qs) {
    qs = qs.split('+').join(' ');

    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
}

const _PreviewVideo = (props) => {
    const [datos, setDatos] = useState([])
    const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: '2022-01-01' })
    const listElement = useRef();
    const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI)
    const [to, setTo] = useState()
    const [src, setSrc] = useState()
    const [datos_videos, setDatos_videos] = useState([])
    const [vendedorData, setVendedorData] = useState()
    let { id, token, proyectoParametro, nombre, proyecto, proyectoId, vendedor, url, meid, type } = useParams();
    const breakpoint = useMediaQuery('(max-width: 1200px)');
    const breakpoint800 = useMediaQuery('(max-width: 400px)');
    const breakpoint1201 = useMediaQuery('(max-width: 1601px)');
    const { Imagen, proyectoRData, videos, videoDefault } = props || {};
    const [office, setOffice] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const { tamano, altura } = useWindowSize()
    const [urlVideo, setUrlVideo] = useState([])
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [play, setPlay] = useState(false)
    const handleHover = () => {
        setIsHovered(!isHovered);
    };

    const iconRotation = isHovered ? "rotate(180deg)" : "rotate(0)";

    const buttonStyles = {
        rounded: "full",
        transition: "transform 0.3s ease", // Agregar transición
        transform: iconRotation, // Aplicar la rotación
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offSet) {
        setPageNumber(prevPageNumber => prevPageNumber + offSet);
    }

    function changePageBack() {
        changePage(-1)
    }

    function changePageNext() {
        changePage(+1)
    }
    const useWindowDimensions = () => {
        const [windowDimensions, setWindowDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight,
        });

        useLayoutEffect(() => {
            const handleResize = () => {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            };

            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);

        return windowDimensions;
    };
    const { width, height } = useWindowDimensions();
    //console.log(docClient)

    function handlePlay() {
        setIsPlaying(true);
    }

    const fetchData = (tableName) => {
        var params = {
            TableName: tableName,
        }

        docClient.scan(params, function (err, data) {
            if (!err) {
                //return data
                setDatos(data.Items)
                //console.log(data.Items)

            }
        })

    }

    const putData = (tableName, data) => {
        var params = {
            TableName: tableName,
            Item: data
        }

        docClient.put(params, function (err, data) {
            if (err) {
                console.log('Error', err)
            } else {
                console.log('Success', data)
            }
        })
    }
    const fetchDataFormDynamoDb = () => {
        const res = fetchData('Clientes')
        //console.log("Resultado:", res)
        // setDatos(res.Items)
        //return res

    }
    const addDataToDynamoDB = async () => {
        const userData = {
            Nombre: "Juan Carlos Santiago",
            Prueba: "Desde React",
            FechaCreacion: "Hoa"
        }

        await putData('Clientes', userData)
    }
    const regresar = () => {

        window.location.href = `/me/${meid}/${proyectoId}/${nombre}/${proyecto}/${vendedor}`

    }

    const actualizar = () => {
        const query = getQueryParams(document.location.search);
        var params = {
            TableName: 'Bitacora',
            Key: {
                "id": query.id
            },
            UpdateExpression: "set open = :open, fechaFin = :fechaFin",
            ExpressionAttributeValues: {
                ":open": false,
                ":fechaFin": Date.now()

            },
        }
        docClient.update(params, function (err, data) {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                //res.status(200).json({ data })
                // console.log(data)
            }
        });
    }
    const fetchDataVideos = () => {
        // const query = getQueryParams(document.location.search);
        // console.log("Query:", query)
        // const proyecto = query.token.substring(query.token.indexOf(',') + 1)
        // console.log("proyecto1:", proyecto)
        var params = {
            TableName: 'Media',
            FilterExpression: "#nom = :name and #estado = :estado and #proyecto = :proyecto and #tipo = :tipo",
            ExpressionAttributeNames: {
                "#nom": "cliente",
                "#estado": "estatus",
                "#proyecto": "proyecto",
                "#tipo": "tipo"
            },
            ExpressionAttributeValues: {
                ":name": proyectoId,
                ":estado": "visible",
                ":proyecto": proyecto,
                ":tipo": "presentacion"
            }
        }
        docClient.scan(params, function (err, data) {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                // console.log("Query succeeded.", data.Items);
                setDatos_videos(data.Items)
            }
        });
    }
    const setvideoDefault = () => {
        props.get_video_default(proyecto)
        console.log("NULL", videoDefault)
        if (
            videoDefault != null
        ) {
            if (urlVideo.length == 0) {
                setTimeout(() => {
                    setUrlVideo(videoDefault)
                }, 500)
            }
        }

    }
    useEffect(() => {
        props.get_video(proyecto)

        setTimeout(() => {
            setvideoDefault()
        }, 200)
    }, [videoDefault])
    useEffect(() => {
        const query = getQueryParams(document.location.search);
        setTo(query.token)
        if (type == "presentacion") {
            setSrc("https://view.officeapps.live.com/op/embed.aspx?src=https://nctech-imagenes.s3.amazonaws.com/" + url)
            //setSrc("https://docs.google.com/viewer?src=https://nctech-imagenes.s3.amazonaws.com/ES Parsing_ Part One by Slidesgo.pptx&embedded=true")

            //setSrc("https://nctech-imagenes.s3.amazonaws.com/" + url)
            //https://pus6-powerpoint.officeapps.live.com/p/PowerPointFrame.aspx?PowerPointView=SlideShowView&ui=es%2DES&rs=es%2DES&WOPISrc=http%3A%2F%2Fpus6%2Dview%2Dwopi%2Ewopi%2Eonline%2Eoffice%2Enet%3A808%2Foh%2Fwopi%2Ffiles%2F%40%2FwFileId%3FwFileId%3Dhttps%253A%252F%252Fnctech%252Dimagenes%252Es3%252Eamazonaws%252Ecom%253A443%252FMachote%252Epropuesta%255FMostrilio%255FHello%255Fwebcorporativa%252Epptx&access_token_ttl=0&hid=2095d27b-0368-4b8e-ac30-c7c560b00faa&wdSlideId=264&wdModeSwitchTime=1680411530498
        } else {
            //console.log(url)
            setSrc("https://nctech-imagenes.s3.amazonaws.com/" + url)
        }
        if (type == "video") {
            console.log("Video")
        }
        fetchDataFormDynamoDb()
        //console.log("Vendedor",vendedor)
        props.get_avatar(vendedor, urlStrapi)
        props.get_proyecto(proyecto)
        //fetchVendedor()
        //console.log(query)
        const noRightClick = document.getElementById("preview");

        noRightClick.addEventListener("contextmenu", e => e.preventDefault());
        if (type == "presentacion") {
            const noRightClickIframe = document.getElementById("presentaciones");
            noRightClickIframe.addEventListener("contextmenu", e => e.preventDefault());
        }
        if (type == "propuesta") {
            // Capturamos el evento del clic derecho en el frame
            document.getElementById("pdfs").addEventListener("contextmenu", function (event) {
                // Anulamos el comportamiento predeterminado del clic derecho
                event.preventDefault()
            })
        }
    }, [])

    useEffect(() => {
        function handleContextMenu(event) {
            event.preventDefault();
        }

        const iframe = listElement.current;

        if (iframe) {
            iframe.addEventListener('contextmenu', handleContextMenu);
        }

        return () => {
            if (iframe) {
                iframe.removeEventListener('contextmenu', handleContextMenu);
            }
        };
    }, []);
    const { refetch: refetch_videos } = useQuery('VIDEOS', fetchDataVideos)



    return (
        // <div>
        // The current window dimensions are:{' '}
        // <code>{JSON.stringify({ breakpoint800, width, height })}</code>
        // </div>
        <Box h={'100vh'} w={'100%'} id={'preview'} bg={'#0B3A5B'} border={0}>

            <Flex direction={'column'} h={'100vh'} w={'100%'}>

                <RenderIf isTrue={width >= 1700 && width <= 2000 && height >= 970 && height <= 1000} >


                    <Flex direction={'row'} w={'100%'} bg={'#0B3A5B'} position={'relative'}>
                        <Box bg={'transparent'}
                            zIndex={6}
                            w={'20%'}
                            position={'relative'}

                            h={'100vh'}>
                            <Center>
                                <Box mt={5} zIndex={9}>
                                <Image src={LogoNctech} w={'100px'} h={'60px'} />
                                </Box>

                            </Center>
                            <Center>
                                <Box w={'60%'} mt={10} rounded={10} overflow={'hidden'}>
                                    {
                                        videos !== undefined ? (
                                            <Accordion allowToggle>
                                                {videos.map((item) => (
                                                    <AccordionItem key={item.id} bg={'white'}>
                                                        <AccordionButton onClick={() => setUrlVideo(item.url)}>
                                                            <Box as="span" flex="1" textAlign="left" fontWeight={'bold'}>
                                                                {item.nombre}
                                                            </Box>
                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                        <AccordionPanel pb={4}>
                                                            {item.hijos.map((hijo) => (
                                                                <Box onClick={() => setUrlVideo(hijo.url)} key={hijo.id} w={'100%'} pl={5} fontSize={'14px'} fontWeight={'500'} py={2} _hover={{ opacity: .8, cursor: 'pointer' }}>
                                                                    {hijo.nombre} {/* Nombre del hijo */}
                                                                </Box>
                                                            ))}
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                ))}
                                            </Accordion>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </Box>
                            </Center>
                        </Box>
                        <Box w={'80%'}
                            position={'relative'}
                            left={'-2'}
                            top={0}
                            h='100%'
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >

                            <RenderIf isTrue={type == "video"}>
                                <Center h="100vh"> {/* Centra verticalmente */}
                                    <AspectRatio w={['101%', '100%']} ratio={14 / 7.2} >
                                        <Box overflow="hidden" >
                                            <ReactPlayer
                                                width={(width / 2) + (height / 2) * 2}
                                                height={(height) - (50)}
                                                playing
                                                url={urlVideo}
                                                style={{ borderRadius: '15px' }}
                                            />
                                        </Box>
                                    </AspectRatio>
                                </Center>
                            </RenderIf>

                        </Box>
                        <Box bg={'transparent'}
                            zIndex={2}
                            w={'20%'}
                            position={'relative'}

                            h={'100vh'}>

                            <Center>
                                <Box mt={5} ml={5} >
                                    <Flex justify={'center'}>
                                        {
                                            Imagen === undefined ?
                                                <Avatar src={"user.username"} alignSelf={'center'} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                                :
                                                <Avatar alignSelf={'center'} src={Imagen} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                        }
                                        <Flex direction={'column'}>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>Asesor de Ventas</Box>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>{proyectoRData.vendedorName}</Box>

                                        </Flex>
                                    </Flex>
                                    <Flex justify={'start'} direction={'column'} zIndex={99999} w={'200px'} h={'500px'} mt={4}>
                                        {datos_videos.map((p, i) => {
                                            return (
                                                <Box rounded={'lg'} w={'auto'} bg={'gray.200'} overflow={'hidden'} >
                                                    <Box textAlign={'center'} p={1} fontWeight={'bold'}>Video de Presentación</Box>
                                                    <AspectRatio w={['100%', '100%']} ratio={2 / 2} >
                                                        <video controls={true} autoPlay={false} preload="auto" width="auto" height="50%"
                                                        >
                                                            <source src={p.url} controls />
                                                        </video>
                                                    </AspectRatio>
                                                </Box>
                                            )
                                        })}
                                    </Flex>
                                </Box>
                            </Center>
                        </Box>
                        <Botones zIndex={7} style={{ zIndex: 9999 }} />
                    </Flex>

                </RenderIf>
                <RenderIf isTrue={width >= 1400 && width <= 1699 && height >= 970 && height <= 1000} >


                    <Flex direction={'row'} w={'100%'} bg={'#0B3A5B'} position={'relative'}>
                        <Box bg={'#0B3A5B'}
                            zIndex={6}
                            w={'20%'}
                            position={'relative'}

                            h={'100vh'}>
                            <Center>
                                <Box mt={5} zIndex={9}>
                                    <Image src={LogoNctech} w={'100px'} h={'60px'} />
                                </Box>
                            </Center>
                            <Center>
                                <Box w={'60%'} mt={10} rounded={10} overflow={'hidden'}>
                                    {
                                        videos !== undefined ? (
                                            <Accordion allowToggle>
                                                {videos.map((item) => (
                                                    <AccordionItem key={item.id} bg={'white'}>
                                                        <AccordionButton onClick={() => setUrlVideo(item.url)}>
                                                            <Box as="span" flex="1" textAlign="left" fontWeight={'bold'}>
                                                                {item.nombre}
                                                            </Box>
                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                        <AccordionPanel pb={4} >
                                                            {item.hijos.map((hijo) => (
                                                                <Box onClick={() => setUrlVideo(hijo.url)} key={hijo.id} w={'100%'} pl={5} fontSize={'14px'} fontWeight={'500'} py={2} _hover={{ opacity: .8, cursor: 'pointer' }}>
                                                                    {hijo.nombre} {/* Nombre del hijo */}
                                                                </Box>
                                                            ))}
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                ))}
                                            </Accordion>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </Box>
                            </Center>
                        </Box>
                        <Box w={'90%'}
                            position={'relative'}
                            left={'-2'}
                            top={0}
                            h='100%'
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >

                            <RenderIf isTrue={type == "video"}>
                                <Center h="100vh"> {/* Centra verticalmente */}
                                    <AspectRatio w={['101%', '100%']} ratio={14 / 7.2} >
                                        <Box overflow="hidden" >
                                            <ReactPlayer
                                                width={(width / 2) + (height / 2) * 2}
                                                height={(height) - (100)}
                                                playing
                                                url={urlVideo}
                                                style={{ borderRadius: '15px' }}
                                            />
                                        </Box>
                                    </AspectRatio>
                                </Center>
                            </RenderIf>

                        </Box>
                        <Box bg={'transparent'}
                            zIndex={2}
                            w={'20%'}
                            position={'relative'}

                            h={'100vh'}>

                            <Center>
                                <Box mt={5} ml={5} >
                                    <Flex justify={'center'}>
                                        {
                                            Imagen === undefined ?
                                                <Avatar src={"user.username"} alignSelf={'center'} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                                :
                                                <Avatar alignSelf={'center'} src={Imagen} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                        }
                                        <Flex direction={'column'}>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} style={{fontSize: 'clamp(2rem, 8vw, 5rem)'}}>Asesor de Ventas</Box>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>{proyectoRData.vendedorName}</Box>

                                        </Flex>
                                    </Flex>
                                    <Flex justify={'start'} direction={'column'} zIndex={99999} w={'200px'} h={'500px'} mt={4}>
                                        {datos_videos.map((p, i) => {
                                            return (
                                                <Box rounded={'lg'} w={'auto'} bg={'gray.200'} overflow={'hidden'} >
                                                    <Box textAlign={'center'} p={1} fontWeight={'bold'}>Video de Presentación</Box>
                                                    <AspectRatio w={['100%', '100%']} ratio={2 / 2} >
                                                        <video controls={true} autoPlay={false} preload="auto" width="auto" height="50%"
                                                        >
                                                            <source src={p.url} controls />
                                                        </video>
                                                    </AspectRatio>
                                                </Box>
                                            )
                                        })}
                                    </Flex>
                                </Box>
                            </Center>
                        </Box>
                        <Botones zIndex={7} style={{ zIndex: 9999 }} />
                    </Flex>

                </RenderIf>

                <RenderIf isTrue={width >= 1100 && width <= 1399 && height >= 970 && height <= 1000} >
                    <Flex direction={'row'} w={'100%'} bg={'#0B3A5B'} position={'relative'}>

                        <Box w={'100%'}
                            position={'relative'}

                            top={0}
                            h='100%'
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >
                            <Center>
                                <Box mt={24} zIndex={9} position={'fixed'}>
                                    <Image src={LogoNctech} w={'100px'} h={'60px'} opacity={.9} />
                                </Box>
                            </Center>
                            <Box position={'absolute'} top={5} zIndex={9999} ml={10}>
                                {
                                    videos !== undefined ? (
                                        <Menu>
                                            <MenuButton as={Button} rounded={'full'} rightIcon={<ChevronDownIcon />}>
                                                Menú
                                            </MenuButton>
                                            <MenuList>
                                                {videos.map((item) => (
                                                    <>
                                                        <Button onClick={() => {
                                                            setUrlVideo(item.url);
                                                        }} fontSize={'17px'} w={'100%'} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                            <Box textAlign={'left'} w={'100%'}>
                                                                {item.nombre}
                                                            </Box>
                                                        </Button>
                                                        {item.hijos.map((hijo) => (
                                                            <Flex w={'100%'}>
                                                                <Button w={'100%'} key={hijo.id} onClick={() => setUrlVideo(hijo.url)} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                                    <Box textAlign={'left'} w={'100%'} fontWeight={'400'} pl={5}>
                                                                        {hijo.nombre}
                                                                    </Box>
                                                                </Button>
                                                            </Flex>
                                                        ))}
                                                        <MenuDivider />
                                                    </>
                                                ))}
                                                {/* {item.hijos.map((hijo) => (
                                        <MenuItem key={hijo.id} onClick={() => setUrlVideo(hijo.url)}>{hijo.nombre}</MenuItem>
                                        ))} */}
                                            </MenuList>
                                        </Menu>
                                    ) : (
                                        <></>
                                    )
                                }
                            </Box>
                            <RenderIf isTrue={type == "video"}>
                                <Center h="100vh"> {/* Centra verticalmente */}
                                    <AspectRatio w={['150%', '150%']} ratio={16 / 8} >
                                        <Box overflow="hidden" >
                                            <ReactPlayer
                                                width={(width / 2) + (height / 2) * 2}
                                                height={(height) - (100)}
                                                playing
                                                url={urlVideo}
                                                style={{ borderRadius: '15px' }}
                                            />
                                        </Box>
                                    </AspectRatio>
                                </Center>
                            </RenderIf>
                            <Botones zIndex={99999} />
                        </Box>

                    </Flex>
                </RenderIf>
                <RenderIf isTrue={width >= 900 && width <= 1099 && height >= 970 && height <= 1000} >
                    <Flex direction={'row'} w={'100%'} bg={'#0B3A5B'} position={'relative'}>

                        <Box w={'100%'}
                            position={'relative'}
                            top={0}
                            h='100%'
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >
                            <Center>
                                <Box mt={24} zIndex={9} position={'fixed'}>
                                    <Image src={LogoNctech} w={'100px'} h={'60px'} opacity={.9} />
                                </Box>
                            </Center>
                            <Box position={'absolute'} top={5} zIndex={9999} ml={10}>
                                {
                                    videos !== undefined ? (
                                        <Menu>
                                            <MenuButton as={Button} rounded={'full'} rightIcon={<ChevronDownIcon />}>
                                                Menú
                                            </MenuButton>
                                            <MenuList>
                                                {videos.map((item) => (
                                                    <>
                                                        <Button onClick={() => {
                                                            setUrlVideo(item.url);
                                                        }} fontSize={'17px'} w={'100%'} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                            <Box textAlign={'left'} w={'100%'}>
                                                                {item.nombre}
                                                            </Box>
                                                        </Button>
                                                        {item.hijos.map((hijo) => (
                                                            <Flex w={'100%'}>
                                                                <Button w={'100%'} key={hijo.id} onClick={() => setUrlVideo(hijo.url)} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                                    <Box textAlign={'left'} w={'100%'} fontWeight={'400'} pl={5}>
                                                                        {hijo.nombre}
                                                                    </Box>
                                                                </Button>
                                                            </Flex>
                                                        ))}
                                                        <MenuDivider />
                                                    </>
                                                ))}
                                                {/* {item.hijos.map((hijo) => (
                                        <MenuItem key={hijo.id} onClick={() => setUrlVideo(hijo.url)}>{hijo.nombre}</MenuItem>
                                        ))} */}
                                            </MenuList>
                                        </Menu>
                                    ) : (
                                        <></>
                                    )
                                }
                            </Box>
                            <RenderIf isTrue={type == "video"}>
                                <Center h="100vh"> {/* Centra verticalmente */}
                                    <AspectRatio w={['150%', '150%']} ratio={16 / 9} >
                                        <Box overflow="hidden" zIndex={9999} >
                                            <ReactPlayer
                                                width={(width / 2) + (height / 2) * 2}
                                                height={(height) - (100)}
                                                playing
                                                url={urlVideo}
                                                style={{ borderRadius: '15px' }}
                                            />
                                        </Box>
                                    </AspectRatio>
                                </Center>
                            </RenderIf>
                            <Botones zIndex={99999} />
                        </Box>

                    </Flex>
                </RenderIf>
                <RenderIf isTrue={width >= 600 && width <= 899 && height >= 970 && height <= 1000} >
                    <Flex direction={'row'} w={'100%'} bg={'#0B3A5B'} position={'relative'}>

                        <Box w={'100%'}
                            position={'relative'}

                            top={0}
                            h='100%'
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >
                            <Center>
                                <Box mt={24} zIndex={9} position={'fixed'}>
                                    <Image src={LogoNctech} w={'100px'} h={'60px'} opacity={.9} />
                                </Box>
                            </Center>
                            <Box position={'absolute'} top={5} zIndex={9999} ml={10}>
                                {
                                    videos !== undefined ? (
                                        <Menu>
                                            <MenuButton as={Button} rounded={'full'} rightIcon={<ChevronDownIcon />}>
                                                Menú
                                            </MenuButton>
                                            <MenuList>
                                                {videos.map((item) => (
                                                    <>
                                                        <Button onClick={() => {
                                                            setUrlVideo(item.url);
                                                        }} fontSize={'17px'} w={'100%'} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                            <Box textAlign={'left'} w={'100%'}>
                                                                {item.nombre}
                                                            </Box>
                                                        </Button>
                                                        {item.hijos.map((hijo) => (
                                                            <Flex w={'100%'}>
                                                                <Button w={'100%'} key={hijo.id} onClick={() => setUrlVideo(hijo.url)} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                                    <Box textAlign={'left'} w={'100%'} fontWeight={'400'} pl={5}>
                                                                        {hijo.nombre}
                                                                    </Box>
                                                                </Button>
                                                            </Flex>
                                                        ))}
                                                        <MenuDivider />
                                                    </>
                                                ))}
                                                {/* {item.hijos.map((hijo) => (
                                        <MenuItem key={hijo.id} onClick={() => setUrlVideo(hijo.url)}>{hijo.nombre}</MenuItem>
                                        ))} */}
                                            </MenuList>
                                        </Menu>
                                    ) : (
                                        <></>
                                    )
                                }
                            </Box>
                            <RenderIf isTrue={type == "video"}>
                                <Center h="100vh" w={'100%'}> {/* Centra verticalmente */}
                                    <AspectRatio w={['150%', '150%']} ratio={16 / 9} >
                                        <Box overflow="hidden">
                                            <ReactPlayer
                                                width={(width / 2) + (height / 2) * 2}
                                                height={(height) - (100)}
                                                playing
                                                url={urlVideo}
                                                style={{ borderRadius: '15px' }}
                                            />
                                        </Box>
                                    </AspectRatio>
                                </Center>
                            </RenderIf>
                            <Botones zIndex={99999} />
                        </Box>

                    </Flex>
                </RenderIf>
                <RenderIf isTrue={width >= 300 && width <= 599 && height >= 970 && height <= 1000} >
                    <Flex direction={'row'} w={'100%'} bg={'#0B3A5B'}>

                        <Box w={'100%'}
                            position={'relative'}

                            top={0}
                            h='100%'
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >
                            <Center>
                                <Box mt={24} zIndex={9} position={'fixed'}>
                                    <Image src={LogoNctech} w={'100px'} h={'60px'} opacity={.9} />
                                </Box>
                            </Center>
                            <Box position={'absolute'} top={5} zIndex={9999} ml={10}>
                                {
                                    videos !== undefined ? (
                                        <Menu>
                                            <MenuButton as={Button} rounded={'full'} rightIcon={<ChevronDownIcon />}>
                                                Menú
                                            </MenuButton>
                                            <MenuList>
                                                {videos.map((item) => (
                                                    <>
                                                        <Button onClick={() => {
                                                            setUrlVideo(item.url);
                                                        }} fontSize={'17px'} w={'100%'} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                            <Box textAlign={'left'} w={'100%'}>
                                                                {item.nombre}
                                                            </Box>
                                                        </Button>
                                                        {item.hijos.map((hijo) => (
                                                            <Flex w={'100%'}>
                                                                <Button w={'100%'} key={hijo.id} onClick={() => setUrlVideo(hijo.url)} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                                    <Box textAlign={'left'} w={'100%'} fontWeight={'400'} pl={5}>
                                                                        {hijo.nombre}
                                                                    </Box>
                                                                </Button>
                                                            </Flex>
                                                        ))}
                                                        <MenuDivider />
                                                    </>
                                                ))}
                                                {/* {item.hijos.map((hijo) => (
                                        <MenuItem key={hijo.id} onClick={() => setUrlVideo(hijo.url)}>{hijo.nombre}</MenuItem>
                                        ))} */}
                                            </MenuList>
                                        </Menu>
                                    ) : (
                                        <></>
                                    )
                                }
                            </Box>
                            <RenderIf isTrue={type == "video"}>
                                <Center h="100vh" w={'100%'}> {/* Centra verticalmente */}
                                    <AspectRatio w={['150%', '150%']} ratio={16 / 9} >
                                        <Box overflow="hidden">
                                            <ReactPlayer
                                                width={(width / 2) + (height / 2) * 2}
                                                height={(height) - (100)}
                                                playing
                                                url={urlVideo}
                                                style={{ borderRadius: '15px' }}
                                            />
                                        </Box>
                                    </AspectRatio>
                                </Center>
                            </RenderIf>
                            <Botones zIndex={99999} />
                        </Box>

                    </Flex>
                </RenderIf>
                     {/* Grande  */}
                <RenderIf isTrue={width >= 1701 && width <= 2000 && height >= 313 && height <= 969} >
                    <Flex direction={'row'} w={'100%'} bg={'#0B3A5B'}>
                        <Box bg={'transparent'}
                            zIndex={6}
                            w={'20%'}
                            position={'relative'}

                            h={'100vh'}>
                            <Center>
                                <Box mt={5} zIndex={9}>
                                    <Image src={LogoNctech} w={'100px'} h={'60px'} />
                                </Box>
                            </Center>
                            <Center>
                                {/* Grande */}
                            <Box rounded={10} overflow={'hidden'}>
                                    {
                                        videos !== undefined ? (
                                            <Accordion allowToggle style={{
                                                width: 'clamp(250px, 15vw, 500px)',
                                                height: 'clamp(600px, 1vh, 250px)',
                                                padding:'clamp(1rem, 1vw, 1rem)',
                                              }}>
                                                {videos.map((item) => (
                                                    <AccordionItem key={item.id} bg={'white'}>
                                                        <AccordionButton onClick={() => setUrlVideo(item.url)}>
                                                            <Box as="span" flex="1" textAlign="left" fontWeight={'bold'}>
                                                                {item.nombre}
                                                            </Box>
                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                        <AccordionPanel pb={10} style={{ maxHeight: '500px', overflowY: 'auto'}}>
                                                            {item.hijos.map((hijo) => (
                                                                <Box onClick={() => setUrlVideo(hijo.url)} key={hijo.id} w={'100%'} pl={5} fontSize={'14px'} fontWeight={'500'} py={2} _hover={{ opacity: .8, cursor: 'pointer' }}>
                                                                    {hijo.nombre} {/* Nombre del hijo */}
                                                                </Box>
                                                            ))}
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                ))}
                                            </Accordion>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </Box>
                            </Center>
                        </Box>
                        <Box w={'80%'}
                            position={'relative'}
                            top={0}
                            h='100%'
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >

                            <RenderIf isTrue={type == "video"}>
                                <Center h="100vh"> {/* Centra verticalmente */}
                                    <AspectRatio w={['150%', '150%']} ratio={16 / 9} >
                                        <Box overflow="hidden" >
                                            <ReactPlayer
                                                width={width}
                                                height={(height) - (50)}
                                                playing
                                                url={urlVideo}

                                            />
                                        </Box>
                                    </AspectRatio>
                                </Center>
                            </RenderIf>

                        </Box>
                        <Box bg={'transparent'}
                            zIndex={2}
                            w={'20%'}
                            position={'relative'}

                            h={'100vh'}>

                            <Center>
                                <Box mt={5} ml={5} >
                                    <Flex justify={'center'}>
                                        {
                                            Imagen === undefined ?
                                                <Avatar src={"user.username"} alignSelf={'center'} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                                :
                                                <Avatar alignSelf={'center'} src={Imagen} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                        }
                                        <Flex direction={'column'}>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>Asesor de Ventas</Box>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>{proyectoRData.vendedorName}</Box>

                                        </Flex>
                                    </Flex>
                                    {/* grande */}
                                    <Flex justify={'start'} direction={'column'} zIndex={99999} w={'200px'} h={'500px'} mt={8} >
                                        {datos_videos.map((p, i) => {
                                            return (
                                                <Box rounded={'lg'} w={'auto'} bg={'gray.200'} overflow={'hidden'} mb={5}>
                                                    <Box textAlign={'center'} p={1} fontWeight={'bold'} style={{fontSize: 'clamp(1rem, 8vh, .8rem)'}}>Video de Presentación</Box>
                                                    <AspectRatio w={['100%', '100%']} ratio={2 / 2} >
                                                        <video controls={true} autoPlay={false} preload="auto" width="auto" height="50%"
                                                        >
                                                            <source src={p.url} controls />
                                                        </video>
                                                    </AspectRatio>
                                                </Box>
                                            )
                                        })}
                                    </Flex>
                                </Box>
                            </Center>
                        </Box>
                        <Botones zIndex={7} style={{ zIndex: 9999 }} />
                    </Flex>
                </RenderIf>


                <RenderIf isTrue={width >= 1401 && width <= 1700 && height >= 313 && height <= 969} >


                    <Flex direction={'row'} w={'100%'} bg={'#0B3A5B'} position={'relative'}>
                        <Box bg={'#0B3A5B'}
                            zIndex={6}
                            w={'20%'}
                            position={'relative'}

                            h={'100vh'}>
                            <Center>
                                <Box mt={5} zIndex={9}>
                                    <Image src={LogoNctech} w={'100px'} h={'60px'} />
                                </Box>
                            </Center>
                            <Center>
                                <Box  mt={10} rounded={10} overflow={'hidden'}>
                                    {
                                        videos !== undefined ? (
                                            <Accordion allowToggle style={{
                                                width: 'clamp(250px, 15vw, 500px)',
                                                height: 'clamp(600px, 1vh, 250px)',
                                                padding:'clamp(1rem, 1vw, 1rem)',
                                              }}>
                                                {videos.map((item) => (
                                                    <AccordionItem key={item.id} bg={'white'}>
                                                        <AccordionButton onClick={() => setUrlVideo(item.url)}>
                                                            <Box as="span" flex="1" textAlign="left" fontWeight={'bold'} fontFamily={'Montserrat'} style={{fontSize: 'clamp(14px, 8rem, 14px)'}} >
                                                                {item.nombre}
                                                            </Box>
                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                        <AccordionPanel pb={10} style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                                            {item.hijos.map((hijo) => (
                                                                <Box onClick={() => setUrlVideo(hijo.url)} key={hijo.id} w={'100%'} pl={5} fontSize={'14px'} fontWeight={'500'} py={2} _hover={{ opacity: .8, cursor: 'pointer' }}>
                                                                    {hijo.nombre} {/* Nombre del hijo */}
                                                                </Box>
                                                            ))}
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                ))}
                                            </Accordion>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </Box>
                            </Center>
                        </Box>
                        <Box w={'90%'}
                            position={'relative'}
                            left={'-2'}
                            top={0}
                            h='100%'
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >

                            <RenderIf isTrue={type == "video"}>

                                <Center h="100vh"> {/* Centra verticalmente */}
                                    <AspectRatio w={['101%', '100%']} ratio={16 / 9} >
                                        <Box overflow="hidden">
                                            <ReactPlayer
                                                width={(width / 2) + (height / 2) * 2}
                                                height={(height) - (100)}
                                                playing
                                                url={urlVideo}
                                                style={{ borderRadius: '15px' }}
                                            />
                                        </Box>
                                    </AspectRatio>
                                </Center>
                            </RenderIf>

                        </Box>
                        {/* Video de presentación*/}
                        <Box bg={'transparent'}
                            zIndex={2}
                            w={'20%'}
                            position={'relative'}

                            h={'100vh'}>

                            <Center>
                                <Box mt={5} ml={5} >
                                    <Flex justify={'center'}>
                                        {
                                            Imagen === undefined ?
                                                <Avatar src={"user.username"} alignSelf={'center'} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                                :
                                                <Avatar alignSelf={'center'} src={Imagen} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                        }
                                        <Flex direction={'column'}>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} style={{fontSize: 'clamp(1rem, 8vw, 1rem)'}}>Asesor de Ventas</Box>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} style={{fontSize: 'clamp(1rem, 8vw, 1rem)'}}>{proyectoRData.vendedorName}</Box>

                                        </Flex>
                                    </Flex>
                                    <Flex justify={'start'} direction={'column'} zIndex={99999} w={'200px'} h={'500px'} mt={4}>
                                        {datos_videos.map((p, i) => {
                                            return (
                                                <Box rounded={'lg'} w={'auto'} bg={'gray.200'} overflow={'hidden'} style={{
                                                    width: 'clamp(200px, 10vw, 400px)',
                                                    height: 'clamp(230px, 6vw, 250px)',
                                                    marginRight: 'clamp(20px, calc(20px + 1vw), 20px)'
                                                  }} mb={5}>
                                                    <Box textAlign={'center'} p={1} fontWeight={'bold'} style={{fontSize: 'clamp(1rem, 8vh, .8rem)'}}>Video de Presentación</Box>
                                                    <AspectRatio w={['100%', '100%']} ratio={2 / 2} >
                                                        <video controls={true} autoPlay={false} preload="auto" width="auto" height="50%"
                                                        >
                                                            <source src={p.url} controls />
                                                        </video>
                                                    </AspectRatio>
                                                </Box>
                                            )
                                        })}
                                    </Flex>
                                </Box>
                            </Center>
                        </Box>
                        <Botones zIndex={9} style={{ zIndex: 9999 }} />
                    </Flex>

                </RenderIf>

                <RenderIf isTrue={width >= 1101 && width <= 1400 && height >= 313 && height <= 969} >
                    <Flex direction={'row'} w={'100%'} bg={'#0B3A5B'} position={'relative'}>

                        <Box w={'100%'}
                            position={'relative'}

                            top={0}
                            h='100%'
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >
                            <Center>
                                <Box mt={24} zIndex={9} position={'fixed'}>
                                    <Image src={LogoNctech} w={'100px'} h={'60px'} opacity={.9} />
                                </Box>
                            </Center>
                            <Box position={'absolute'} top={5} zIndex={9999} ml={10}>
                                {
                                    videos !== undefined ? (
                                        <Menu>
                                            <MenuButton as={Button} rounded={'full'} rightIcon={<ArrowUpIcon sx={buttonStyles}
                                            />} onMouseEnter={handleHover}
                                                onMouseLeave={handleHover}
                                                _focus={{ boxShadow: "none", outline: "none" }}
                                            >
                                                Videos
                                            </MenuButton>
                                            <MenuList>
                                                {videos.map((item) => (
                                                    <>
                                                        <Button onClick={() => {
                                                            setUrlVideo(item.url);
                                                        }} fontSize={'17px'} w={'100%'} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                            <Box textAlign={'left'} w={'100%'}>
                                                                {item.nombre}
                                                            </Box>
                                                        </Button>
                                                        {item.hijos.map((hijo) => (
                                                            <Flex w={'100%'}>
                                                                <Button w={'100%'} key={hijo.id} onClick={() => setUrlVideo(hijo.url)} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                                    <Box textAlign={'left'} w={'100%'} fontWeight={'400'} pl={5}>
                                                                        {hijo.nombre}
                                                                    </Box>
                                                                </Button>
                                                            </Flex>
                                                        ))}
                                                        <MenuDivider />
                                                    </>
                                                ))}
                                                {/* {item.hijos.map((hijo) => (
                                        <MenuItem key={hijo.id} onClick={() => setUrlVideo(hijo.url)}>{hijo.nombre}</MenuItem>
                                        ))} */}
                                            </MenuList>
                                        </Menu>
                                    ) : (
                                        <></>
                                    )
                                }
                            </Box>
                            <RenderIf isTrue={type == "video"}>
                                <Center h="100vh"> {/* Centra verticalmente */}
                                    <AspectRatio w={['101%', '100%']} ratio={16 / 9} >
                                        <Box overflow="hidden">
                                            <ReactPlayer
                                                width={(width / 2) + (height / 2) * 2}
                                                height={(height) - (100)}
                                                playing
                                                url={urlVideo}
                                                style={{ borderRadius: '15px' }}
                                            />
                                        </Box>
                                    </AspectRatio>
                                </Center>
                            </RenderIf>
                            <Botones zIndex={99999} />
                        </Box>

                    </Flex>
                </RenderIf>
                <RenderIf isTrue={width >= 901 && width <= 1100 && height >= 313 && height <= 969} >
                    <Flex direction={'row'} w={'100%'} bg={'#0B3A5B'} position={'relative'}>

                        <Box w={'100%'}
                            position={'relative'}
                            top={0}
                            h='100%'
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >
                            <Center>
                                <Box mt={24} zIndex={9} position={'fixed'}>
                                    <Image src={LogoNctech} w={'100px'} h={'60px'} opacity={.9} />
                                </Box>
                            </Center>
                            <Box position={'absolute'} top={5} zIndex={9999} ml={10}>
                                {
                                    videos !== undefined ? (
                                        <Menu>
                                            <MenuButton as={Button} rounded={'full'} rightIcon={<ArrowUpIcon sx={buttonStyles}
                                            />} onMouseEnter={handleHover}
                                                onMouseLeave={handleHover}
                                                _focus={{ boxShadow: "none", outline: "none" }}
                                            >
                                                Videos
                                            </MenuButton>
                                            <MenuList>
                                                {videos.map((item) => (
                                                    <>
                                                        <Button onClick={() => {
                                                            setUrlVideo(item.url);
                                                        }} fontSize={'17px'} w={'100%'} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                            <Box textAlign={'left'} w={'100%'}>
                                                                {item.nombre}
                                                            </Box>
                                                        </Button>
                                                        {item.hijos.map((hijo) => (
                                                            <Flex w={'100%'}>
                                                                <Button w={'100%'} key={hijo.id} onClick={() => setUrlVideo(hijo.url)} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                                    <Box textAlign={'left'} w={'100%'} fontWeight={'400'} pl={5}>
                                                                        {hijo.nombre}
                                                                    </Box>
                                                                </Button>
                                                            </Flex>
                                                        ))}
                                                        <MenuDivider />
                                                    </>
                                                ))}
                                                {/* {item.hijos.map((hijo) => (
                                        <MenuItem key={hijo.id} onClick={() => setUrlVideo(hijo.url)}>{hijo.nombre}</MenuItem>
                                        ))} */}
                                            </MenuList>
                                        </Menu>
                                    ) : (
                                        <></>
                                    )
                                }
                            </Box>
                            <RenderIf isTrue={type == "video"}>
                                <Center h="100vh"> {/* Centra verticalmente */}
                                    <AspectRatio w={['150%', '150%']} ratio={16 / 9} >
                                        <Box overflow="hidden"  >
                                            <ReactPlayer
                                                width={(width / 2) + (height / 2) * 2}
                                                height={(height) - (100)}
                                                playing
                                                url={urlVideo}
                                                style={{ borderRadius: '15px' }}
                                            />
                                        </Box>
                                    </AspectRatio>
                                </Center>
                            </RenderIf>
                            <Botones zIndex={99999} />
                        </Box>

                    </Flex>
                </RenderIf>
                <RenderIf isTrue={width >= 601 && width <= 900 && height >= 313 && height <= 969} >
                    <Flex direction={'row'} w={'100%'} bg={'#0B3A5B'} position={'relative'}>

                        <Box w={'100%'}
                            position={'relative'}

                            top={0}
                            h='100%'
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >
                            <Center>
                                <Box mt={24} zIndex={9} position={'fixed'}>
                                    <Image src={LogoNctech} w={'100px'} h={'60px'} opacity={.9} />
                                </Box>
                            </Center>
                            <Box position={'absolute'} top={5} zIndex={9999} ml={10}>
                                {
                                    videos !== undefined ? (
                                        <Menu>
                                            <MenuButton as={Button} rounded={'full'} rightIcon={<ArrowUpIcon sx={buttonStyles}
                                            />} onMouseEnter={handleHover}
                                                onMouseLeave={handleHover}
                                                _focus={{ boxShadow: "none", outline: "none" }}
                                            >
                                                Videos
                                            </MenuButton>
                                            <MenuList>
                                                {videos.map((item) => (
                                                    <>
                                                        <Button onClick={() => {
                                                            setUrlVideo(item.url);
                                                        }} fontSize={'17px'} w={'100%'} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                            <Box textAlign={'left'} w={'100%'}>
                                                                {item.nombre}
                                                            </Box>
                                                        </Button>
                                                        {item.hijos.map((hijo) => (
                                                            <Flex w={'100%'}>
                                                                <Button w={'100%'} key={hijo.id} onClick={() => setUrlVideo(hijo.url)} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                                    <Box textAlign={'left'} w={'100%'} fontWeight={'400'} pl={5}>
                                                                        {hijo.nombre}
                                                                    </Box>
                                                                </Button>
                                                            </Flex>
                                                        ))}
                                                        <MenuDivider />
                                                    </>
                                                ))}
                                                {/* {item.hijos.map((hijo) => (
                                        <MenuItem key={hijo.id} onClick={() => setUrlVideo(hijo.url)}>{hijo.nombre}</MenuItem>
                                        ))} */}
                                            </MenuList>
                                        </Menu>
                                    ) : (
                                        <></>
                                    )
                                }
                            </Box>
                            <RenderIf isTrue={type == "video"}>
                                <Center h="100vh" w={'100%'}> {/* Centra verticalmente */}
                                    <AspectRatio w={['150%', '150%']} ratio={16 / 9} >
                                        <Box overflow="hidden">
                                            <ReactPlayer
                                                width={(width / 2) + (height / 2) * 2}
                                                height={(height) - (100)}
                                                playing
                                                url={urlVideo}
                                                style={{ borderRadius: '15px' }}
                                            />
                                        </Box>
                                    </AspectRatio>
                                </Center>
                            </RenderIf>
                            <Botones zIndex={99999} />
                        </Box>

                    </Flex>
                </RenderIf>
                <RenderIf isTrue={width >= 301 && width <= 600 && height >= 313 && height <= 969} >
                    <Flex direction={'row'} w={'100%'} bg={'#0B3A5B'}>
                        <Box w={'100%'}
                            top={0}
                            h='100%'
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >
                            <Center>
                                <Box mt={24} zIndex={9} position={'fixed'}>
                                    <Image src={LogoNctech} w={'100px'} h={'60px'} opacity={.9} />
                                </Box>
                            </Center>
                            <Box position={'absolute'} top={5} zIndex={9999} ml={10}>
                                {
                                    videos !== undefined ? (
                                        <Menu>
                                            <MenuButton as={Button} rounded={'full'} rightIcon={<ArrowUpIcon sx={buttonStyles}
                                            />} onMouseEnter={handleHover}
                                                onMouseLeave={handleHover}
                                                _focus={{ boxShadow: "none", outline: "none" }}
                                            >
                                                Videos
                                            </MenuButton>
                                            <MenuList>
                                                {videos.map((item) => (
                                                    <>
                                                        <Button onClick={() => {
                                                            setUrlVideo(item.url);
                                                        }} fontSize={'17px'} w={'100%'} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                            <Box textAlign={'left'} w={'100%'}>
                                                                {item.nombre}
                                                            </Box>
                                                        </Button>
                                                        {item.hijos.map((hijo) => (
                                                            <Flex w={'100%'}>
                                                                <Button w={'100%'} key={hijo.id} onClick={() => setUrlVideo(hijo.url)} bg={'white'} _focus={{ boxShadow: "none", outline: "none" }}>
                                                                    <Box textAlign={'left'} w={'100%'} fontWeight={'400'} pl={5}>
                                                                        {hijo.nombre}
                                                                    </Box>
                                                                </Button>
                                                            </Flex>
                                                        ))}
                                                        <MenuDivider />
                                                    </>
                                                ))}
                                                {/* {item.hijos.map((hijo) => (
                                        <MenuItem key={hijo.id} onClick={() => setUrlVideo(hijo.url)}>{hijo.nombre}</MenuItem>
                                        ))} */}
                                            </MenuList>
                                        </Menu>
                                    ) : (
                                        <></>
                                    )
                                }
                            </Box>
                            <RenderIf isTrue={type == "video"}>
                                <Center h="100vh" w={'100%'}> {/* Centra verticalmente */}
                                    <AspectRatio w={['150%', '150%']} ratio={16 / 9} >
                                        <Box overflow="hidden">
                                            <ReactPlayer
                                                width={(width / 2) + (height / 2) * 2}
                                                height={(height) - (100)}
                                                playing={play}
                                                url={urlVideo}
                                                style={{ borderRadius: '15px' }}
                                            />
                                        </Box>

                                    </AspectRatio>
                                    {urlVideo.length !== 0 ?
                                        <Box position={'fixed'} bottom={'32%'}>
                                            {
                                                play === true ?
                                                    <Button onClick={() => setPlay(!play)} _focus={{ boxShadow: "none", outline: "none" }}>Stop</Button>
                                                    :
                                                    <Button onClick={() => setPlay(!play)} _focus={{ boxShadow: "none", outline: "none" }}>Play</Button>
                                            }
                                        </Box> :
                                        <></>
                                    }
                                </Center>
                            </RenderIf>
                            <Botones zIndex={99999} />
                        </Box>

                    </Flex>
                </RenderIf>

            </Flex>


        </Box>

    );
};
//REDUX
const mapStateProps = (state) => ({
    proyectoRData: state.proyectoReducer.proyectoRData,
    Imagen: state.avatarReducer.Image,
    isLoading: state.avatarReducer.isLoading,
    error: state.avatarReducer.error,
    videos: state.videosReducer.videos,
    videoDefault: state.videosReducer.videoDefault
})

const PreviewVideo = connect(mapStateProps, { get_avatar, get_proyecto, get_video, get_video_default })(_PreviewVideo)

export default PreviewVideo;

