import React, { useEffect, useState } from 'react';
import Logo from '../../assets/LOGOS_NCTECH-01.svg'
import LogoBlack from '../../assets/logo-nctech-1-1.png'
import Cover from '../../assets/image1.jpg'
import {
  Input, VStack, SimpleGrid, HStack, Image, Stack, useToast, Button,
  Text, Box, Center,
  Spacer, Spinner, Select, Flex
} from "@chakra-ui/react"
import { FaLinkedin, FaWhatsappSquare } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';
import { useParams } from "react-router-dom";
import { RenderIf } from '../../hooks/renderIf';
import { connect } from 'react-redux';
import { get_contacto, get_codigo, formato_email, get_proyecto } from '../../actions/index'
import FondoGri from '../../assets/FONDO_GRIS.png'
const _LoginOnAccess = (props) => {
  let { proyecto, proyectoId, vendedor, vendedorName } = useParams();
  const toast = useToast()
  const [userName, setUserName] = useState()
  const [userNameCode, setUserNameCode] = useState()
  const [userNameValido, setUserNameValido] = useState()
  const [errores, setErrores] = useState()
  const [next, setNext] = useState(false)
  const [cargando, setCargando] = useState(false)
  const { contacto, error, isLoading, codigo } = props || {};
  const [duracion, setDuracion] = useState(5);
  const [unidadTiempo, setUnidadTiempo] = useState('horas');
  const [nombreContacto, setNombreContacto] = useState()
  const [correoContacto, setCorreoContacto] = useState()
  const [dominio, setDominio] = useState([])
  const opcionesUnidadTiempo = ['minutos', 'horas', 'dias', 'semanas', 'meses'];

  const calcularDuracionEnMilisegundos = () => {
    switch (unidadTiempo) {
      case 'minutos':
        return duracion * 60 * 1000;
      case 'horas':
        return duracion * 60 * 60 * 1000;
      case 'dias':
        return duracion * 24 * 60 * 60 * 1000;
      case 'semanas':
        return duracion * 7 * 24 * 60 * 60 * 1000;
      case 'meses':
        // Asumimos 1 mes = 30 días (aproximado)
        return duracion * 30 * 24 * 60 * 60 * 1000;
      default:
        return 0;
    }
  };

  const guardarDuracionEnLocalStorage = (correo, nombre) => {
    const duracionEnMilisegundos = calcularDuracionEnMilisegundos();
    setNombreContacto(nombre)
    setCorreoContacto(correo)
    if (duracionEnMilisegundos > 0) {
      const ahora = new Date();
      const expiracion = new Date(ahora.getTime() + duracionEnMilisegundos);
      const datosConCaducidad = {
        valor: duracionEnMilisegundos,
        expiracion: expiracion.getTime(),
        correo,
        nombre
      };

      localStorage.setItem('duracionSeleccionada', JSON.stringify(datosConCaducidad));
      console.log(`Duración guardada en localStorage hasta ${expiracion}`);
    }
  };

  const obtenerDuracionDesdeLocalStorage = () => {
    const datosConCaducidad = JSON.parse(localStorage.getItem('duracionSeleccionada'));

    if (datosConCaducidad) {
      const ahora = new Date().getTime();

      if (ahora < datosConCaducidad.expiracion) {
        return datosConCaducidad.valor;
      } else {
        // Los datos han caducado, eliminarlos
        localStorage.removeItem('duracionSeleccionada');
      }
    }

    return null;
  };

  const handleDuracionChange = (event) => {
    setDuracion(parseInt(event.target.value, 10));
  };

  const handleUnidadTiempoChange = (event) => {
    setUnidadTiempo(event.target.value);
  };

  const handleCalcularDuracion = (correo, nombre) => {
    guardarDuracionEnLocalStorage(correo, nombre);
    const duracionDesdeLocalStorage = obtenerDuracionDesdeLocalStorage();
    console.log(`Duración obtenida desde localStorage: ${duracionDesdeLocalStorage}`);
    // Aquí puedes utilizar duracionDesdeLocalStorage como necesites
  };


  const toas = () => {
    toast({
      title: "Error.",
      description: "Error al iniciar sesión, verifica tus datos y vuelve a intentar",
      status: "error",
      duration: 5000,
      isClosable: true,
    })
  }

  useEffect(() => {
    const expresionRegular = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    setUserNameValido(expresionRegular.test(userName));
   
  }, [userName]);

  const extraerDominio = (correo) => {
    const regex = /@([^@]+)$/;
    const match = correo.match(regex);
    return match ? match[1] : null;
  };

  const forgot = () => {
    window.location = 'forgotpassword'
  }
  function redirect() {
    window.location = '/signup'
  }
  function privacidad() {
    console.log("aviso de privacidad")
  }
  function LoginFacebook() {
    window.location = 'https://s-api-users.herokuapp.com/connect/facebook'
  }
  function LoginLinkedin() {
    window.location = 'https://s-api-users.herokuapp.com/connect/linkedin'
  }
  function LoginGoogle() {
    window.location = 'https://s-api-users.herokuapp.com/connect/google'
  }

  const newCode = () => {
    setNext(false)
    setCargando(false)
  }

  const session = () => {
    console.log(userNameCode, codigo)
    if (userNameCode == codigo) {
      handleCalcularDuracion(contacto.correo, contacto.nombre)
      window.location = "/me/" + contacto.correo + "/" + proyecto + "/" + contacto.nombre + "/" + proyectoId + "/" + vendedor + "/" + vendedorName

    } else {
      setErrores("Código erroneo, favor de validar e intenter nuevamente.")
    }
  }

  const handlerCode = async () => {
    if (userNameValido) {
      await props.get_contacto(userName, proyecto).then((res) => {
        if (res == undefined) {
        }
      })

    }
    else {
      //Enviamos el error
      await props.formato_email(userName).then((res) => {
        if (res == undefined) {
          console.log("error de formato", res)
          setErrores(`El usuario ${userName}, no tiene el formato requerido.`)
          // props.contacto_error()
        }
      })
    }
  }

  useEffect(() => {
    if (contacto.nombre == undefined) {
      console.log("entra no null", contacto.nombre)
      setErrores(error)
    }
    console.log(codigo)
  }, [contacto, codigo])
  useEffect(() => {
    const duracionDesdeLocalStorage = obtenerDuracionDesdeLocalStorage();

    console.log(`Duración obtenida desde localStorage: ${duracionDesdeLocalStorage}`);

    if (duracionDesdeLocalStorage) {
      const { correo, nombre } = JSON.parse(localStorage.getItem('duracionSeleccionada'));
      console.log(correo, nombre)
      window.location = "/me/" + correo + "/" + proyecto + "/" + nombre + "/" + proyectoId + "/" + vendedor + "/" + vendedorName
    }
    // Aquí puedes utilizar duracionDesdeLocalStorage como necesites
  }, []);

  useEffect(() => {
    
         props.get_proyecto(proyectoId)
          
 
   }, []);
   const validarDominio = () => {
    const dominios = props.proyectoRData.dominios.map(dominio => dominio.value); // Extraer los valores de los dominios permitidos
    console.log(dominios, userNameValido);

    const exdominio = extraerDominio(userName);
    console.log(exdominio);

    if (dominios.includes(exdominio)) {
      setErrores("Dominio válido");
      setUserNameValido(true);
      window.location = "/me/" + exdominio + "/" + proyecto + "/" + userName + "/" + proyectoId + "/" + vendedor + "/" + vendedorName
    } else {
      setErrores("Dominio no válido");
      setUserNameValido(false);
    }
   }
 
  return (
    <Stack direction={{ base: 'column', lg: 'row' }} m={'0px'} spacing={0} >
      <Box mt={0} visibility={{ base: 'hidden', lg: 'visible' }} w={{ base: '0px', lg: '100%' }}
        h={{ base: '0px', lg: "100vh" }} borderWidth={{ base: '0px', lg: '1px' }} bgImage={FondoGri}>

        <Image src={Logo} w={'132px'} mt={'5'} ml={'10'} position={'absolute'} />
        {/* Logo    */}

        <Center>

          <Box justifyContent={'center'} w={'100%'}>
            <Image src={Cover} w={{ base: '100%', md: '100%', lg: '100%' }} h={'100vh'} objectFit={'cover'} />
          </Box>
        </Center>
        {/* Ilustración */}
        <HStack spacing={'10'}>
          <Box bottom={10} position={'absolute'} left={10} visibility={{ base: 'hidden', lg: 'visible' }}>
            <FaWhatsappSquare fontSize={'20pt'} color={'white'} />
          </Box>
          <Spacer />
          <Box bottom={10} position={'absolute'} left={10} visibility={{ base: 'hidden', lg: 'visible' }}>
            <SiGmail fontSize={'20pt'} color={'white'} />
          </Box>
          <Box bottom={10} position={'absolute'} left={20} visibility={{ base: 'hidden', lg: 'visible' }}>
            <FaLinkedin fontSize={'20pt'} color={'white'} />
          </Box>
        </HStack>

        {/* Redes Sociales */}
      </Box>
      {/* Fin primera sección */}
      <Box w={{ base: '100%', lg: '60%' }} h="100vh" bg="gray.50" m={0} bgImage={FondoGri}>
        {/* Inicio-Logo */}

        <Center>
          <Box mt={'10'} zIndex={2}>
            <Image visibility={{ base: 'visible', lg: 'hidden' }} src={LogoBlack} w={'132px'} mt={'5'} />
            {/* <Image visibility={{base:'visible', lg:'hidden'}} src={LogoBlack} w={'132px'} mt={'5'} ml={'10'} />  */}
          </Box>
        </Center>
        {/* Fin-Logo */}
        {/* Inicio-titulos */}

        <Center mt={{ base: 14, lg: 0, "2xl": 24 }} >

          <VStack spacing={1}>
            <Center>
              <Text
                textAlign={'center'}
                fontFamily={'Montserrat'}
                fontWeight={'bold'}
                fontSize={'33px'}
                color={'gray.600'}>Portal de Clientes NC Tech</Text>
            </Center>

            <Text mx={5}
              textAlign={'center'}
              fontSize={'15px'}
              fontWeight={'normal'}
              color={'gray.500'}
              fontFamily={'Montserrat'}
              p={'12px'}
              w={'80%'}
            >¡Bienvenido! Te compartimos información relevante sobre tu proyecto con NC Tech, Inicia sesión ahora para explorar los detalles.</Text>

            <Text fontFamily={'Montserrat'}
              fontWeight={'bold'}
              fontSize={'38px'}
              color={'gray.700'}></Text>
          </VStack>
        </Center>
        {/* Fin-titulos */}
        {/* Inicio-formulario */}
        <Stack mt={{ base: 5, lg: 10 }}>
          <form onSubmit={() => { }}>

            <VStack w={'100%'} p={3}>
              <RenderIf isTrue={codigo == null}>
                <RenderIf isTrue={next == false}>
                  <Center>
                    <Text align={'center'}
                      color={'black'}
                      fontSize={'15px'}
                    >
                      <Center>
                        {isLoading ? <Spinner color='orange' /> : <></>}
                      </Center>

                      {errores ?
                        <Box color={'red'}>{errores}.</Box>
                        :
                        <Box w={'300px'} fontSize={'15px'} >Ingresa tu correo empresarial</Box>
                      }
                    </Text>
                  </Center>
                  <Center w={'100%'}>
                    <Flex direction={'column'} w={'70%'}>
                      <Center>

                        <Input
                          h={14}
                          onChange={e => setUserName(e.target.value.toLowerCase())}
                          w={'70%'}
                          type="email"
                          placeholder="ejemplo@ejemplo.com"
                          fontSize={'15px'}
                          textAlign={'center'}
                          bg={'white'}

                        />

                      </Center>
                     

                    </Flex>
                  </Center>

                  <Center mt={10}>
                    {userName == null ?
                      <>
                        <Button
                          h={14}
                          variant={'solid'}
                          w="200px"
                          bg={'orange.500'}
                          fontWeight={'medium'}
                          className="font"
                          rounded={'full'}
                          color={'white'}
                          my={5}
                        >
                          INGRESAR</Button>
                      </>
                      :
                      <>
                        {/* {userName == null ? 
                        <Button 
                            h={'60px'} 
                            variant={'outline'} 
                            w="200px" 
                            colorScheme={'red'} 
                            fontWeight={'medium'}
                            className="font"
                            rounded={'full'}
                            >
                        SOLICITAR CÓDIGO</Button> : */}
                        <Button
                          h={14}
                          //type="submit" 
                          variant={'outline'}
                          w="200px"
                          bg={'orange.400'}
                          color={'white'}
                          fontWeight={'medium'}
                          rounded={'full'}
                          className="font"
                          isLoading={cargando}
                          my={'5'}
                          onClick={() => validarDominio()}
                        >
                          INGRESAR
                        </Button>
                        {/* } */}
                      </>

                    }

                  </Center>
                </RenderIf>
              </RenderIf>
            </VStack>
            <VStack w={'100%'} p={3}>
              <RenderIf isTrue={codigo}>

                <Box>
                  <Text
                    align={'left'}
                    w={'100%'}
                    color={'black'}
                    fontSize={'15px'}
                    textAlign={'center'}
                  >

                    {errores ?
                      <Box color={'red'}>{errores}.</Box>
                      :
                      <>Introduce tu código de acceso</>
                    }


                  </Text>
                </Box>
                <Center w={'100%'}>
                  <Input h={14} onChange={e => setUserNameCode(e.target.value)} w={'70%'} type="password"
                    placeholder="Código" textAlign={'center'} bg={'white'} />
                </Center>
                <Center>
                  <Button
                    h={'60px'}
                    //type="submit" 
                    variant={'outline'}
                    w="200px"
                    bg={'orange.400'}
                    color={'white'}
                    fontWeight={'medium'}
                    rounded={'full'}
                    className="font"
                    my={5}
                    onClick={() => session()}
                  >
                    INICIAR SESIÓN
                  </Button>
                </Center>
              </RenderIf>
              <Center>
                <HStack w={'100%'}>

                </HStack>
              </Center>
            </VStack>

          </form>
        </Stack>
        {/* fin-formulario */}
        {/* Inicio Redes Sociales Mobile */}
        <Center>
          <SimpleGrid bottom={14} position={'absolute'} columns={3} spacing={5}>
            <Box visibility={{ base: 'hidden', lg: 'hidden' }}>
              <FaWhatsappSquare fontSize={'20pt'} color={'gray'} />
            </Box>
            <Box visibility={{ base: 'hidden', lg: 'hidden' }}>
              <SiGmail fontSize={'20pt'} color={'gray'} />
            </Box>
            <Box visibility={{ base: 'hidden', lg: 'hidden' }}>
              <FaLinkedin fontSize={'20pt'} color={'gray'} />
            </Box>
          </SimpleGrid>
        </Center>
        {/* Fin Redes Sociales */}
      </Box>
    </Stack>
  )
}


//REDUX
const mapStateProps = (state) => ({
  contacto: state.contactoReducer.contacto,
  isLoading: state.contactoReducer.isLoading,
  error: state.contactoReducer.error,
  codigo: state.codigoReducer.code,
  isLoading: state.codigoReducer.isLoading,
  errorCodigo: state.codigoReducer.error,
  proyectoRData: state.proyectoReducer.proyectoRData
})

const LoginOnAccess = connect(mapStateProps, { get_contacto, get_codigo, formato_email,get_proyecto })(_LoginOnAccess)

export default LoginOnAccess;

