import { combineReducers, legacy_createStore as createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'

const initialState = {
  contacto: {
    id: null,
    nombre: "",
    correo: null
  },
  error: null,
  isLoading: false,
  proyectoRData: {
    status: null,
    tipo: null,
    nombreProyecto: null
  },
  presentacionRData: [],
  propuestaRData: [],
  contratoRData: [],
  contactoAdicional: [],
  codigo: 0,
  Image: null,
  videos:[],
  videoDefault: []
};
export const contactoReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'GET_CONTACTO':
          return {
              ...state,
              contacto: action.payload,
              isLoading: false,
              error: '' // Borra el mensaje de error en caso de que se hayan encontrado datos
          };
      case 'LOAD_CONTACTO_REQUEST':
            return {
              ...state,
              isLoading: true,
              error: null
            };
      case 'GET_CONTACTO_NULO':
              return {
                ...state,
                contacto: [],
                isLoading: false,
                error: action.payload
              };
      case 'GET_CONTACTO_ERROR':
            return {
              ...state,
              contacto: [],
              isLoading: false,
              error: action.payload // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
            };
      default:
          return state;
  }
};
export const proyectoReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'GET_PROYECTO':
          return {
              ...state,
              proyectoRData: action.payload,
              isLoading: false,
              error: '' // Borra el mensaje de error en caso de que se hayan encontrado datos
          };
      case 'LOAD_PROYECTO_REQUEST':
            return {
              ...state,
              isLoading: true,
              error: null
            };
      case 'GET_PROYECTO_ERROR':
            return {
              ...state,
              proyectoRData: [],
              isLoading: false,
              error: 'No encontramos ningun proyecto' // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
            };
      default:
          return state;
  }
};
export const presentacionesReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'GET_PRESENTACIONES':
          return {
              ...state,
              presentacionRData: action.payload,
              isLoading: false,
              error: '' // Borra el mensaje de error en caso de que se hayan encontrado datos
          };
      case 'LOAD_PRESENTACIONES_REQUEST':
            return {
              ...state,
              isLoading: true,
              error: null
            };
      case 'GET_PRESENTACIONES_ERROR':
            return {
              ...state,
              presentacionRData: [],
              isLoading: false,
              error: 'No encontramos ninguna presentación' // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
            };
      default:
          return state;
  }
};
export const propuestasReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'GET_PROPUESTAS':
          return {
              ...state,
              propuestaRData: action.payload,
              isLoading: false,
              error: '' // Borra el mensaje de error en caso de que se hayan encontrado datos
          };
      case 'LOAD_PROPUESTAS_REQUEST':
            return {
              ...state,
              isLoading: true,
              error: null
            };
      case 'GET_PROPUESTAS_ERROR':
            return {
              ...state,
              propuestaRData: [],
              isLoading: false,
              error: 'No encontramos ninguna presentación' // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
            };
      default:
          return state;
  }
};
export const contratosReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'GET_CONTRATOS':
          return {
              ...state,
              contratoRData: action.payload,
              isLoading: false,
              error: '' // Borra el mensaje de error en caso de que se hayan encontrado datos
          };
      case 'LOAD_CONTRATOS_REQUEST':
            return {
              ...state,
              isLoading: true,
              error: null
            };
      case 'GET_CONTRATOS_ERROR':
            return {
              ...state,
              contratoRData: [],
              isLoading: false,
              error: 'No encontramos ninguna presentación' // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
            };
      default:
          return state;
  }
};
export const addContactoReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'SET_CONTACTO_ADICIONAL':
          return {
              ...state,
              contactoAdicional: action.payload,
              isLoading: false,
              error: '' // Borra el mensaje de error en caso de que se hayan encontrado datos
          };
      case 'LOAD_CONTACTO_ADICIONAL_REQUEST':
            return {
              ...state,
              isLoading: true,
              error: null
            };
      case 'SET_CONTACTO_ADICIONAL_ERROR':
            return {
              ...state,
              contactoAdicional: [],
              isLoading: false,
              error: 'Usuario no encontrado, intente nuevamente.' // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
            };
      default:
          return state;
  }
};
export const codigoReducer = (state = initialState, action) => {
  switch (action.type) {
      
    case 'GET_CODE':
        return {
            ...state,
            code: action.payload,
            isLoading: false,
            error: '' // Borra el mensaje de error en caso de que se hayan encontrado datos
        };
    case 'LOAD_CODE_REQUEST':
          return {
            ...state,
            isLoading: true,
            error: null
          };
    case 'GET_CODE_ERROR':
          return {
            ...state,
            isLoading: false,
            error: `El usuario ${action.payload}, no tiene el formato requerido.` // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
          };
    default:
        return state;
  }
};
export const avatarReducer = (state = initialState, action) => {
  switch (action.type) {
      
    case 'GET_AVATAR':
        return {
            ...state,
            Image: action.payload,
            isLoading: false,
            error: '' // Borra el mensaje de error en caso de que se hayan encontrado datos
        };
    case 'LOAD_AVATAR_REQUEST':
          return {
            ...state,
            isLoading: true,
            error: null
          };
    case 'GET_AVATAR_ERROR':
          return {
            ...state,
            isLoading: false,
            error: `El usuario ${action.payload}, no tiene el formato requerido.` // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
          };
    default:
        return state;
  }
};
export const videosReducer = (state = initialState, action) => {
  switch (action.type) {
      
    case 'GET_VIDEOS':
        return {
            ...state,
            videos: action.payload,
            isLoading: false,
            error: '' // Borra el mensaje de error en caso de que se hayan encontrado datos
        };
    case 'GET_VIDEO':
          return {
              ...state,
              videoDefault: action.payload,
              isLoading: false,
              error: '' // Borra el mensaje de error en caso de que se hayan encontrado datos
          };
    case 'LOAD_VIDEOS_REQUEST':
          return {
            ...state,
            isLoading: true,
            error: null
          };
    case 'GET_VIDEOS_ERROR':
          return {
            ...state,
            isLoading: false,
            error: `El usuario ${action.payload}, no tiene el formato requerido.` // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
          };
    default:
        return state;
  }
};
//root reducer
export const rootReducer = combineReducers({
  contactoReducer,
  proyectoReducer,
  presentacionesReducer,
  propuestasReducer,
  addContactoReducer,
  codigoReducer,
  avatarReducer,
  contratosReducer,
  videosReducer
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//store
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));