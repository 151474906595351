import React, { useContext, useEffect, useState } from 'react';
import {Spinner, 
    Input, VStack, Toast, SimpleGrid, LinkOverlay, HStack, Image, Stack, useToast, Button,
    Text, Heading, Container, Box, Center,  Flex, Spacer, Link, Table, Tag,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Avatar,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Tooltip, 
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  LinkBox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Textarea,
  TagLabel
} from "@chakra-ui/react"

import Header from '../Header';
import { ChevronDownIcon, PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import useUser from '../../context/useUser';
import useToken from '../useToken';
import { useQuery, useMutation, useQueryClient} from 'react-query'
import axios from 'axios';
import MenuCliente from '../Cliente/Utilidades/MenuCliente';
import userEvent from '@testing-library/user-event';
import {useParams} from "react-router-dom";
import {BsCalendar2DateFill, BsPinMapFill, BsPeopleFill, BsDot} from 'react-icons/bs'
import {MdEmail, MdAttachFile} from 'react-icons/md'
import {HiDocumentText, HiDotsHorizontal} from 'react-icons/hi'
import {GoPrimitiveDot} from 'react-icons/go'

import Moment from 'react-moment';
import 'moment-timezone';


import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses  } from '@mui/base/TabUnstyled';
import { motion, AnimatePresence } from "framer-motion"
import * as AWS from 'aws-sdk'
import {BiSlideshow} from 'react-icons/bi'
import {IoMdHelpCircle, IoMdSend} from 'react-icons/io'
import {GiOrganigram} from 'react-icons/gi'
import {BsTelephoneFill} from 'react-icons/bs'
import {FaWhatsappSquare} from 'react-icons/fa'
import { nanoid } from 'nanoid';
import { now } from 'moment';
import HomePic from '../../assets/home.svg'
import ErrorPic from '../../assets/error.svg'

//AWS.config.update({region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId:'AKIAWLUS3YRU4LTKLQHG'});
AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIARVGY33F62XNKJJK3' });
const Error = () => {
    let { id, token } = useParams();
    const [cliente, setCliente] = useState();
    const queryClient = useQueryClient();
    const [datos, setDatos] = useState([])
    const {
        isOpen: isOrganigramaModalOpen,
        onOpen: onOpenOrganigramaModal,
        onClose: onCloseOrganigramaModal,
      } = useDisclosure();
    const [proyecto, setProyecto] = useState([])
    const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI)
    //const { token, setToken } = useToken();
    const [clientes, setClientes] = useState([])
    const [vendedor, setVendedor] = useState([])
    const { user, setUser } = useUser()
    const [urlCassandra, setUrlCassandra] = useState(process.env.REACT_APP_URL_CASSANDRA)
    const [tokenCasandra, setTokenCassandra] = useState(process.env.REACT_APP_TOKEN_CASSANDRA)
    const docClient = new AWS.DynamoDB.DocumentClient({apiVersion: '2022-01-01'})
    const [datos_c, setDatos_c] = useState([])
    const [datos_d, setDatos_d] = useState([])
    const [datos_e, setDatos_e] = useState([])
    const [datos_f, setDatos_f] = useState([])
    const [mensaje, setMensaje] = useState()
    const [notificacion, setNotificacion] = useState()
    const [to, setTo] = useState()

    function getQueryParams(qs) {
        qs = qs.split('+').join(' ');
    
        var params = {},
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;
    
        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }
    
        return params;
    }


    async function fetchProyectos() {
      
        const query = getQueryParams(document.location.search);
        //console.log("parametro:",  query.token.substring(query.token.indexOf('_') + 1));
        //hJsb8vmBsBhQ9bPq3KLIy
        //const urlC = `${urlCassandra}collections/proyectos?where={"token":{"$eq":"${query.token.split('_')[0]}"}}&page-size=20`
        
        const proyecto = query.token.substring(query.token.indexOf('*') + 1).replace("-", "")
        console.log("proyecto:", proyecto);
        const urlC = `${urlCassandra}collections/proyectos${proyecto}?page-size=20`
        const ventasData = await axios.get(urlC, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Cassandra-Token': tokenCasandra
            }
        })
        const proyectos =  await Object.keys(ventasData.data.data).map((item) => ({id: item, ...ventasData.data.data[item]}))
        return proyectos

   
}


    const { data: project} = useQuery('PROYECTOS', fetchProyectos, {
        onSuccess: (data) => {
            setProyecto(data[0])
            if(data[0].estatus == "Compartido"){
                const query = getQueryParams(document.location.search);
                window.location = `/public?token=${query.token}`
            }
        }
    })
    return (

        <Center>
            <AnimatePresence initial={false}>
            <motion.div  whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
                    >
            <Flex direction={'column'} justify={'center'}>
                    <Center alignSelf={'center'} my={10}>
                    
                    <Image src={ErrorPic} w={'90%'} />
                   
                    </Center>
                    <Flex direction={'column'}>
                    <Box alignSelf={'center'} fontSize={{lg:'50pt', base:'40pt', '2xl': '50pt'}} color={'gray.700'} fontWeight={'bold'} fontFamily={'system-ui, sans-serif'}  mt={{lg:'8', base:'5'}}>Ups</Box>
                        <Box alignSelf={'center'} fontSize={{lg:'20pt', base:'15pt', '2xl': '20pt'}} color={'gray.700'} fontWeight={'normal'} fontFamily={'system-ui, sans-serif'}  mt={{lg:'8', base:'5'}}>No tenemos proyectos disponibles en este momento. </Box>
                    </Flex>
                    
            
            </Flex>
            </motion.div>
            </AnimatePresence>
        </Center>
    
    );
};

export default Error;