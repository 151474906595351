import React, { useEffect, useState, useRef } from 'react';
import { Fab, Action } from 'react-tiny-fab';
import { IoMdAdd, IoMdHelpCircle, IoMdSend, IoMdShareAlt, IoMdHome } from 'react-icons/io'
import { mainButtonStyles, actionButtonStyles, style } from 'react-tiny-fab/dist/styles.css';
import { GiOrganigram } from 'react-icons/gi'
import {
    Button,
    Box,
    Flex,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Center,
    Image,
    Tag,
    TagLabel,
    useToast,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Avatar, AvatarBadge, AvatarGroup,
    CloseButton,
    List,
    ListItem,
    ListIcon
} from '@chakra-ui/react'
import * as AWS from 'aws-sdk'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { nanoid } from 'nanoid';
import { useParams } from "react-router-dom";
import { RiChatSmile3Fill, RiCloseFill } from 'react-icons/ri'
import { RenderIf } from '../../hooks/renderIf';
import Moment from 'react-moment';
import 'moment-timezone';
import { connect } from 'react-redux';
import { add_contactos } from '../../actions/index'

const gray = {
    50: '#EDF2F7',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923'
};
const orange = {
    50: '#FFFAF0',
    100: '#FEEBC8',
    200: '#FBD38D',
    300: '#F6AD55',
    400: '#ED8936',
    500: '#DD6B20',
    600: '#C05621',
    700: '#9C4221',
    800: '#7B341E',
    900: '#652B19'
};


//AWS.config.update({region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId:'AKIAWLUS3YRU4LTKLQHG'}); //Desarrollo
AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIARVGY33F62XNKJJK3' }); //Produccion NCTECH
const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: '2022-01-01' })

const _Buttons = (props) => {
    const MessageRef = useRef(null)
    let { id, token, proyectoParametro, nombre, proyecto, proyectoId, vendedor, meid } = useParams();

    const form = useRef();
    const queryClient = useQueryClient();
    const [datos_d, setDatos_d] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure()
    const { contacto, error, errorCodigo, codigo } = props || {};
    const {
        isOpen: isOpenChat,
        onOpen: onOpenChat,
        onClose: onCloseChat,
    } = useDisclosure();

    const [value, setValue] = useState()
    const [valueNombre, setValueNombre] = useState()
    const [color, setColor] = useState('blue.50')
    const [colorBoton, setColorBoton] = useState('blue')
    const [cargando, setCargando] = useState(false)
    const [changeMessage, onChangeMessage] = useState("")
    const [chat, setChat] = useState("")
    const toast = useToast()
    const [fuente] = useState("ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji")
    function getQueryParams(qs) {
        qs = qs.split('+').join(' ');

        var params = {},
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;

        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }

        return params;
    }
    const scrollToBottom = () => {
        MessageRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    const fetchContactos = () => {
        //console.log("proyecto1:", proyecto)
        var params = {
            TableName: 'Contactos',
            //  FilterExpression: "#nom != null",
            //  ExpressionAttributeNames: {
            //      "#nom": "nombre",
            //  },
            //  ExpressionAttributeValues: {
            //      ":name": id
            //  }
        }
        docClient.scan(params, function (err, data) {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                console.log("Query succeeded.");
                setDatos_d(data.Items)
                // data.Items.forEach(function(item) {
                //console.log(data.Items);

                // });
            }
        });
    }
    function avisoError(data) {
        toast({
            title: 'El campo correo no puede estar vacio, verifica y vuelve a intentar',
            description: "",
            status: 'error',
            duration: 3000,
            isClosable: true,
        })
    }
    function aviso(data) {
        toast({
            title: 'Proyecto compartido con exito',
            description: "",
            status: 'success',
            duration: 3000,
            isClosable: true,
        })
    }
    const compartir = async () => {



        if (!value && !valueNombre) {
            setCargando(true)
            avisoError()
            setColor('red.100')
            setColorBoton('red')
            setTimeout(() => {
                setColor('blue.50')
                setColorBoton('blue')
                setValue("")
                setValueNombre("")
                setCargando(false)
            }, 3000)

        }
        else {
            setCargando(true)
            const query = getQueryParams(document.location.search);
            //const urlPublic = `https://portalcl.nctech.com.mx/public?token=${query.token}&proyecto=${query.proyecto}`
            //const urlPublic = `https://portalcl.nctech.com.mx/public?token=1234&proyecto=123`
            const urlPublic = `https://portalcl.nctech.com.mx/oneaccess/${proyectoId}/${proyecto}/${vendedor}`
            const urlC = `https://servicesemail-production.herokuapp.com/api/mail`
            const body = {
                templateId: 'd-11627cc3f1cf4486b154451aac923497',
                to: form.current[0].value.toString(),
                url: urlPublic,
                subject: "¡Tenemos un proyecto que compartir contigo!",
                comentarios: "Te han compartido un proyecto de NC Tech, da clic en el boton para visualizarlo.",
                cliente: valueNombre,
                vendedor: nombre
            }
            const response = await fetch(urlC, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'

                },
                body: JSON.stringify(body)
            })

            if (response.status == 201) {

                setColor('green.100')
                setColorBoton('green')
                aviso()
                setCargando(false)
                setTimeout(() => {
                    setColor('blue.50')
                    setColorBoton('blue')
                    actualizaPresentacionInicio()
                    props.add_contactos(valueNombre, value, proyectoId)
                    onShareClose()
                    setValue("")
                    setValueNombre("")
                }, 3000)

            }
        }
    }
    const putData_b = (tableName, data) => {
        var params = {
            TableName: tableName,
            Item: data
        }

        docClient.put(params, function (err, data) {
            if (err) {
                console.log('Error', err)
            } else {
                console.log('Success', data)
                //refetch_c()


            }
        })
    }
    const updatePresentacionInicio = async (data) => {


        //   const query = getQueryParams(document.location.search);
        //   console.log("Query:", form.current[0].value.toString())
        //   console.log("SHARE---:", query.proyecto.toString())
        //   const proyecto = query.token.substring(query.token.indexOf(',') + 1)

        const presentacionData = {
            id: nanoid(),
            proyectoId: proyecto,//query.proyecto.toString(),
            share: true,
            fechaShare: Date.now(),
            tipo: "Proyecto Compartido",
            destinatario: form.current[0].value.toString(),
            compartidoA: valueNombre,
            compartidoDe: nombre
        }

        await putData_b('Bitacora', presentacionData)



    }
    function actualizaPresentacionInicio() {
        mutation_c.mutate()
    }
    const mutation_c = useMutation(updatePresentacionInicio, {
        onSuccess: () => {
            console.log("Notificacion enviada correctamente")
            //queryClient.invalidateQueries('NOTIFICACIONES')
            //setDatos_e(data)

        },
        onError: (error) => {
            console.log(error)
        }
    })
    const handlerValor = (e) => {
        setValue(e.target.value)
    }
    const handlerNombreValor = (e) => {
        setValueNombre(e.target.value)
    }

    const Chat = async () => {
        const urlC = `http://localhost:8080/api/setchat`
        const response = await fetch(urlC, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                cliente: id,
                message: changeMessage,
            })

        })
        //refetchChat()
        onChangeMessage("")
        return response
    }

    const getChat = async () => {

        const chat = await fetch('https://portalnc.nctech.com.mx/api/getchat')
            .then(response => response.json())
        console.log(chat)
        scrollToBottom()
        onChangeMessage(null)
        return chat

    }
    const { isLoading, data_c, refetch: refetch_a } = useQuery('CONTACTOS', fetchContactos)
    // const { refetch: refetchChat } = useQuery("CHATS", getChat,  {
    //     enabled: true, // turned off by default, manual refetch is needed
    //     onSuccess: (data) => {
    //         const result = data.map((x) => {
    //             const ob = x.message.split("*")
    //             const nombre = ob[0]
    //             const mensaje = ob[1]
    //             const fecha = ob[2]

    //             return {nombre, mensaje, fecha }

    //         })
    //         console.log(result)
    //         setChat(result)

    //     }

    // })

    const handlerClickChat = () => {
        onOpenChat()
        setTimeout(() => {
            scrollToBottom()
        }, 2000)

    }
    const handlerClickCloseChat = () => {
        scrollToBottom()
        onCloseChat()

    }
    useEffect(() => {
        scrollToBottom()

    }, [])
    const abrir = () => {
        console.log("Hello")
        onShareOpen()
    }
    const home = () => {
        //console.log(meid)
        // /me/:meid/:proyectoId/:nombre/:proyecto/:vendedorId
        window.location = "/me/" + meid + "/" + proyectoId + "/" + nombre + "/" + proyecto + "/" + vendedor
    }

    const svgString = `
    <svg viewBox="-3.2 -3.2 38.40 38.40" version="1.1" xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    xmlnsSketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(10.719999999999999,10.719999999999999), scale(0.33)"><rect x="-3.2" y="-3.2" width="38.40" height="38.40" rx="19.2" fill="#ebaf47" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#ff0a0a" stroke-width="0.44800000000000006"></g><g id="SVGRepo_iconCarrier"> <title>plus-circle</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"> <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-466.000000, -1089.000000)" fill="#a1a1a1"> <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" sketch:type="MSShapeGroup"> </path> </g> </g> </g></svg>

  `;
    return (
        <Box zIndex={props.zIndex}>
            <Drawer
                isOpen={isOpenChat}
                placement='left'
                onClose={handlerClickCloseChat}
                zIndex={'9999'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Chatea con tu vendedor</DrawerHeader>
                    <Box ml={6}>
                        <Box fontFamily={fuente} fontSize={'13px'} py={2}>Estos son los contactos de este cliente</Box>
                        <AvatarGroup size='md' max={3}>
                            <Avatar name='Ryan Florence' src='https://bit.ly/ryan-florence' />
                            <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' />
                            <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds' />
                            <Avatar name='Prosper Otemuyiwa' src='https://bit.ly/prosper-baba' />
                            <Avatar name='Christian Nwamba' src='https://bit.ly/code-beast' />
                        </AvatarGroup>
                    </Box>
                    <DrawerBody>
                        <List spacing={3}>
                            <>
                                {chat ?
                                    <>
                                        {chat.map((proyecto, index) => {
                                            const url = `/vendedor/proyectos/${id}/${proyecto.id}`
                                            return (
                                                <ListItem key={index}>

                                                    <RenderIf isTrue={id == proyecto.nombre}>
                                                        <Box
                                                            w={'30px'}
                                                            h={'30px'}
                                                            mr={2}
                                                            textAlign={'center'}
                                                            p={1}
                                                            rounded={'full'}
                                                            bgGradient='linear(to-r, #fae1dd,#f8edeb )'
                                                            fontFamily={fuente}
                                                            fontSize={'15px'}
                                                            fontWeight={'bold'}
                                                            color={'gray.700'}>Tu</Box>
                                                        <Box w={'auto'}
                                                            py={3}
                                                            px={'4'}
                                                            fontWeight={'semibold'}
                                                            ref={MessageRef}
                                                            fontFamily={fuente}
                                                            fontSize={'15px'}
                                                            bgGradient='linear(to-l, #e8e8e4,#d8e2dc )'
                                                            rounded={'full'}
                                                            whiteSpace={'break-spaces'}
                                                        >
                                                            <Flex>

                                                                <Box textAlign={'end'}
                                                                    fontFamily={fuente}
                                                                    fontWeight={'medium'}
                                                                    fontSize={'14px'}
                                                                    _hover={{
                                                                        textDecoration: 'underline',
                                                                        textUnderlineOffset: '0.2rem',
                                                                        textDecorationStyle: 'wavy'
                                                                    }}>{proyecto.mensaje}</Box>
                                                            </Flex>
                                                            <Box fontFamily={fuente} textAlign={'right'} fontSize={'12px'} color={'gray.500'}> hace <Moment fromNow ago tz="America/Mexico_City" local='es'>{proyecto.fecha}</Moment></Box>
                                                        </Box>
                                                    </RenderIf>
                                                    <RenderIf isTrue={id !== proyecto.nombre}>
                                                        <Box textAlign={'start'}
                                                            p={1}
                                                            pl={3}
                                                            mr={'2'}
                                                            rounded={'full'}
                                                            w={'auto'}
                                                            bg={'white'}
                                                            fontFamily={fuente}
                                                            fontSize={'13px'}
                                                            fontWeight={'normal'}
                                                            fontStyle={'italic'}
                                                            color={'gray.500'}>
                                                            respuesta
                                                        </Box>
                                                        <Box w={'auto'}
                                                            py={3}
                                                            px={'4'}
                                                            fontWeight={'semibold'}
                                                            ref={MessageRef}
                                                            fontFamily={fuente}
                                                            fontSize={'15px'}
                                                            bgGradient='linear(to-r, #ffd7ba,#e8e8e4 )'
                                                            rounded={'full'}
                                                            whiteSpace={'break-spaces'}
                                                            textAlign={'right'}
                                                        >
                                                            <Flex>

                                                                <Box
                                                                    textAlign={'end'}
                                                                    fontFamily={fuente}
                                                                    fontWeight={'medium'}
                                                                    fontSize={'14px'}
                                                                    _hover={{
                                                                        textDecoration: 'underline',
                                                                        textUnderlineOffset: '0.2rem',
                                                                        textDecorationStyle: 'wavy'
                                                                    }}>
                                                                    {proyecto.mensaje}
                                                                </Box>
                                                            </Flex>
                                                            <Box fontFamily={fuente} textAlign={'right'} fontSize={'12px'} color={'gray.500'}> hace <Moment fromNow ago tz="America/Mexico_City" local='es'>{proyecto.fecha}</Moment></Box>
                                                        </Box>
                                                    </RenderIf>

                                                </ListItem>
                                            )
                                        })}
                                    </>
                                    :
                                    <Box fontSize={15} px={5} py={5} color={'gray.500'} fontWeight={'medium'} fontFamily={fuente}>
                                        No hay mensajes que mostrar
                                    </Box>
                                }
                            </>
                        </List>
                    </DrawerBody>
                    <DrawerFooter>
                        <Flex direction={'column'} w={'full'}>
                            <Input placeholder='Escribe algo aquí!...' rounded={'full'} my={3} onChange={(e) => onChangeMessage(e.target.value)} value={changeMessage || ''} />
                            <Flex direction={'row'} justify={'center'} w={'full'}>
                                <Button shadow={'sm'} bgGradient='linear(to-r, #fae1dd,#d8e2dc )' rounded={'full'} w={'full'} onClick={() => Chat()}>
                                    <IoMdSend mr={2} /> Enviar
                                </Button>
                            </Flex>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

            <Modal size={'lg'} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader fontFamily={fuente} fontSize={'15pt'} fontWeight={'medium'}></ModalHeader>

                    <ModalBody pb={6}  >

                        <Center >
                            <Box zIndex={1} py={3} w='100%'>
                                <Box
                                    p='20px'
                                    color='gray.500'
                                    mt='4'
                                    bg='red.50'
                                    rounded='15'
                                    shadow={'sm'}
                                    blur='10'
                                    mb='4'
                                    h={'90vh'}
                                >
                                    <ModalCloseButton position={'fixed'} zIndex={99999} />
                                    <Center>
                                        <Flex direction={'column'}>
                                            <Box mt={5} blur={'8px'} fontSize={'25pt'} color={'gray.800'} fontWeight={'bold'} fontFamily={fuente} >Podemos ayudarte?</Box>
                                            <Box fontSize={'sm'} color={'gray.700'} fontWeight={'normal'} fontFamily={fuente} mb={5}>Nuestro equipo le ayudará en cualquier situación.</Box>
                                        </Flex>
                                    </Center>
                                    <Flex wrap={'wrap'} justify={'center'} >
                                        {datos_d
                                            .map((p, i) => {
                                                return (

                                                    <Box key={i} bg={'red'} rounded={15} mx={5} w={'350px'} alignSelf={'center'} h={'13vh'} my={{ base: 5, lg: 2 }}>
                                                        <Flex mb={2} cursor={'pointer'} bg={'white'} overflow={'hidden'} rounded={15} >
                                                            <Box alignSelf={'center'} mr={4}>
                                                                {/* <Avatar src={p.photo} name={p.nombre} size={'md'} /> */}
                                                                <Image src={p.photo} boxSize={'13vh'} objectFit='cover' />
                                                            </Box>
                                                            <Flex direction={'column'} alignSelf={'center'}>
                                                                <Box fontSize={'11pt'} color={'gray.800'} fontWeight={'bold'} fontFamily={fuente}>
                                                                    {p.nombre}
                                                                </Box>
                                                                <Flex direction={'column'}>
                                                                    <Box fontSize={'9pt'} color={'gray.700'} fontWeight={'light'} fontFamily={fuente}>
                                                                        {p.puesto}
                                                                    </Box>
                                                                    <Tag size={'sm'} w={'65px'} mt={1}>
                                                                        <TagLabel fontSize={'9pt'} fontWeight={'light'} fontFamily={fuente}>NC Tech</TagLabel>
                                                                    </Tag>
                                                                </Flex>

                                                            </Flex>
                                                        </Flex>
                                                    </Box>
                                                )

                                            })}
                                    </Flex>
                                </Box>

                            </Box>
                        </Center>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal zIndex={999999} size={'lg'} closeOnOverlayClick={false} isOpen={isShareOpen} onClose={onShareClose} isCentered>
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader fontFamily={fuente} fontSize={'15pt'} fontWeight={'medium'}></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}  >
                        <Center>
                            <Box zIndex={1} py={3} w='100%'>


                                <Box
                                    p='20px'
                                    color='gray.500'
                                    mt='4'
                                    bg={'#BFEDF6'}
                                    rounded='15'
                                    shadow={'sm'}
                                    blur='10'
                                >
                                    <Center>
                                        <Flex direction={'column'}>
                                            <Box mt={5} blur={'8px'} fontSize={'25pt'} color={'gray.800'} fontWeight={'bold'} fontFamily={fuente} >¡Compártelo! con alguien más</Box>
                                            <Box fontSize={'sm'} color={'gray.700'} fontWeight={'normal'} fontFamily={fuente}
                                                mb={5}>Comparte el contenido del proyecto escribiendo su nombre y su cuenta de correo electrónico</Box>
                                            <Box>
                                                <Box fontSize={'sm'} color={'gray.700'} fontWeight={'normal'} fontFamily={fuente} >Nombre completo del destinatario</Box>
                                                <Input rounded={15} mb={5} type={'text'}
                                                    placeholder={'Eje. Juan José López'}
                                                    bg={'white'}
                                                    fontSize={'sm'}
                                                    color={'gray.800'}
                                                    fontWeight={'normal'}
                                                    fontFamily={fuente}
                                                    value={valueNombre}
                                                    onChange={(e) => handlerNombreValor(e)} />
                                            </Box>
                                            <form ref={form}>
                                                <Flex direction={'column'}>

                                                    <Box>
                                                        <Box fontSize={'sm'} color={'gray.700'} fontWeight={'normal'} fontFamily={fuente} >Correo electrónico del destinatario</Box>
                                                        <Input rounded={15} mb={5} type={'email'} placeholder={'Eje. example@example.com'} bg={'white'} fontSize={'sm'} color={'gray.800'} fontWeight={'normal'} fontFamily={fuente} value={value} onChange={(e) => handlerValor(e)} />
                                                        <Button isLoading={cargando} onClick={() => compartir()} variant={'outline'} mx={1} colorScheme={colorBoton}><IoMdShareAlt size={'25px'} /></Button>
                                                    </Box>

                                                </Flex>
                                            </form>
                                        </Flex>
                                    </Center>
                                </Box>


                            </Box>
                        </Center>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Fab
                mainButtonStyles={{ backgroundColor: '#fff' }}

                style={style}
                icon={
                    <svg width={'28px'} height='28px' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="24" height="24" fill="white"></rect> <path fill-rule="evenodd" clip-rule="evenodd" d="M13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9V11H9C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H11V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H13V9ZM7.25007 2.38782C8.54878 2.0992 10.1243 2 12 2C13.8757 2 15.4512 2.0992 16.7499 2.38782C18.06 2.67897 19.1488 3.176 19.9864 4.01358C20.824 4.85116 21.321 5.94002 21.6122 7.25007C21.9008 8.54878 22 10.1243 22 12C22 13.8757 21.9008 15.4512 21.6122 16.7499C21.321 18.06 20.824 19.1488 19.9864 19.9864C19.1488 20.824 18.06 21.321 16.7499 21.6122C15.4512 21.9008 13.8757 22 12 22C10.1243 22 8.54878 21.9008 7.25007 21.6122C5.94002 21.321 4.85116 20.824 4.01358 19.9864C3.176 19.1488 2.67897 18.06 2.38782 16.7499C2.0992 15.4512 2 13.8757 2 12C2 10.1243 2.0992 8.54878 2.38782 7.25007C2.67897 5.94002 3.176 4.85116 4.01358 4.01358C4.85116 3.176 5.94002 2.67897 7.25007 2.38782Z" fill="#FF6D00"></path> </g></svg>
                }>
                {/* <Action
                  text="Chat"
                  onClick={handlerClickChat}
                  disabled={true}
                >
                  <RiChatSmile3Fill  />
                </Action> */}
                {/* <Action
                    text="Organigrama"
                    onClick={onOpen}
                >
                    <GiOrganigram />
                </Action> */}

                <Action
                    text="Compartir"
                    onClick={() => abrir()}
                    style={{ backgroundColor: '#fff' }}
                //onClick={onShareOpen}
                >

                    <svg width={'28px'} height='28px' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z" fill="#FF6D00"></path> </g></svg>
                </Action>
                <Action
                    text="Inicio"
                    onClick={() => home()}
                    style={{ backgroundColor: '#fff' }}
                //onClick={onShareOpen}
                >
                    <svg width={'26px'} height='26px' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5192 7.82274C2 8.77128 2 9.91549 2 12.2039V13.725C2 17.6258 2 19.5763 3.17157 20.7881C4.34315 22 6.22876 22 10 22H14C17.7712 22 19.6569 22 20.8284 20.7881C22 19.5763 22 17.6258 22 13.725V12.2039C22 9.91549 22 8.77128 21.4808 7.82274C20.9616 6.87421 20.0131 6.28551 18.116 5.10812L16.116 3.86687C14.1106 2.62229 13.1079 2 12 2C10.8921 2 9.88939 2.62229 7.88403 3.86687L5.88403 5.10813C3.98695 6.28551 3.0384 6.87421 2.5192 7.82274ZM11.25 18C11.25 18.4142 11.5858 18.75 12 18.75C12.4142 18.75 12.75 18.4142 12.75 18V15C12.75 14.5858 12.4142 14.25 12 14.25C11.5858 14.25 11.25 14.5858 11.25 15V18Z" fill="#FF6D00"></path> </g></svg>
                </Action>
            </Fab>
        </Box>
    );
};

const mapStateProps = (state) => ({
    contacto: state.addContactoReducer.contacto,
    isLoading: state.addContactoReducer.isLoading,
    error: state.addContactoReducer.error,

})

const Buttons = connect(mapStateProps, { add_contactos })(_Buttons)

export default Buttons;