import { Box } from '@chakra-ui/layout';
import React from 'react';
import HomeComponent from '../components/Cliente/Home';
const Home = () => {
    return (
        <Box bg={'white'} h={'100vh'}>
            <HomeComponent />
        </Box>
    );
};
export default Home;