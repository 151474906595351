import React, { useContext, useEffect, useState } from 'react';
import {Spinner, 
    Input, VStack, Toast, SimpleGrid, LinkOverlay, HStack, Image, Stack, useToast, Button,
    Text, Heading, Container, Box, Center, Flex, Spacer, Link,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    Avatar,
    Divider,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    LinkBox, Tag
} from "@chakra-ui/react"

import Header from '../Header';
import { ChevronDownIcon, PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import useUser from '../../context/useUser';
import useToken from '../useToken';
import { useQuery, useMutation, useQueryClient} from 'react-query'
import axios from 'axios';
import MenuCliente from '../Cliente/Utilidades/MenuCliente';
import userEvent from '@testing-library/user-event';
import {useParams} from "react-router-dom";
import {BsCalendar2DateFill, BsPinMapFill, BsPeopleFill, BsDot} from 'react-icons/bs'
import {MdEmail, MdAttachFile} from 'react-icons/md'
import {HiDocumentText, HiDotsHorizontal} from 'react-icons/hi'
import {GoPrimitiveDot} from 'react-icons/go'

import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { motion, AnimatePresence } from "framer-motion"
import * as AWS from 'aws-sdk'
import {BiSlideshow} from 'react-icons/bi'
import {IoMdHelpCircle} from 'react-icons/io'
import {GiOrganigram} from 'react-icons/gi'
//AWS.config.update({region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId:'AKIAWLUS3YRU4LTKLQHG'});
AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIARVGY33F62XNKJJK3' });
const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
  };

  const gray = {
    50: '#EDF2F7',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923'
  };
  const orange = {
    50: '#FFFAF0',
    100: '#FEEBC8',
    200: '#FBD38D',
    300: '#F6AD55',
    400: '#ED8936',
    500: '#DD6B20',
    600: '#C05621',
    700: '#9C4221',
    800: '#7B341E',
    900: '#652B19'
  };
  
  const Tab = styled(TabUnstyled)`
    font-family: system-ui, sans-serif;
    color: white;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    padding: 12px 16px;
    margin: 6px 6px;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
  
    &:hover {
      background-color: ${gray[400]};
    }
  
    &:focus {
      color: #fff;
      border-radius: 3px;
      outline: 2px solid ${orange[200]};
      outline-offset: 2px;
    }
  
    &.${tabUnstyledClasses.selected} {
      background-color: ${gray[50]};
      color: ${orange[600]};
    }
  
    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;
  
  const TabPanel = styled(TabPanelUnstyled)`
    width: 100%;
    font-family: system-ui, sans-serif;
    font-size: 0.875rem;
  `;
  
  const TabsList = styled(TabsListUnstyled)`
    min-width: 320px;
    background-color: ${gray[500]};
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
  `;

const Proyectos = () => {
    let { id, token } = useParams();
    const [cliente, setCliente] = useState();
    const queryClient = useQueryClient();
    const [datos, setDatos] = useState([])
    const [proyecto, setProyecto] = useState([])
    const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI)
    //const { token, setToken } = useToken();
    const [clientes, setClientes] = useState([])
    const [vendedor, setVendedor] = useState([])
    const { user, setUser } = useUser()
    const [urlCassandra, setUrlCassandra] = useState(process.env.REACT_APP_URL_CASSANDRA)
    const [tokenCasandra, setTokenCassandra] = useState(process.env.REACT_APP_TOKEN_CASSANDRA)
    const docClient = new AWS.DynamoDB.DocumentClient({apiVersion: '2022-01-01'})
    const [datos_c, setDatos_c] = useState([])
    function getQueryParams(qs) {
        qs = qs.split('+').join(' ');
    
        var params = {},
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;
    
        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }
    
        return params;
    }
    async function fetchClientes() {
        const query = getQueryParams(document.location.search);
        //console.log("token",query.token.split('_')[0]);
        
        //console.log("Vendedor:", query.token.substring(query.token.indexOf('_') + 1));
        const clientes = fetch(urlStrapi + '/clientes?token=' + query.token.split('_')[0])
        .then(response => response.json())
       // console.log("Clientes:", clientes);
        return clientes
    }


    async function fetchVendedor() {
        const query = getQueryParams(document.location.search);
        const vendedor = query.token.substring(query.token.indexOf('_') + 1)
        const ven = vendedor.split('-')[0]
        //console.log("vendedor a :", ven);
        const clientes = fetch(urlStrapi + '/users/' + ven)
        .then(response => response.json())
        //console.log(clientes);
        return clientes
    }

    async function fetchProyectos() {
      
            const query = getQueryParams(document.location.search);
            //console.log("parametro:",  query.token.substring(query.token.indexOf('_') + 1));
            //hJsb8vmBsBhQ9bPq3KLIy
            //const urlC = `${urlCassandra}collections/proyectos?where={"token":{"$eq":"${query.token.split('_')[0]}"}}&page-size=20`
            const proyecto = query.token.substring(query.token.indexOf('_') + 1).replace("-", "")
            //console.log("proyecto:", proyecto);
            const urlC = `${urlCassandra}collections/proyectos${proyecto}?page-size=20`
            const ventasData = await axios.get(urlC, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Cassandra-Token': tokenCasandra
                }
            })
            const proyectos =  await Object.keys(ventasData.data.data).map((item) => ({id: item, ...ventasData.data.data[item]}))
            return proyectos

       
    }
    useEffect(() => {
        
        //setCliente(id);
        console.log(token)
    } , [])

    const fetchData = () => {
        const query = getQueryParams(document.location.search);
        //console.log("fetchDayaID:", id)
        const proyecto = query.token.substring(query.token.indexOf('-') + 1)
        //console.log("proyecto1:", proyecto)
        var params = {
            TableName: 'Presentaciones',
              FilterExpression: "#nom = :name and #estado = :estado",
             ExpressionAttributeNames: {
                 "#nom": "cliente",
                 "#estado": "estatus"
             },
             ExpressionAttributeValues: {
                 ":name": proyecto,
                 ":estado": "Visible"
             }
        }
        docClient.scan(params, function(err, data) {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                console.log("Query succeeded.");
                setDatos_c(data.Items)
                // data.Items.forEach(function(item) {
                //     console.log(item);
                    
                // });
            }
        });
    }
    const {isLoading: isLoading_a, data_c, refetch: refetch_a} = useQuery('PRESENTACIONES', fetchData,{
      
       
    })


    const {data: vendedores} = useQuery('VENDEDORES', fetchVendedor, {
        onSuccess: (data) => {
            //console.log("Datos-Vendedores:", data)
            setVendedor(data)
        },
    })

    const { isLoading, isFetching, data, refetch  } = useQuery('CLIENTES', fetchClientes, {
        onSuccess: (data) => {
            //console.log("data-clientes:", data[0].nombre)
            setDatos(data)
        },
        cacheTime: Infinity
    })
    const { data: project} = useQuery('PROYECTOS', fetchProyectos, {
        onSuccess: (data) => {
            setProyecto(data[0])
        }
    })
    
    if (isLoading) {
        return <Center mt={'10%'}><Spinner color={'green.700'} /></Center>
    }


    const addCliente = () => {
        window.location.href = '/vendedor/clientes/new'
    }

    
 
    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
           // fetchCliente(event.target.value)
        }
      }
    
 //console.log("DATAC:", data_c)
    return (
        <>
        <Flex direction={{base:'column', lg:'row'}}  h={'100vh'}>
            <MenuCliente />
            <Box  w={{base:'100%', lg: '95%'}} h={{base: '100vh', lg:'100vh'}} ml={{lg:'5%', base:0}}>
                <Flex direction={'column'}>
                    <Box display={{base:'none', lg:'flex'}} bg={'gray.900'} h={{base:'0', lg:'10vh'}} w={{base:0, lg:'100%'}} position={'fixed'} zIndex={'9999'}><Header/></Box>
                    
                    <Box  h={{lg:'90vh', base:'90vh'}} mt={'10vh'}>
                        <Flex direction={'column'}>
                            <Box bg={'white'} h={{base: '25vh', lg:'35vh'}} >
                                <Flex direction={{base:'column', lg:'row'}} >
                                    <Box display={{base:'none', lg:'flex'}} bg={'white'} w={{base:'0', lg:'20%'}} h={{base: '0', lg:'35vh'}}></Box> {/* Box 1 */}
                                    <Box w={{base:'100%', lg:'60%'}} h={{base: '35vh', lg:'35vh'}} >
                                            <Flex direction={'column'}>
                                                <Box >
                                                <Flex direction={'row'} mb={5}>
                                                        <Box  w={'70%'} >
                                                            <Flex direction={'column'}>
                                                              
                                                                    <Flex direction={'column'} ml={{lg:'0', base:'5'}}>
                                                                        <Box alignSelf={'start'} fontSize={{lg:'17px', base:'25px', '2xl': '33px'}} color={'gray.500'} fontWeight={'normal'} fontFamily={'system-ui, sans-serif'}  mt={{lg:'8', base:'5'}}>Bienvenido! </Box>
                                                                        <Box alignSelf={'start'} fontSize={{lg:'25px', base:'25px', '2xl': '33px'}} color={'gray.600'} fontWeight={'bold'} fontFamily={'system-ui, sans-serif'}  >{datos[0].nombre} </Box>
                                                                        <Box color={'gray.600'} fontWeight={'bold'} fontSize={'sm'} fontFamily={'system-ui, sans-serif'} mt={5} mb={2}>Detalles del Proyecto:</Box>
                                                                        <Flex direction={'row'} >
                                                                            <Box color={'gray.600'} fontWeight={'bold'} fontSize={'sm'} fontFamily={'system-ui, sans-serif'}>
                                                                                Nombre:
                                                                            </Box>
                                                                            <Link href='/vendedor/clientes/detalles/1' fontSize={'sm'} color={'gray.500'} fontWeight={'normal'} fontFamily={'system-ui, sans-serif'} ml={{lg:'2', base:'2'}} >{proyecto.nombreProyecto} </Link>
                                                                        </Flex>
                                                                        <Flex direction={'row'}>
                                                                            <Box color={'gray.600'} fontWeight={'bold'} fontSize={'sm'} fontFamily={'system-ui, sans-serif'}>
                                                                                Agente:
                                                                            </Box>
                                                                            <Text color={'gray.500'} fontWeight={'normal'} fontSize={'sm'} fontFamily={'system-ui, sans-serif'} ml={{lg:'2', base:'2'}}> {vendedor.username} </Text>
                                                                        </Flex>
                                                                        <Flex direction={'row'}>
                                                                            <Box color={'gray.600'} fontWeight={'bold'} fontSize={'sm'} fontFamily={'system-ui, sans-serif'}>
                                                                                    Estado:
                                                                            </Box>
                                                                            <Flex justify={'start'}>
                                                                                    <Box h={'2vh'} justifySelf={'center'} fontSize={'15px'} mt={'1'} mr={1} color={'green.500'}>
                                                                                        <GoPrimitiveDot color={'green.400'} />
                                                                                    </Box>
                                                                                <Text fontFamily={'Roboto'} fontWeight={'normal'} fontSize={'sm'} color={'gray.500'}>{proyecto.estatus} </Text>
                                                                            </Flex>
                                                                        </Flex>
                                                                        <Flex mr={5} mt={{base:7,lg:5}} wrap={'wrap'}>
                                                                        <Flex direction={'row'}  >
                                                                            
                                                                            <Flex justify={'start'}>
                                                                                    <Button variant='outline' colorScheme={'orange'}>Platica con tu asesor</Button>
                                                                            </Flex>
                                                                        </Flex>
                                                                        <Flex direction={'row'}  mx={{base:0,lg:2}} mt={{base:2,lg:0}} >
                                                                            
                                                                            <Flex justify={'start'} >
                                                                                    <Button variant='outline' colorScheme={'gray'}><GiOrganigram size={25} /></Button>
                                                                            </Flex>
                                                                        </Flex>
                                                                        <Flex direction={'row'} mx={{base:2,lg:2}}mt={{base:2,lg:0}} >
                                                                            
                                                                            <Flex justify={'start'}>
                                                                                    <Button variant='outline'><IoMdHelpCircle /></Button>
                                                                            </Flex>
                                                                        </Flex>
                                                                        </Flex>
                                                                    </Flex>
                                                                    <Box bg={'white'} w={'100%'} my={5}>
                                                                        <Box fontSize={{lg:'13px', base:'15px', '2xl': '15px'}} color={'gray.600'} fontWeight={'bold'} fontFamily={'system-ui, sans-serif'} ml={{lg:'0', base:'5'}}>
                                                                        
                                                                        </Box>
                                                                    </Box>
                                                            </Flex>
                                                        </Box>
                                                        <Box bg={'white'} w={'30%'} h={'12.5vh'}>
                                                            <Flex direction={'column'} justifyContent={'end'}>
                                                                <Box bg={'white'} w={'100%'} h={'4.16vh'} align={'end'} mt={8} pr={{base:10,lg:0}}>
                                                                <Tooltip label="Crear nuevo proyecto" aria-label='A tooltip' placement='right' borderRadius={'sm'}>
                                                                    
                                                                    <Menu>
                                                                                <MenuButton
                                                                                    px={4}
                                                                                    py={2}
                                                                                    transition='all 0.2s'
                                                                                    borderRadius='md'
                                                                                    borderWidth='1px'
                                                                                    _hover={{ bg: 'gray.400', color: 'white' }}
                                                                                    _expanded={{ bg: 'gray.500', color: 'white' }}
                                                                                    
                                                                                    _focus={{ boxShadow: 'outline' }}
                                                                                    as={Button}

                                                                                >
                                                                                    <HiDotsHorizontal />
                                                                                </MenuButton>
                                                                                <MenuList>
                                                                                    <MenuItem>Chatear con tu Agente</MenuItem>
                                                                                    <MenuDivider />
                                                                                    <MenuItem>Organigrama</MenuItem>
                                                                                </MenuList>
                                                                            </Menu>
                                                                </Tooltip>
                                                                </Box>
                                                                <Box bg={'white'} w={'100%'} h={'4.16vh'}></Box>
                                                                <Box bg={'white'} w={'100%'} h={'4.16vh'}></Box>
                                                            </Flex>
                                                        </Box>
                                                </Flex>
                                                </Box>
                                                <Flex>
                                              
                                                </Flex>
                                                <Divider />
                                            </Flex>
                                    </Box>
                                    <Box display={{base:'none', lg:'flex'}} bg={'white'} w={{base:'0', lg:'20%'}} h={{base: '0', lg:'25vh'}}></Box> {/* Box 3 */}
                                    
                                </Flex>
                            </Box>
                            <Box mt={{base: 28, lg:'0'}} bg={'gray.50'} h={{base: '65vh', lg:'65vh'}} pl={{base:0, lg:0}} pr={{base:0, lg:0}}>
                                <Flex direction={{base:'column', lg:'row'}} >
                                    <Box display={{base:'none', lg:'flex'}} bg={'white'} w={{ base: '0', lg: '5%' }} h={{ base: '0', lg: '65vh' }}></Box> {/* Box 1 */}
                                    <Box bg={'white'}   w={{ base: '100%', lg: '90%' }} h={{ base: '0', lg: '65vh' }}>
                                        
                                        <Box bg={'white'} w={'100%'} mt={5} >
                                            <Center>  
                                                <Flex direction={'column'}  w={'100%'} mx={5} mt={{base: 10, lg:5}}>
                                                    <Box textAlign={'center'} my={{base: '5', lg:10}} mx={{base: '2'}} w={'100%'} fontSize={'21px'} fontWeight={'medium'} fontFamily={'Roboto'}>
                                                        Contenido del Proyecto
                                                    </Box>
                                                    <Center>
                                                    <TabsUnstyled defaultValue={0}>
                                                            <TabsList>
                                                                <Tab w={'100%'} onClick={refetch_a}>Presentaciones</Tab>
                                                                <Tab>Propuestas</Tab>
                                                                <Tab>Contratos</Tab>
                                                            </TabsList>
                                                            <TabPanel value={0}>
                                                                <Flex direction={'column'} >
                                                                    <Box>
                                                                        <Box my={5} fontSize={'md'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'}>Listado de tus presentaciones</Box>
                                                                    </Box>
                                                                    <Center>
                                                                        <Box borderWidth={1} borderRadius={'12px'} zIndex={1} py={3}>
                                                                                        <Table variant='simple' size={'lg'} fontFamily={'system-ui, sans-serif'} p={10}>
                                                                                            <Thead>
                                                                                                <Tr>
                                                                                                    <Th fontSize={'sm'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} >Nombre de la presentación</Th>
                                                                                                    <Th fontSize={'sm'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} >Status</Th>
                                                                                                   
                                                                                                </Tr>
                                                                                            </Thead>
                                                                                            {datos_c
                                                                                            .map((p)=>{
                                                                                                const url = p.url
                                                                                                return(
                                                                                                <Tbody size={'sm'}>
                                                                                                    <Tr key={p.id}>
                                                                                                        <Td fontSize={'sm'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} color={'gray.700'} >
                                                                                                            <Link href={`preview?url=https://view.officeapps.live.com/op/embed.aspx?src=${p.url}&action=edit&wdStartOn=2`}>
                                                                                                                <Flex>
                                                                                                                    <Box fontSize={'21px'} fontWeight={'medium'} color={'gray.700'} mr={'3'}>
                                                                                                                        <BiSlideshow />
                                                                                                                    </Box>
                                                                                                                    {p.nombre}
                                                                                                                </Flex>
                                                                                                            </Link>
                                                                                                        </Td>
                                                                                                        <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}><Tag colorScheme={'green'}>{p.estatus}</Tag></Td>
                                                                                                        <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>
                                                                                                       
                                                                                                        </Td>
                                                                                                       
                                                                                                        
                                                                                                    </Tr>
                                                                                                </Tbody>)
                                                                                            })}
                                                                                        </Table>
                                                                        </Box>
                                                                    </Center>
                                                                    
                                                                </Flex>
                                                                
                                                            
                                                            </TabPanel>
                                                            <TabPanel value={1}>
                                                            <Flex>
                                                                    <Box>
                                                                        <Box fontSize={'11pt'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'}>Listado de tus propuestas</Box>
                                                                    </Box>
                                                                </Flex>
                                                            </TabPanel>
                                                            <TabPanel value={2}>
                                                            <Flex>
                                                                    <Box>
                                                                        <Box fontSize={'11pt'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'}>Listado de tus contratos</Box>
                                                                    </Box>
                                                                </Flex>
                                                            </TabPanel>
                                                        </TabsUnstyled>
                                                    </Center>
                                                </Flex>
                                            </Center>
                                        </Box>
                                    </Box>
                                    {/* Tabla para movil*/}
                                    <Box display={{base:'none', lg:'flex'}} bg={'white'} w={{ base: '0', lg: '5%' }} h={{ base: '0', lg: '65vh' }}></Box> {/* Box 3 */}
                                </Flex>   
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        </Flex>
        {/* <Box >
            <Box fontFamily={'Roboto'} fontSize={'33px'} fontWeight={'semibold'} color={'gray.800'} align={'center'} p={'5'}>Panel Inicial</Box>
            
            <Box  w={'100%'}  bg={'gray.50'} p={2}>
        
                    <Flex direction={'column'} justifyContent={'center'}>
                    <Center>
                    <Box w='50%' align={'end'} mb={'5'} mt={'2'}>
                        <Button w={'200px'} variant={'outline'} colorScheme={'green'} onClick={addCliente}>
                           <AddIcon mr={'5'} /> Nuevo Cliente
                        </Button>
                    </Box>
                    </Center>
                        <Center >
                            <Box w={'50%'}>
                        <FormControl id='razon'>
                            <FormLabel>Buscar Cliente</FormLabel>
                            <Input type='text'  autoFocus onKeyPress={handleKeyPress} bg={'white'} />
                            <FormHelperText>Presiona la tecla Enter para inicar la busqueda</FormHelperText>
                        </FormControl>
                        </Box>
                        </Center>
                    </Flex>
                    <VStack >
                        <Center>
                            <Table variant='simple' colorScheme={'orange'} size={'md'} fontFamily={'Roboto'} borderWidth={1} borderRadius={'lg'} p={10}>
                                <Thead>
                                    <Tr>
                                        <Th fontSize={'13px'} fontFamily={'Roboto'}>Razón Social</Th>
                                        <Th fontSize={'13px'} fontFamily={'Roboto'}>Nombre</Th>
                                        <Th fontSize={'13px'} fontFamily={'Roboto'}>Dirección</Th>
                                        <Th fontSize={'13px'} fontFamily={'Roboto'}>Contacto</Th>
                                        <Th fontSize={'13px'} fontFamily={'Roboto'}>Email</Th>
                                        <Th fontSize={'13px'} fontFamily={'Roboto'}>Teléfono</Th>
                                        <Th fontSize={'13px'} fontFamily={'Roboto'}>Acciones</Th>
                                    </Tr>
                                </Thead>
                                {datos.map((item, index) => {
                                    return (
                                        <Tbody size={'sm'}>
                                            <Tr>
                                                <Td fontSize={'13px'} fontWeight={'medium'} color={'orange.500'} ><Avatar name={item.nombre} size='sm' mr={'3'} /> <Link href='/vendedor/clientes/detalles' >{item.razonSocial}</Link></Td>
                                                <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>{item.nombre} </Td>
                                                <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>{item.direccion}</Td>
                                                <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>{item.nombreContacto} </Td>
                                                <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>{item.correo}</Td>
                                                <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>{item.telefono}</Td>
                                                <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}><Button>...</Button></Td>
                                            </Tr>
                                        </Tbody>
                                    )
                                }
                                )}

                            </Table>
                        </Center>
                    </VStack>
            </Box>
            
            </Box> */}
        
</>
    );
};

export default Proyectos;