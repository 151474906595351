import { Box } from '@chakra-ui/layout';
import React from 'react';
import Header from '../components/Header';
import PresentacionesComponent from '../components/Presentaciones/Presentaciones';

const Presentaciones = () => {
    return (
        <Box bg={'gray.50'} h={'100vh'}>
            <Header/>
            <PresentacionesComponent />
        </Box>
    );
};

export default Presentaciones;