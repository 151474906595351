
import * as AWS from 'aws-sdk'
import { customAlphabet } from 'nanoid'
//AWS.config.update({ region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' }); //DESARROLLO
AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIARVGY33F62XNKJJK3' }); //PRODUCCION
const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: '2022-01-01' })
const nanoid = customAlphabet('0123456789', 30);   
const code = customAlphabet('0123456789', 5);  
export const get_contacto = (email, proyecto) => 
    async (dispatch) => {
                dispatch({ type: 'LOAD_CONTACTO_REQUEST' });
                var params = {
                    TableName: 'Contactos_Adicionales',
                    FilterExpression: "#correo = :correo and #idProyecto = :idProyecto" ,
                    ExpressionAttributeNames: {
                        "#correo": "correo",
                        "#idProyecto": "idProyecto"
                    },
                    ExpressionAttributeValues: {
                        ":correo": email,
                        ":idProyecto": proyecto
                    }
                }
        
        
               docClient.scan(params, function (err, data) {
                    //  if (err) {
                    // //     console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
                    //      dispatch({ type: 'GET_CONTACTO_ERROR', payload: "Error en la consulta..." });
                    //  } else {console.log("Datos--Dynamo--CONTACTOS", data.Items.length === 0)
                      if (data.Items.length === 0) {
                         dispatch({ type: 'GET_CONTACTO_NULO', payload: "No encontramos ningun proyecto con este correo"}); // Actualiza el estado con el mensaje de error
                        console.log("entro" ,null)
                        } else {

                            const randomCode = code();
                            console.log("From Action", randomCode)
                            dispatch({ type: 'GET_CODE', payload: randomCode });
                            sendCode(email,randomCode)
                            dispatch({ type: 'GET_CONTACTO', payload: data.Items[0] });
                         // Actualiza el estado con los datos recibidos
                      }

                    }
               // }
               );
    
           
} 
export const get_proyecto = (proyecto) => 
    async (dispatch) => {
            dispatch({ type: 'LOAD_PROYECTO_REQUEST' });
            console.log("Este es el nuevo proyecxto---", proyecto)
            
                try {
                    var params = {
                    TableName: 'Proyectos',
                    FilterExpression: "#id = :id",
                    ExpressionAttributeNames: {
                        "#id": "id",
                    },
                    ExpressionAttributeValues: {
                        ":id": proyecto
                    }
                }
                docClient.scan(params, function (err, data) {
                    if (err) {
                        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
                        dispatch({ type: 'GET_PROYECTO_ERROR' });
                    } else {
                       
                       if (data.Items.length === 0) {
                        dispatch({ type: 'GET_PROYECTO_ERROR' }); // Actualiza el estado con el mensaje de error
                      } else {
                        dispatch({ type: 'GET_PROYECTO', payload: data.Items[0] });
                         // Actualiza el estado con los datos recibidos
                      }

                    }
                });
              } catch (error) {
                console.log("error")
              }           
} 
export const get_presentaciones = (proyectoId, proyecto) => 
    async (dispatch) => {
            dispatch({ type: 'LOAD_PRESENTACIONES_REQUEST' });
            console.log("Este es el nuevo proyecxto---", proyecto)
            
            var params = {
                TableName: 'Presentaciones',
                 FilterExpression: "#nom = :name and #estado = :estado and #proyecto = :proyecto",
                 ExpressionAttributeNames: {
                     "#nom": "cliente",
                     "#estado": "estatus",
                     "#proyecto": "proyecto"
                 },
                 ExpressionAttributeValues: {
                     ":name": proyectoId,
                     ":estado": "visible",
                     ":proyecto": proyecto
                 }
            }
            docClient.scan(params, function(err, data) {
                if (err) {
                    console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
                    dispatch({ type: 'GET_PRESENTACIONES_ERROR' });
                } else {
                   
                   if (data.Items.length === 0) {
                    dispatch({ type: 'GET_PRESENTACIONES_ERROR' }); // Actualiza el estado con el mensaje de error
                  } else {
                    dispatch({ type: 'GET_PRESENTACIONES', payload: data.Items });
                     // Actualiza el estado con los datos recibidos
                  }

                }
            });
    
           
} 
export const get_propuestas = (proyectoId, proyecto) => 
    async (dispatch) => {
            dispatch({ type: 'LOAD_PROPUESTAS_REQUEST' });
           // console.log("Este es el nuevo proyecxto---", proyecto)
            
            var params = {
                TableName: 'Propuestas',
                 FilterExpression: "#nom = :name and #estado = :estado and #proyecto = :proyecto",
                 ExpressionAttributeNames: {
                     "#nom": "cliente",
                     "#estado": "estatus",
                     "#proyecto": "proyecto"
                 },
                 ExpressionAttributeValues: {
                     ":name": proyectoId,
                     ":estado": "visible",
                     ":proyecto": proyecto
                 }
            }
            docClient.scan(params, function(err, data) {
                if (err) {
                    //console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
                    dispatch({ type: 'GET_PROPUESTAS_ERROR' });
                } else {
                   
                   if (data.Items.length === 0) {
                    dispatch({ type: 'GET_PROPUESTAS_ERROR' }); // Actualiza el estado con el mensaje de error
                  } else {
                    dispatch({ type: 'GET_PROPUESTAS', payload: data.Items });
                     // Actualiza el estado con los datos recibidos
                  }

                }
            });
    
           
} 
export const get_contratos = (proyectoId, proyecto) => 
    async (dispatch) => {
            dispatch({ type: 'LOAD_CONTRATOS_REQUEST' });
            console.log("DATOS DE CONTTRATOS---", proyecto, proyectoId)
            var params = {
                TableName: 'Contratos',
                 FilterExpression: "#nom = :name and #estado = :estado and #proyecto = :proyecto",
                 ExpressionAttributeNames: {
                     "#nom": "cliente",
                     "#estado": "estatus",
                     "#proyecto": "proyecto"
                 },
                 ExpressionAttributeValues: {
                     ":name": proyectoId,
                     ":estado": "visible",
                     ":proyecto": proyecto
                 }
            }
            docClient.scan(params, function(err, data) {
                if (err) {
                    console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
                    dispatch({ type: 'GET_CONTRATOS_ERROR' });
                } else {
                   
                   if (data.Items.length === 0) {
                    dispatch({ type: 'GET_CONTRATOS_ERROR' }); // Actualiza el estado con el mensaje de error
                  } else {
                    //console.log("[GET_CONTRATOS]", data.Items)
                    dispatch({ type: 'GET_CONTRATOS', payload: data.Items });
                     // Actualiza el estado con los datos recibidos
                  }

                }
            });
    
           
} 
export const add_contactos = (nombre, correo, proyectoId) => 
    async(dispatch) => {
        dispatch({ type: 'LOAD_CONTACTO_ADICIONAL_REQUEST' });
            console.log("Este es el nuevo proyecxto---", proyectoId)
        const Data = {
            id: nanoid(),
            nombre,
            whatsapp: null,
            correo: correo,
            fechaCreacion: Date.now(),
            puesto: null,
            idProyecto: proyectoId,
            creadoDesde: "Cliente"
        }

        var params = {
            TableName: "Contactos_Adicionales",
            Item: Data
        }
        docClient.put(params, function(err, data) {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
                dispatch({ type: 'SET_CONTACTO_ADICIONAL_ERROR' });
            } else {
               
               
                dispatch({ type: 'SET_CONTACTO_ADICIONAL' });
                 // Actualiza el estado con los datos recibidos
              

            }
        });
        



        
}
export const get_codigo = () => 
    async(dispatch) => {
        const randomCode = code();
        console.log("From Action", randomCode)
       await dispatch({ type: 'GET_CODE', payload: randomCode });
}
export const formato_email = (username) => 
    async(dispatch) => {
    await dispatch({ type: 'GET_CODE_ERROR', payload: username});
}
const sendCode = async(email,code) => {
    const urlPublic = `https://portalcl.nctech.com.mx/oneaccess/`
    const urlC = `https://servicesemail-production.herokuapp.com/api/code/`
    const body = {
        templateId: 'd-c503d092bd5e4ab280724b6fe147e90b',
        to: email,
        url: urlPublic,
        comentarios: `Prueba de Correo ${code}`,
        code: code
    }
    const response = await fetch(urlC, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
            
        },
        body: JSON.stringify(body)
    })
}
export const get_avatar = (vendedor, urlStrapi) => async (dispatch) => {
   
        console.log("vendedor a :", vendedor);
        try {
          const response = await fetch(urlStrapi + '/users/' + vendedor);
          const clientes = await response.json();
      
          // Ejecutar el dispatch solo cuando la solicitud a la API sea exitosa
          if (response.ok) {
            dispatch({
              type: 'GET_AVATAR',
              payload: clientes.Image
            });
          }
          // En caso de que la solicitud a la API falle, manejar el error aquí
        } catch (error) {
          console.log(error);
        }
}