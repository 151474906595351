import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import * as AWS from 'aws-sdk'
import {
    Button,
    Box, Flex, Image,
    Center,
    Avatar, AvatarBadge, AvatarGroup,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    AspectRatio,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from "@chakra-ui/react"
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useParams } from "react-router-dom";
import Botones from '../Cliente/Buttons'
import { useMediaQuery } from '../../hooks/mediaQuery'
import { RenderIf } from '../../hooks/renderIf';
import LogoNctech from '../../assets/LOGOS_NCTECH-01.svg'
import { motion } from "framer-motion";
import { connect } from 'react-redux'
import { get_avatar, get_proyecto } from '../../actions';
import Fondo from '../../assets/FONDOAZUL.png'
import EMBLEMA from '../../assets/EMBLEMA.png'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ReactPlayer from 'react-player'
import { useWindowSize } from 'usehooks-ts'
import { PlayCircle, Fullscreen } from 'lucide-react'
//AWS.config.update({region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId:'AKIAWLUS3YRU4LTKLQHG'}); // DESARROLLO
AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIARVGY33F62XNKJJK3' });
function getQueryParams(qs) {
    qs = qs.split('+').join(' ');

    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
}

const _ShareProject = (props) => {
    const [datos, setDatos] = useState([])
    const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: '2022-01-01' })
    const listElement = useRef();
    const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI)
    const [to, setTo] = useState()
    const [src, setSrc] = useState()
    const [datos_videos, setDatos_videos] = useState([])
    const [vendedorData, setVendedorData] = useState()
    let { id, token, proyectoParametro, nombre, proyecto, proyectoId, vendedor, url, meid, type } = useParams();
    const breakpoint = useMediaQuery('(max-width: 1200px)');
    const breakpoint800 = useMediaQuery('(max-width: 400px)');
    const breakpoint1201 = useMediaQuery('(max-width: 1601px)');
    const { Imagen, proyectoRData } = props || {};
    const [office, setOffice] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const { tamano, altura } = useWindowSize()
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [scale, setScale] = useState(1.0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [orientation, setOrientation] = useState(
        window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
    );
    const {
        isOpen: isProyectoOpen,
        onOpen: onOpenProyectoModal,
        onClose: onCloseProyectoModal,
    } = useDisclosure();
    const [fuente] = useState("ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji")




    const updateWindowSize = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
        setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowSize);
        return () => {
            window.removeEventListener('resize', updateWindowSize);
        };
    }, []);

    const determinePageSize = () => {
        if (orientation === 'portrait') {
            console.log("portrait", windowWidth, windowHeight)
            return { widthv: windowWidth, heightv: windowHeight };
        } else {
            // Ajusta el tamaño para landscape según tus necesidades
            const landscapeWidth = Math.min(windowWidth, windowHeight);
            const landscapeHeight = Math.max(windowWidth, windowHeight);
            console.log("landscape", landscapeWidth, landscapeHeight)
            return { widthv: landscapeWidth, heightv: landscapeHeight };
        }
    };

    const { widthv, heightv } = determinePageSize();


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offSet) {
        setPageNumber(prevPageNumber => prevPageNumber + offSet);
    }

    function changePageBack() {
        changePage(-1)
    }

    function changePageNext() {
        changePage(+1)
    }
    const useWindowDimensions = () => {
        const [windowDimensions, setWindowDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight,
        });

        useLayoutEffect(() => {
            const handleResize = () => {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            };

            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);

        return windowDimensions;
    };
    const { width, height } = useWindowDimensions();
    //console.log(docClient)

    function handlePlay() {
        setIsPlaying(true);
    }

    const fetchData = (tableName) => {
        var params = {
            TableName: tableName,
        }

        docClient.scan(params, function (err, data) {
            if (!err) {
                //return data
                setDatos(data.Items)
                //console.log(data.Items)

            }
        })

    }

    const putData = (tableName, data) => {
        var params = {
            TableName: tableName,
            Item: data
        }

        docClient.put(params, function (err, data) {
            if (err) {
                console.log('Error', err)
            } else {
                console.log('Success', data)
            }
        })
    }
    const fetchDataFormDynamoDb = () => {
        const res = fetchData('Clientes')
        //console.log("Resultado:", res)
        // setDatos(res.Items)
        //return res

    }
    const addDataToDynamoDB = async () => {
        const userData = {
            Nombre: "Juan Carlos Santiago",
            Prueba: "Desde React",
            FechaCreacion: "Hoa"
        }

        await putData('Clientes', userData)
    }
    const regresar = () => {

        window.location.href = `/me/${meid}/${proyectoId}/${nombre}/${proyecto}/${vendedor}`

    }

    const actualizar = () => {
        const query = getQueryParams(document.location.search);
        var params = {
            TableName: 'Bitacora',
            Key: {
                "id": query.id
            },
            UpdateExpression: "set open = :open, fechaFin = :fechaFin",
            ExpressionAttributeValues: {
                ":open": false,
                ":fechaFin": Date.now()

            },
        }
        docClient.update(params, function (err, data) {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                //res.status(200).json({ data })
                // console.log(data)
            }
        });
    }
    const fetchDataVideos = () => {
        // const query = getQueryParams(document.location.search);
        // console.log("Query:", query)
        // const proyecto = query.token.substring(query.token.indexOf(',') + 1)
        // console.log("proyecto1:", proyecto)
        var params = {
            TableName: 'Media',
            FilterExpression: "#nom = :name and #estado = :estado and #proyecto = :proyecto and #tipo = :tipo",
            ExpressionAttributeNames: {
                "#nom": "cliente",
                "#estado": "estatus",
                "#proyecto": "proyecto",
                "#tipo": "tipo"
            },
            ExpressionAttributeValues: {
                ":name": proyectoId,
                ":estado": "visible",
                ":proyecto": proyecto,
                ":tipo": "presentacion"
            }
        }
        docClient.scan(params, function (err, data) {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                // console.log("Query succeeded.", data.Items);
                setDatos_videos(data.Items)
            }
        });
    }
    useEffect(() => {
        const query = getQueryParams(document.location.search);
        setTo(query.token)
        if (type == "presentacion") {
            setSrc("https://view.officeapps.live.com/op/embed.aspx?src=https://nctech-imagenes.s3.amazonaws.com/" + url)
            //setSrc("https://docs.google.com/viewer?src=https://nctech-imagenes.s3.amazonaws.com/ES Parsing_ Part One by Slidesgo.pptx&embedded=true")

            //setSrc("https://nctech-imagenes.s3.amazonaws.com/" + url)
            //https://pus6-powerpoint.officeapps.live.com/p/PowerPointFrame.aspx?PowerPointView=SlideShowView&ui=es%2DES&rs=es%2DES&WOPISrc=http%3A%2F%2Fpus6%2Dview%2Dwopi%2Ewopi%2Eonline%2Eoffice%2Enet%3A808%2Foh%2Fwopi%2Ffiles%2F%40%2FwFileId%3FwFileId%3Dhttps%253A%252F%252Fnctech%252Dimagenes%252Es3%252Eamazonaws%252Ecom%253A443%252FMachote%252Epropuesta%255FMostrilio%255FHello%255Fwebcorporativa%252Epptx&access_token_ttl=0&hid=2095d27b-0368-4b8e-ac30-c7c560b00faa&wdSlideId=264&wdModeSwitchTime=1680411530498
        } else {
            //console.log(url)
            setSrc("https://nctech-imagenes.s3.amazonaws.com/" + url)
        }
        if (type == "video") {
            console.log("Video")
        }
        fetchDataFormDynamoDb()
        //console.log("Vendedor",vendedor)
        props.get_avatar(vendedor, urlStrapi)
        props.get_proyecto(proyecto)
        //fetchVendedor()
        //console.log(query)
        const noRightClick = document.getElementById("preview");

        noRightClick.addEventListener("contextmenu", e => e.preventDefault());
        if (type == "presentacion") {
            const noRightClickIframe = document.getElementById("presentaciones");
            noRightClickIframe.addEventListener("contextmenu", e => e.preventDefault());
        }
        if (type == "propuesta") {
            // Capturamos el evento del clic derecho en el frame
            document.getElementById("pdfs").addEventListener("contextmenu", function (event) {
                // Anulamos el comportamiento predeterminado del clic derecho
                event.preventDefault()
            })
        }
    }, [])

    useEffect(() => {
        function handleContextMenu(event) {
            event.preventDefault();
        }

        const iframe = listElement.current;

        if (iframe) {
            iframe.addEventListener('contextmenu', handleContextMenu);
        }

        return () => {
            if (iframe) {
                iframe.removeEventListener('contextmenu', handleContextMenu);
            }
        };
    }, []);
    const { refetch: refetch_videos } = useQuery('VIDEOS', fetchDataVideos)
    //console.log(proyectoRData)

    const handleZoomIn = () => {
        setScale(scale + 0.1);
    };

    const handleZoomOut = () => {
        if (scale - 0.1 >= 1.0) {
            setScale(scale - 0.1);
        }
    };

    const handleZoomReset = () => {
        setScale(1.0);
    };
    return (
        <>
            <Modal size={'full'} isOpen={isProyectoOpen} onClose={onCloseProyectoModal} isCentered zIndex={'9999'}>
                <div style={{ width: '100%', height: '100%' }}>
                    <ModalContent style={{ width: '100%', height: '100%', padding: 0 }}>
                        <Document file={src} onLoadSuccess={onDocumentLoadSuccess} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                    key={index}
                                    pageNumber={index + 1}
                                    width={widthv}
                                    height={'100vh'}
                                    renderMode="pdf"
                                    style={{ flex: '1 1 auto', marginBottom: '5px' }}
                                    scale={1}
                                />
                            ))}
                        </Document>
                        <ModalCloseButton zIndex={'9999'} />
                    </ModalContent>
                </div>
            </Modal>
            <Box h={'100vh'} w={'100%'} position={'relative'} id={'preview'} bgImage={`url(${Fondo})`} border={0}>



                <Flex direction={'column'} h={'100vh'} w={'100%'}>

                    <RenderIf isTrue={!breakpoint && height >= 970 && height <= 1000} >
                        <RenderIf isTrue={type == "presentacion"}>
                            <Box bgImage={`url(${Fondo})`}
                                position={'absolute'}
                                top={'-1'}
                                w={'100%'}
                                zIndex={5}
                                h={breakpoint1201 ? '13vh' : '13vh'}
                            >
                            </Box>
                        </RenderIf>

                        <Flex direction={'row'} w={'100%'} bgImage={`url(${Fondo})`} position={'relative'}>
                            <Box bgImage={`url(${Fondo})`}
                                zIndex={6}
                                w={'22%'}
                                position={'relative'}
                                left={"-1"}
                                h={'100vh'}>
                                <Center>
                                    <Box mt={5} zIndex={9}>
                                        <Image src={LogoNctech} w={'150px'} h={'60px'} />
                                    </Box>
                                </Center>
                            </Box>
                            <Box w={'60%'}
                                position={'relative'}
                                left={'-2'}
                                top={0}
                                h='100%'
                                zIndex={'4'}
                                m={0}
                                p={0}
                            >

                                <RenderIf isTrue={type == "presentacion"}>


                                    <AspectRatio w={['101%', '100%']} ratio={19 / 11} position={'relative'} top={28}>
                                        <Box bg={'red.500'} borderRadius={15} boxShadow={'lg'} overflow={'hidden'} h={`${height}px`} w={'100%'} m={0}>
                                            <iframe
                                                style={{ placeItems: 'center', display: 'grid', border: '0px', overflow: 'hidden' }}
                                                id={'presentaciones'}
                                                src={src}
                                                width="101%"
                                                height="101%"
                                                allow="*"
                                                ref={listElement}
                                            >
                                            </iframe>
                                        </Box>
                                    </AspectRatio>


                                    <div style={{
                                        position: 'absolute',
                                        bottom: '10%',
                                        right: '0px',
                                        width: '100px',
                                        height: '20%',
                                        backgroundColor: 'transparent',
                                        zIndex: 2
                                    }}>
                                        <p style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            color: '#ff2',
                                            fontSize: '16px',
                                            textAlign: 'center'
                                        }}></p>
                                    </div>
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '77.5%',
                                        backgroundColor: 'transparent',
                                        zIndex: 1
                                    }}>
                                        <p style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            color: '#fff',
                                            fontSize: '20px',
                                            textAlign: 'center'
                                        }}></p>
                                    </div>
                                </RenderIf>
                                <RenderIf isTrue={type == "propuesta" || type == "contrato"}>

                                    <Center>

                                        <Box id={'pdfs'} mt={20} >
                                            <Center>
                                                <Box color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                    p={2}
                                                    borderRadius={5}
                                                    top={10}
                                                    zIndex={999}

                                                    fontSize={'13px'}
                                                > Total de Páginas {numPages}</Box>
                                                <Box zIndex={999}>

                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomIn} mx={1}>Zoom +</Button>
                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomOut} mx={1}>Zoom -</Button>
                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomReset} mx={1}>Reset Zoom</Button>
                                                </Box>
                                            </Center>
                                            {/* <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page height="750" pageNumber={pageNumber} />
                </Document> */}

                                            <Box
                                                position="fixed"
                                                top="50%"
                                                left="50%"
                                                transform="translate(-50%, -50%)"
                                                overflowY="scroll"
                                                maxHeight="90vh"
                                                rounded={15}
                                                mt={10}
                                                scroll={'none'}
                                            // Ajusta la altura máxima según tus necesidades
                                            >

                                                <Document file={src} onLoadSuccess={onDocumentLoadSuccess} pageMode={'fullScreen'}  >
                                                    {Array.from(new Array(numPages), (el, index) => (
                                                        <Page
                                                            key={index}
                                                            pageNumber={index + 1}
                                                            width={600}
                                                            height={750}

                                                            renderMode="pdf"
                                                            clip={{ x: 100, y: 100, width: 400, height: 400 }}
                                                            style={{ marginBottom: '5px' }}
                                                            scale={scale}
                                                        />
                                                    ))}
                                                </Document>

                                            </Box>




                                            <Flex justify={'center'} position={'relative'} top={-5}>
                                                {/* <Center>

                        {pageNumber > 1 &&
                            <Button onClick={changePageBack}
                                bg={'gray.800'} color={'white'}
                                _hover={{ bg: 'gray.700' }}
                                rounded={'full'}
                                h={'40px'}

                                mr={1}
                            >
                                <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z" fill="#fff"></path></g></svg>
                            </Button>
                        }
                        {
                            pageNumber < numPages &&
                            <Button onClick={changePageNext}
                                bg={'gray.800'} color={'white'}
                                _hover={{ bg: 'gray.700' }}
                                rounded={'full'}
                                h={'40px'}

                            >
                                <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#fff"></path></g></svg>

                            </Button>
                        }
                    </Center> */}
                                            </Flex>


                                        </Box>
                                    </Center>
                                </RenderIf>


                            </Box>
                            <Box zIndex={6} w={'15%'} h={'100vh'} transform={'90'} bgImage={`url(${Fondo})`}
                                border={'0'} left={'-3'} position={'relative'}>
                                <Box mt={5} ml={5} >
                                    <Flex justify={'center'}>
                                        {
                                            Imagen === undefined ?
                                                <Avatar src={"user.username"} alignSelf={'center'} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                                :
                                                <Avatar alignSelf={'center'} src={Imagen} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                        }
                                        <Flex direction={'column'}>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>Asesor de Ventas</Box>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>{proyectoRData.vendedorName}</Box>

                                        </Flex>
                                    </Flex>
                                    <Flex justify={'start'} direction={'column'} zIndex={99999} w={'250px'} h={'500px'} mt={16}>
                                        {datos_videos.map((p, i) => {
                                            return (
                                                <Box rounded={'lg'} w={'auto'} bg={'gray.200'} overflow={'hidden'} >
                                                    <Box textAlign={'center'} p={1} fontWeight={'bold'}>Video de Presentación</Box>
                                                    <AspectRatio w={['100%', '100%']} ratio={2 / 2} >
                                                        <video controls={true} autoPlay={false} preload="auto" width="auto" height="50%"
                                                        >
                                                            <source src={p.url} controls />
                                                        </video>
                                                    </AspectRatio>
                                                </Box>
                                            )
                                        })}
                                    </Flex>
                                </Box>
                                <Box zIndex={99999} >
                                    <Botones zIndex={99999} />
                                </Box>
                            </Box>
                        </Flex>
                        <RenderIf isTrue={type == "presentacion"}>
                            <Box h={breakpoint1201 ? '14vh' : '14vh'}
                                position={'absolute'}
                                bgImage={`url(${Fondo})`}
                                w={'100%'}
                                zIndex={5}
                                left={0}
                                bottom={0}
                            >Down
                            </Box>
                        </RenderIf>
                    </RenderIf>


                    <RenderIf isTrue={!breakpoint800 && height >= 907 && height <= 969} >
                        {/* <RenderIf isTrue={type == "presentacion"}>
                            <Box bgImage={`url(${Fondo})`}
                                position={'absolute'}
                                top={'-1'}
                                w={'100%'}
                                zIndex={5}
                                h={breakpoint1201 ? '13vh' : '13vh'}
                            >
                            </Box>
                        </RenderIf> */}

                        <Flex direction={'row'} w={'100%'} bgImage={`url(${Fondo})`} position={'relative'}>
                            {/* <Box bgImage={`url(${Fondo})`}
                                zIndex={6}
                                w={'22%'}
                                position={'relative'}
                                left={"-1"}
                                h={'100vh'}>
                                <Center>
                                    <Box mt={5} zIndex={9}>
                                        <Image src={LogoNctech} w={'150px'} h={'60px'} />
                                    </Box>
                                </Center>
                            </Box> */}
                            <Box w={'60%'}
                                position={'relative'}
                                left={'-2'}
                                top={0}
                                h='100%'
                                zIndex={'4'}
                                m={0}
                                p={0}
                            >

                                {/* <RenderIf isTrue={type == "presentacion"}>


                                    <AspectRatio w={['90%', '100%']} ratio={19 / 11} position={'relative'} top={10}>
                                        <Box bg={'blue.500'} borderRadius={15} boxShadow={'lg'} overflow={'hidden'} h={`${height}px`} w={'100%'} m={0}>
                                            <iframe
                                                style={{ placeItems: 'center', display: 'grid', border: '0px', overflow: 'hidden' }}
                                                id={'presentaciones'}
                                                src={src}
                                                width="101%"
                                                height="101%"
                                                allow="*"
                                                ref={listElement}
                                            >
                                            </iframe>
                                        </Box>
                                    </AspectRatio>


                                    <div style={{
                                        position: 'absolute',
                                        bottom: '10%',
                                        right: '0px',
                                        width: '100px',
                                        height: '20%',
                                        backgroundColor: 'transparent',
                                        zIndex: 2
                                    }}>
                                        <p style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            color: '#ff2',
                                            fontSize: '16px',
                                            textAlign: 'center'
                                        }}></p>
                                    </div>
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '77.5%',
                                        backgroundColor: 'transparent',
                                        zIndex: 1
                                    }}>
                                        <p style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            color: '#fff',
                                            fontSize: '20px',
                                            textAlign: 'center'
                                        }}></p>
                                    </div>
                                </RenderIf> */}
                                {/* <RenderIf isTrue={type == "propuesta" || type == "contrato"}>

                                    <Center>

                                        <Box id={'pdfs'} mt={20} >
                                            <Center>
                                                <Box color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                    p={2}
                                                    borderRadius={5}
                                                    top={10}
                                                    zIndex={999}

                                                    fontSize={'13px'}
                                                > Total de Páginas {numPages}</Box>
                                                <Box zIndex={999}>

                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomIn} mx={1}>Zoom +</Button>
                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomOut} mx={1}>Zoom -</Button>
                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomReset} mx={1}>Reset Zoom</Button>
                                                </Box>
                                            </Center>
                                         

                                            <Box
                                                position="fixed"
                                                top="50%"
                                                left="50%"
                                                transform="translate(-50%, -50%)"
                                                overflowY="scroll"
                                                maxHeight="90vh"
                                                rounded={15}
                                                mt={10}
                                                scroll={'none'}
                                            // Ajusta la altura máxima según tus necesidades
                                            >

                                                <Document file={src} onLoadSuccess={onDocumentLoadSuccess} pageMode={'fullScreen'} >
                                                    {Array.from(new Array(numPages), (el, index) => (
                                                        <Page
                                                            key={index}
                                                            pageNumber={index + 1}
                                                            width={600}
                                                            height={'750'}
                                                            renderMode="pdf"
                                                            clip={{ x: 100, y: 100, width: 400, height: 400 }}
                                                            style={{ marginBottom: '5px' }}
                                                            scale={scale}
                                                        />
                                                    ))}
                                                </Document>

                                            </Box>




                                            <Flex justify={'center'} position={'relative'} top={-5}>
                                               
                                            </Flex>


                                        </Box>
                                    </Center>
                                </RenderIf> */}


                            </Box>
                            {/* <Box zIndex={6} w={'15%'} h={'100vh'} transform={'90'} bgImage={`url(${Fondo})`}
                                border={'0'} left={'-3'} position={'relative'}>
                                <Box mt={5} ml={5} >
                                    <Flex justify={'center'}>
                                        {
                                            Imagen === undefined ?
                                                <Avatar src={"user.username"} alignSelf={'center'} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                                :
                                                <Avatar alignSelf={'center'} src={Imagen} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                        }
                                        <Flex direction={'column'}>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>Asesor de Ventas</Box>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>{proyectoRData.vendedorName}</Box>

                                        </Flex>
                                    </Flex>
                                    <Flex justify={'start'} direction={'column'} zIndex={99999} w={'250px'} h={'500px'} mt={16}>
                                        {datos_videos.map((p, i) => {
                                            return (
                                                <Box rounded={'lg'} w={'auto'} bg={'gray.200'} overflow={'hidden'} >
                                                    <Box textAlign={'center'} p={1} fontWeight={'bold'}>Video de Presentación</Box>
                                                    <AspectRatio w={['100%', '100%']} ratio={2 / 2} >
                                                        <video controls={true} autoPlay={false} preload="auto" width="auto" height="50%"
                                                        >
                                                            <source src={p.url} controls />
                                                        </video>
                                                    </AspectRatio>
                                                </Box>
                                            )
                                        })}
                                    </Flex>
                                </Box>
                                <Box zIndex={99999} >
                                    <Botones zIndex={99999} />
                                </Box>
                            </Box> */}
                        </Flex>

                        {/* <RenderIf isTrue={type == "presentacion"}>
                            <Box h={breakpoint1201 ? '14vh' : '14vh'}
                                position={'absolute'}
                                bgImage={`url(${Fondo})`}
                                w={'100%'}
                                zIndex={5}
                                left={0}
                                bottom={0}
                            >Down
                            </Box>
                        </RenderIf> */}

                    </RenderIf>

                    <RenderIf isTrue={!breakpoint && height >= 812 && height <= 906}>
                        <RenderIf isTrue={type == "presentacion"}>
                            <Box bgImage={`url(${Fondo})`}
                                position={'absolute'}
                                top={'-1'}
                                w={'100%'}
                                zIndex={5}
                                h={breakpoint1201 ? '13vh' : '13vh'}
                            >
                            </Box>
                        </RenderIf>
                        <Flex direction={'row'} w={'100%'} bgImage={`url(${Fondo})`} position={'relative'}>
                            <Box bgImage={`url(${Fondo})`}
                                zIndex={6}
                                w={'22%'}
                                position={'relative'}
                                left={"-1"}
                                h={'100vh'}>
                                <Center>
                                    <Box mt={5} zIndex={9}>
                                        <Image src={LogoNctech} w={'150px'} h={'60px'} />
                                    </Box>
                                </Center>
                            </Box>
                            <Box w={'60%'}
                                position={'relative'}
                                left={'-2'}
                                top={0}
                                height="100%"
                                zIndex={'4'}
                                m={0}
                                p={0}
                            >

                                <RenderIf isTrue={type == "presentacion"}>


                                    <AspectRatio w={['90%', '100%']} ratio={19 / 11} position={'relative'} top={10}>
                                        <Box bg={'blue.500'} borderRadius={15} boxShadow={'lg'} overflow={'hidden'} h={`${height}px`} w={'100%'} m={0}>
                                            <iframe
                                                style={{ placeItems: 'center', display: 'grid', border: '0px', overflow: 'hidden' }}
                                                id={'presentaciones'}
                                                src={src}
                                                width="101%"
                                                height="101%"
                                                allow="*"
                                                ref={listElement}
                                            >
                                            </iframe>
                                        </Box>
                                    </AspectRatio>

                                    <div style={{
                                        position: 'absolute',
                                        bottom: '10%',
                                        right: '0px',
                                        width: '100px',
                                        height: '20%',
                                        backgroundColor: 'transparent',
                                        zIndex: 2
                                    }}>
                                        <p style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            color: '#ff2',
                                            fontSize: '16px',
                                            textAlign: 'center'
                                        }}></p>
                                    </div>
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '74%',
                                        backgroundColor: 'transparent',
                                        zIndex: 1
                                    }}>
                                        <p style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            color: '#fff',
                                            fontSize: '20px',
                                            textAlign: 'center'
                                        }}></p>
                                    </div>
                                </RenderIf>
                                <RenderIf isTrue={type == "propuesta" || type == "contrato"}>

                                    <Center>

                                        <Box id={'pdfs'} mt={20} >
                                            <Center>
                                                <Box color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                    p={2}
                                                    borderRadius={5}
                                                    top={10}
                                                    zIndex={999}

                                                    fontSize={'13px'}
                                                > Total de Páginas {numPages}</Box>
                                                <Box zIndex={999}>

                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomIn} mx={1}>Zoom +</Button>
                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomOut} mx={1}>Zoom -</Button>
                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomReset} mx={1}>Reset Zoom</Button>
                                                </Box>
                                            </Center>
                                            {/* <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
                                                    <Page height="750" pageNumber={pageNumber} />
                                                </Document> */}

                                            <Box
                                                position="fixed"
                                                top="50%"
                                                left="50%"
                                                transform="translate(-50%, -50%)"
                                                overflowY="scroll"
                                                maxHeight="90vh"
                                                rounded={15}
                                                mt={10}
                                                scroll={'none'}
                                            // Ajusta la altura máxima según tus necesidades
                                            >

                                                <Document file={src} onLoadSuccess={onDocumentLoadSuccess} pageMode={'fullScreen'} >
                                                    {Array.from(new Array(numPages), (el, index) => (
                                                        <Page
                                                            key={index}
                                                            pageNumber={index + 1}
                                                            width={600}
                                                            height={'750'}
                                                            renderMode="pdf"
                                                            clip={{ x: 100, y: 100, width: 400, height: 400 }}
                                                            style={{ marginBottom: '5px' }}
                                                            scale={scale}
                                                        />
                                                    ))}
                                                </Document>

                                            </Box>




                                            <Flex justify={'center'} position={'relative'} top={-5}>
                                                {/* <Center>

                                                        {pageNumber > 1 &&
                                                            <Button onClick={changePageBack}
                                                                bg={'gray.800'} color={'white'}
                                                                _hover={{ bg: 'gray.700' }}
                                                                rounded={'full'}
                                                                h={'40px'}

                                                                mr={1}
                                                            >
                                                                <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z" fill="#fff"></path></g></svg>
                                                            </Button>
                                                        }
                                                        {
                                                            pageNumber < numPages &&
                                                            <Button onClick={changePageNext}
                                                                bg={'gray.800'} color={'white'}
                                                                _hover={{ bg: 'gray.700' }}
                                                                rounded={'full'}
                                                                h={'40px'}

                                                            >
                                                                <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#fff"></path></g></svg>

                                                            </Button>
                                                        }
                                                    </Center> */}
                                            </Flex>


                                        </Box>
                                    </Center>
                                </RenderIf>


                            </Box>
                            <Box zIndex={6} w={'15%'} h={'100vh'} transform={'90'} bgImage={`url(${Fondo})`}
                                border={'0'} left={'-3'} position={'relative'}>
                                <Box mt={5} ml={5} >
                                    <Flex justify={'center'}>
                                        {
                                            Imagen === undefined ?
                                                <Avatar src={"user.username"} alignSelf={'center'} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                                :
                                                <Avatar alignSelf={'center'} src={Imagen} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                        }
                                        <Flex direction={'column'}>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>Asesor de Ventas</Box>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>{proyectoRData.vendedorName}</Box>

                                        </Flex>
                                    </Flex>
                                    <Flex justify={'start'} direction={'column'} zIndex={99999} w={'250px'} h={'500px'} mt={12}>
                                        {datos_videos.map((p, i) => {
                                            return (
                                                <Box rounded={'lg'} w={'auto'} bg={'gray.200'} overflow={'hidden'} >
                                                    <Box textAlign={'center'} p={1} fontWeight={'bold'}>Video de Presentación</Box>
                                                    <AspectRatio w={['100%', '100%']} ratio={2 / 2} >
                                                        <video controls={true} autoPlay={false} preload="auto" width="auto" height="50%"
                                                        >
                                                            <source src={p.url} controls />
                                                        </video>
                                                    </AspectRatio>
                                                </Box>
                                            )
                                        })}
                                    </Flex>
                                </Box>
                                <Box zIndex={99999} >
                                    <Botones zIndex={99999} />
                                </Box>
                            </Box>
                        </Flex>
                        <RenderIf isTrue={type == "presentacion"}>
                            <Box h={breakpoint1201 ? '13vh' : '13vh'}
                                position={'absolute'}
                                bgImage={`url(${Fondo})`}
                                w={'100%'}
                                zIndex={5}
                                left={0}
                                bottom={0}
                            >Down
                            </Box>
                        </RenderIf>
                    </RenderIf>

                    <RenderIf isTrue={!breakpoint && height >= 775 && height <= 811} >
                        <RenderIf isTrue={type == "presentacion"}>
                            <Box bgImage={`url(${Fondo})`}
                                position={'absolute'}
                                top={'-1'}
                                w={'100%'}
                                zIndex={5}
                                h={breakpoint1201 ? '12vh' : '12vh'}
                            >
                            </Box>
                        </RenderIf>
                        <Flex direction={'row'} w={'100%'} bgImage={`url(${Fondo})`} position={'relative'}>
                            <Box bgImage={`url(${Fondo})`}
                                zIndex={6}
                                w={'22%'}
                                position={'relative'}
                                left={"-1"}
                                h={'100vh'}>
                                <Center>
                                    <Box mt={5} zIndex={9}>
                                        <Image src={LogoNctech} w={'150px'} h={'60px'} />
                                    </Box>
                                </Center>

                            </Box>
                            <Box w={'60%'}
                                position={'relative'}
                                left={'-2'}
                                top={0}
                                height="100%"
                                zIndex={'4'}
                                m={0}
                                p={0}
                            >
                                {/* COM */}
                                <RenderIf isTrue={type == "presentacion"}>
                                    <AspectRatio w={['90%', '100%']} ratio={19 / 11} position={'relative'} top={10}>
                                        <Box bg={'pink.500'} borderRadius={15} boxShadow={'lg'} overflow={'hidden'} h={`${height}px`} w={'100%'} m={0}>
                                            <iframe
                                                style={{ placeItems: 'center', display: 'grid', border: '0px', overflow: 'hidden' }}
                                                id={'presentaciones'}
                                                src={src}
                                                width="101%"
                                                height="101%"
                                                allow="*"
                                                ref={listElement}
                                                frameborder="0"
                                                onScrollCapture={1.5}
                                            >
                                            </iframe>
                                        </Box>
                                    </AspectRatio>



                                    <div style={{
                                        position: 'absolute',
                                        bottom: '10%',
                                        right: '0px',
                                        width: '100px',
                                        height: '20%',
                                        backgroundColor: 'transparent',
                                        zIndex: 2
                                    }}>
                                        <p style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            color: '#ff2',
                                            fontSize: '16px',
                                            textAlign: 'center'
                                        }}></p>
                                    </div>
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '76.5%',
                                        backgroundColor: 'transparent',
                                        zIndex: 1
                                    }}>
                                        <p style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            color: '#fff',
                                            fontSize: '20px',
                                            textAlign: 'center'
                                        }}></p>
                                    </div>
                                </RenderIf>
                                <RenderIf isTrue={type == "propuesta" || type == "contrato"}>

                                    <Center>

                                        <Box id={'pdfs'} mt={20} >
                                            <Center>
                                                <Box color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                    p={2}
                                                    borderRadius={5}
                                                    top={10}
                                                    zIndex={999}

                                                    fontSize={'13px'}
                                                > Total de Páginas {numPages}</Box>
                                                <Box zIndex={999}>

                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomIn} mx={1}>Zoom +</Button>
                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomOut} mx={1}>Zoom -</Button>
                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomReset} mx={1}>Reset Zoom</Button>
                                                </Box>
                                            </Center>
                                            {/* <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page height="750" pageNumber={pageNumber} />
                </Document> */}

                                            <Box
                                                position="fixed"
                                                top="50%"
                                                left="50%"
                                                transform="translate(-50%, -50%)"
                                                overflowY="scroll"
                                                maxHeight="90vh"
                                                rounded={15}
                                                mt={10}
                                                scroll={'none'}
                                            // Ajusta la altura máxima según tus necesidades
                                            >

                                                <Document file={src} onLoadSuccess={onDocumentLoadSuccess} pageMode={'fullScreen'} >
                                                    {Array.from(new Array(numPages), (el, index) => (
                                                        <Page
                                                            key={index}
                                                            pageNumber={index + 1}
                                                            width={600}
                                                            height={'750'}
                                                            renderMode="pdf"
                                                            clip={{ x: 100, y: 100, width: 400, height: 400 }}
                                                            style={{ marginBottom: '5px' }}
                                                            scale={scale}
                                                        />
                                                    ))}
                                                </Document>

                                            </Box>




                                            <Flex justify={'center'} position={'relative'} top={-5}>
                                                {/* <Center>

                        {pageNumber > 1 &&
                            <Button onClick={changePageBack}
                                bg={'gray.800'} color={'white'}
                                _hover={{ bg: 'gray.700' }}
                                rounded={'full'}
                                h={'40px'}

                                mr={1}
                            >
                                <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z" fill="#fff"></path></g></svg>
                            </Button>
                        }
                        {
                            pageNumber < numPages &&
                            <Button onClick={changePageNext}
                                bg={'gray.800'} color={'white'}
                                _hover={{ bg: 'gray.700' }}
                                rounded={'full'}
                                h={'40px'}

                            >
                                <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#fff"></path></g></svg>

                            </Button>
                        }
                    </Center> */}
                                            </Flex>


                                        </Box>
                                    </Center>
                                </RenderIf>


                            </Box>
                            <Box zIndex={6} w={'15%'} h={'100vh'} transform={'90'} bgImage={`url(${Fondo})`}
                                border={'0'} left={'-3'} position={'relative'}>
                                <Box mt={5} ml={5} >
                                    <Flex justify={'center'}>
                                        {
                                            Imagen === undefined ?
                                                <Avatar src={"user.username"} alignSelf={'center'} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                                :
                                                <Avatar alignSelf={'center'} src={Imagen} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                        }
                                        <Flex direction={'column'}>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>Asesor de Ventas</Box>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>{proyectoRData.vendedorName}</Box>

                                        </Flex>
                                    </Flex>
                                    <Flex justify={'start'} direction={'column'} zIndex={99999} w={'250px'} h={'500px'} mt={8}>
                                        {datos_videos.map((p, i) => {
                                            return (
                                                <Box rounded={'lg'} w={'auto'} bg={'gray.200'} overflow={'hidden'} >
                                                    <Box textAlign={'center'} p={1} fontWeight={'bold'}>Video de Presentación</Box>
                                                    <AspectRatio w={['100%', '100%']} ratio={2 / 2} >
                                                        <video controls={true} autoPlay={false} preload="auto" width="auto" height="50%"
                                                        >
                                                            <source src={p.url} controls />
                                                        </video>
                                                    </AspectRatio>
                                                </Box>
                                            )
                                        })}
                                    </Flex>
                                </Box>
                                <Box zIndex={99999} >
                                    <Botones zIndex={99999} />
                                </Box>
                            </Box>
                        </Flex>
                        <RenderIf isTrue={type == "presentacion"}>
                            <Box h={breakpoint1201 ? '10vh' : '10vh'}
                                position={'absolute'}
                                bgImage={`url(${Fondo})`}
                                w={'100%'}
                                zIndex={5}
                                left={0}
                                bottom={0}
                            >Down
                            </Box>
                        </RenderIf>
                    </RenderIf>

                    <RenderIf isTrue={!breakpoint && height >= 500 && height <= 774} >
                        <RenderIf isTrue={type == "presentacion"}>
                            <Box bgImage={`url(${Fondo})`}
                                position={'absolute'}
                                top={'-1'}
                                w={'100%'}
                                zIndex={5}
                                h={breakpoint1201 ? '12vh' : '12vh'}
                            >
                            </Box>
                        </RenderIf>
                        <Flex direction={'row'} w={'100%'} bgImage={`url(${Fondo})`} position={'relative'}>
                            <Box bgImage={`url(${Fondo})`}
                                zIndex={6}
                                w={'22%'}
                                position={'relative'}
                                left={"-1"}
                                h={'100vh'}>
                                <Center>
                                    <Box mt={5} zIndex={9}>
                                        <Image src={LogoNctech} w={'150px'} h={'60px'} />
                                    </Box>
                                </Center>
                            </Box>
                            <Box w={'60%'}
                                position={'relative'}
                                left={'-2'}
                                top={0}
                                height="100%"
                                zIndex={'4'}
                                m={0}
                                p={0}
                            >

                                <RenderIf isTrue={type == "presentacion"}>

                                    <AspectRatio w={['90%', '100%']} ratio={19 / 11} position={'relative'} top={20} >
                                        <Box bg={'blue.500'} boxShadow={'lg'} borderRadius={15} overflow={'hidden'} h={'100%'} w={'101%'} m={0}>
                                            <iframe
                                                style={{ placeItems: 'center', display: 'grid', border: '0px', overflow: 'hidden' }}
                                                id={'presentaciones'}
                                                src={src}
                                                width="101%"
                                                height="102%"
                                                allow="*"
                                                ref={listElement}
                                            >
                                            </iframe>
                                        </Box>
                                    </AspectRatio>



                                    <div style={{
                                        position: 'absolute',
                                        bottom: '10%',
                                        right: '0px',
                                        width: '100px',
                                        height: '20%',
                                        backgroundColor: 'transparent',
                                        zIndex: 2
                                    }}>
                                        <p style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            color: '#ff2',
                                            fontSize: '16px',
                                            textAlign: 'center'
                                        }}></p>
                                    </div>
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '72%',
                                        backgroundColor: 'transparent',
                                        zIndex: 1
                                    }}>
                                        <p style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            color: '#fff',
                                            fontSize: '20px',
                                            textAlign: 'center'
                                        }}></p>
                                    </div>
                                </RenderIf>
                                <RenderIf isTrue={type == "propuesta" || type == "contrato"}>

                                    <Center>

                                        <Box id={'pdfs'} mt={20} >
                                            <Center>
                                                <Box color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                    p={2}
                                                    borderRadius={5}
                                                    top={10}
                                                    zIndex={999}

                                                    fontSize={'13px'}
                                                > Total de Páginas {numPages}</Box>
                                                <Box zIndex={999}>

                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomIn} mx={1}>Zoom +</Button>
                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomOut} mx={1}>Zoom -</Button>
                                                    <Button color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                        borderRadius={5}
                                                        zIndex={999}
                                                        fontSize={'13px'} onClick={handleZoomReset} mx={1}>Reset Zoom</Button>
                                                </Box>
                                            </Center>
                                            {/* <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
                                                    <Page height="750" pageNumber={pageNumber} />
                                                </Document> */}

                                            <Box
                                                position="fixed"
                                                top="50%"
                                                left="50%"
                                                transform="translate(-50%, -50%)"
                                                overflowY="scroll"
                                                maxHeight="90vh"
                                                rounded={15}
                                                mt={10}
                                                scroll={'none'}
                                            // Ajusta la altura máxima según tus necesidades
                                            >

                                                <Document file={src} onLoadSuccess={onDocumentLoadSuccess} pageMode={'fullScreen'} >
                                                    {Array.from(new Array(numPages), (el, index) => (
                                                        <Page
                                                            key={index}
                                                            pageNumber={index + 1}
                                                            width={600}
                                                            height={'750'}
                                                            renderMode="pdf"
                                                            clip={{ x: 100, y: 100, width: 400, height: 400 }}
                                                            style={{ marginBottom: '5px' }}
                                                            scale={scale}
                                                        />
                                                    ))}
                                                </Document>

                                            </Box>




                                            <Flex justify={'center'} position={'relative'} top={-5}>
                                                {/* <Center>

                                                        {pageNumber > 1 &&
                                                            <Button onClick={changePageBack}
                                                                bg={'gray.800'} color={'white'}
                                                                _hover={{ bg: 'gray.700' }}
                                                                rounded={'full'}
                                                                h={'40px'}

                                                                mr={1}
                                                            >
                                                                <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z" fill="#fff"></path></g></svg>
                                                            </Button>
                                                        }
                                                        {
                                                            pageNumber < numPages &&
                                                            <Button onClick={changePageNext}
                                                                bg={'gray.800'} color={'white'}
                                                                _hover={{ bg: 'gray.700' }}
                                                                rounded={'full'}
                                                                h={'40px'}

                                                            >
                                                                <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#fff"></path></g></svg>

                                                            </Button>
                                                        }
                                                    </Center> */}
                                            </Flex>


                                        </Box>
                                    </Center>
                                </RenderIf>


                            </Box>
                            <Box zIndex={6} w={'15%'} h={'100vh'} transform={'90'} bgImage={`url(${Fondo})`}
                                border={'0'} left={'-3'} position={'relative'}>
                                <Box mt={5} ml={5} >
                                    <Flex justify={'center'}>
                                        {
                                            Imagen === undefined ?
                                                <Avatar src={"user.username"} alignSelf={'center'} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                                :
                                                <Avatar alignSelf={'center'} src={Imagen} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                        }
                                        <Flex direction={'column'}>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>Asesor de Ventas</Box>
                                            <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>{proyectoRData.vendedorName}</Box>

                                        </Flex>
                                    </Flex>
                                    <Flex justify={'start'} direction={'column'} zIndex={99999} w={'200px'} h={'500px'} mt={4}>
                                        {datos_videos.map((p, i) => {
                                            return (
                                                <Box rounded={'lg'} w={'auto'} bg={'gray.200'} overflow={'hidden'} >
                                                    <Box textAlign={'center'} p={1} fontWeight={'bold'}>Video de Presentación</Box>
                                                    <AspectRatio w={['100%', '100%']} ratio={2 / 2} >
                                                        <video controls={true} autoPlay={false} preload="auto" width="auto" height="50%"
                                                        >
                                                            <source src={p.url} controls />
                                                        </video>
                                                    </AspectRatio>
                                                </Box>
                                            )
                                        })}
                                    </Flex>
                                </Box>
                                <Box zIndex={99999} >
                                    <Botones zIndex={99999} />
                                </Box>
                            </Box>
                        </Flex>
                        <RenderIf isTrue={type == "presentacion"}>
                            <Box h={breakpoint1201 ? '7vh' : '7vh'}
                                position={'absolute'}
                                bgImage={`url(${Fondo})`}
                                w={'100%'}
                                zIndex={5}
                                left={0}
                                bottom={0}
                            >Down
                            </Box>
                        </RenderIf>
                    </RenderIf>



                    <RenderIf isTrue={breakpoint} >

                        <RenderIf isTrue={type == "propuesta" || type == "contrato"}>
                            {
                                orientation === 'landscape' ?
                                    <></> :
                                    <Box
                                        bgImage={`url(${Fondo})`}
                                        position={'absolute'}
                                        top={'0'}
                                        w={'99.89%'}
                                        zIndex={9}
                                        h={'15vh'}
                                    >

                                        <Flex direction={'row'} justify={'space-between'} >
                                            <Box mt={5} pl={5} zIndex={9}>
                                                <Image src={LogoNctech} w={'100px'} h={'60px'} />
                                            </Box>
                                            <Flex justify={'center'} p={5} >

                                                <Popover>
                                                    <PopoverTrigger>
                                                        <motion.div
                                                            animate={{ scale: [1, 1.2, 1] }}
                                                            transition={{ duration: 1.5, repeat: 5 }}
                                                            style={{ position: "relative" }}
                                                        >
                                                            {Imagen === undefined ?
                                                                <Box rounded={'full'} borderColor={'green'} border={1} bg={'green'}>
                                                                    <Avatar src={"user.username"} _hover={{ cursor: 'pointer' }} alignSelf={'center'} >
                                                                        <AvatarBadge boxSize='1.25em' bg='green.500' />
                                                                    </Avatar>
                                                                </Box>
                                                                :
                                                                <Box rounded={'full'}>
                                                                    <Avatar name={proyectoRData.vendedorName} size={'md'} mt={0} src={Imagen} _hover={{ cursor: 'pointer' }} alignSelf={'center'} border={'0px'} borderColor={'green.500'}>
                                                                        <PlayCircle style={{ position: 'absolute', top: '10px', left: '-10px', background: '#fb8b24', borderRadius: '100%' }} color='white' />
                                                                    </Avatar>
                                                                </Box>
                                                            }
                                                        </motion.div>
                                                    </PopoverTrigger>
                                                    <PopoverContent>
                                                        <PopoverArrow />

                                                        <PopoverBody>

                                                            <Flex direction={'column'} >
                                                                {datos_videos.map((p, i) => {
                                                                    return (
                                                                        // <Link key={i} _hover={{textDecorationLine:'none'}} href={`preview?token=${query.token}&url=${p.url}`} onClick={() => actualizaPresentacion(p.videoId)}>
                                                                        //                     <Flex w={'380px'} bg={'gray.50'} mb={1} boxShadow={'sm'} _hover={{bg: 'gray.100', textDecorationLine:'none'}} >
                                                                        //                         <Box fontSize={'20pt'} mx={2} my={2} p={3} bg={'green.400'} color={'white'} rounded={'full'}><BiSlideshow /></Box>
                                                                        //                         <Flex direction={'column'} w={'100%'} justify='center'>
                                                                        //                             <Box ml={3} textAlign={'start'} fontSize={'12pt'} fontWeight={'medium'} fontFamily={fuente} color={'gray.700'}>{p.nombreVideo}</Box>
                                                                        //                         </Flex>
                                                                        //                     </Flex>
                                                                        // </Link>
                                                                        <Box overflow={'hidden'} rounded={'lg'} w={'auto'} zIndex={1}>
                                                                            <PopoverCloseButton zIndex={'9999'} />
                                                                            <video controls autoPlay={false} preload="auto" width="auto" height="40%"
                                                                            >
                                                                                <source src={p.url} controls />

                                                                            </video>
                                                                        </Box>
                                                                    )
                                                                })}
                                                            </Flex>
                                                        </PopoverBody>
                                                    </PopoverContent>
                                                </Popover>

                                                <Flex direction={'column'}>
                                                    <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontFamily={fuente} fontSize={'14px'}>Asesor de Ventas</Box>
                                                    <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontFamily={fuente} fontSize={'14px'}>{proyectoRData.vendedorName}</Box>

                                                </Flex>
                                            </Flex>
                                        </Flex>



                                    </Box>

                            }
                        </RenderIf>

                        <RenderIf isTrue={type == "presentacion"}>
                            <>
                                {
                                    orientation === 'landscape' ?
                                        <></> :
                                        <Box
                                            bgImage={`url(${Fondo})`}
                                            position={'absolute'}
                                            top={'0'}
                                            w={'100%'}
                                            zIndex={9}

                                        >

                                            <Flex direction={'row'} justify={'space-between'} >
                                                <Box mt={5} pl={5} zIndex={9}>
                                                    <Image src={LogoNctech} w={'100px'} h={'60px'} />
                                                </Box>
                                                <Flex justify={'center'} p={6}>

                                                    <Popover >
                                                        <PopoverTrigger>
                                                            <motion.div
                                                                animate={{ scale: [1, 1.2, 1] }}
                                                                transition={{ duration: 1.5, repeat: 5 }}
                                                                style={{ position: "relative" }}
                                                            >
                                                                {Imagen === undefined ?
                                                                    <Box rounded={'full'} borderColor={'green'} border={1} bg={'green'}>
                                                                        <Avatar src={"user.username"} _hover={{ cursor: 'pointer' }} alignSelf={'center'} >
                                                                            <AvatarBadge boxSize='1.25em' bg='green.500' />
                                                                        </Avatar>
                                                                    </Box>
                                                                    :
                                                                    <Box rounded={'full'}>
                                                                        <Avatar name={proyectoRData.vendedorName} size={'md'} mt={0} src={Imagen} _hover={{ cursor: 'pointer' }} alignSelf={'center'} border={'0px'} borderColor={'green.500'}>
                                                                            <PlayCircle style={{ position: 'absolute', top: '10px', left: '-10px', background: '#fb8b24', borderRadius: '100%' }} color='white' />
                                                                        </Avatar>
                                                                    </Box>
                                                                }
                                                            </motion.div>
                                                        </PopoverTrigger>
                                                        <PopoverContent>
                                                            <PopoverArrow />

                                                            <PopoverBody>

                                                                <Flex direction={'column'} zIndex={'9999'}>
                                                                    {datos_videos.map((p, i) => {
                                                                        return (
                                                                            // <Link key={i} _hover={{textDecorationLine:'none'}} href={`preview?token=${query.token}&url=${p.url}`} onClick={() => actualizaPresentacion(p.videoId)}>
                                                                            //                     <Flex w={'380px'} bg={'gray.50'} mb={1} boxShadow={'sm'} _hover={{bg: 'gray.100', textDecorationLine:'none'}} >
                                                                            //                         <Box fontSize={'20pt'} mx={2} my={2} p={3} bg={'green.400'} color={'white'} rounded={'full'}><BiSlideshow /></Box>
                                                                            //                         <Flex direction={'column'} w={'100%'} justify='center'>
                                                                            //                             <Box ml={3} textAlign={'start'} fontSize={'12pt'} fontWeight={'medium'} fontFamily={fuente} color={'gray.700'}>{p.nombreVideo}</Box>
                                                                            //                         </Flex>
                                                                            //                     </Flex>
                                                                            // </Link>
                                                                            <Box overflow={'hidden'} rounded={'lg'} w={'auto'} zIndex={1}>
                                                                                <PopoverCloseButton zIndex={'9999'} />
                                                                                <video controls autoPlay={false} preload="auto" width="auto" height="40%"
                                                                                >
                                                                                    <source src={p.url} controls />

                                                                                </video>
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                </Flex>
                                                            </PopoverBody>
                                                        </PopoverContent>
                                                    </Popover>

                                                    <Flex direction={'column'}>
                                                        <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'}>Asesor de Ventas</Box>
                                                        <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'}>{proyectoRData.vendedorName}</Box>

                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                            {/* Iphone 6S */}
                                            <RenderIf isTrue={width === 320} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={3} mt={28} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width === 360} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={3} mt={24} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width === 375} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={3} mt={20} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width === 390} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={5} mt={32} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width === 400} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={5} mt={40} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width === 425} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={3} mt={20} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width === 430} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={5} mt={40} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width === 414} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={5} mt={36} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width === 540} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={3} mt={4} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width === 768} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={4} mt={20} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width === 820} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={4} mt={36} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width === 1024} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={5} mt={44} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width === 912} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={5} mt={52} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width === 280} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={5} mt={14} />
                                                </Center>
                                            </RenderIf>
                                            {/* <RenderIf isTrue={width >= 359 && height <= 740} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={3} mt={24} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width >= 767 && height <= 1024} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={3} mt={24} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width >= 819 && height <= 1180} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={4} mt={36} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width >= 1023 && height <= 1366} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={5} mt={44} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width >= 912 && height <= 1368} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={5} mt={52} />
                                                </Center>
                                            </RenderIf>
                                            <RenderIf isTrue={width >= 279 && height <= 653} >
                                                <Center>
                                                    <Image src={EMBLEMA} p={5} mt={10} />
                                                </Center>
                                            </RenderIf> */}

                                        </Box>
                                }
                            </>
                        </RenderIf>


                        <Box w={'100%'}
                            height="100%"
                            zIndex={'8'}
                        >
                            <RenderIf isTrue={type == "presentacion"}>
                                <>
                                    {
                                        orientation === 'landscape' ?

                                            <Box boxShadow={'lg'} borderRadius={15} overflow={'hidden'} h={'100%'} w={'101%'} m={0} zIndex={9999}>

                                                <iframe
                                                    style={{ placeItems: 'center', display: 'grid', border: '0px', overflow: 'hidden' }}
                                                    id={'presentaciones'}
                                                    src={src}
                                                    width="103%"
                                                    height="105%"
                                                    allow="*"
                                                    ref={listElement}
                                                >
                                                </iframe>
                                            </Box> :
                                            <Box bg={'orange.900'} boxShadow={'lg'} borderRadius={15} overflow={'hidden'} h={'102%'} w={'100%'} m={0}>

                                                <iframe
                                                    style={{ placeItems: 'center', display: 'grid', border: '0px', overflow: 'hidden' }}
                                                    id={'presentaciones'}
                                                    src={src}
                                                    width="101%"
                                                    height="102%"
                                                    allow="*"
                                                    ref={listElement}
                                                >
                                                </iframe>
                                            </Box>
                                    }
                                </>
                            </RenderIf>

                            <RenderIf isTrue={type == "propuesta" || type == "contrato"}>
                                <>
                                    {
                                        orientation === 'landscape' ?
                                            <Box id={'pdfs'}  >

                                                <Box
                                                    position="relative"
                                                    top="0%"
                                                    left="0%"

                                                    overflowY="scroll"
                                                    w={width}

                                                    height='100vh'
                                                    zIndex='9999'
                                                >



                                                    {/* <Document file={src} onLoadSuccess={onDocumentLoadSuccess} height="400" width={'350'}> */}
                                                    <Document file={src} onLoadSuccess={onDocumentLoadSuccess} >
                                                        {Array.from(new Array(numPages), (el, index) => (
                                                            <Page
                                                                key={index}
                                                                pageNumber={index + 1}
                                                                height="400" width={width}
                                                                renderMode="pdf"

                                                            //scale={1.2}
                                                            />
                                                        ))}

                                                    </Document>

                                                </Box>




                                            </Box> :
                                            <Box id={'pdfs'} mt={48} >

                                                <Box
                                                    position="fixed"
                                                    top="50%"
                                                    left="50%"
                                                    transform="translate(-50%, -50%)"
                                                    overflowY="scroll"
                                                    w={widthv}
                                                    maxHeight="56vh"
                                                    rounded={15}
                                                    mt={10}
                                                >
                                                    <Center>

                                                        <Flex justify={'space-between'} w={'100%'}>

                                                            <Box color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                                //position={'fixed'}
                                                                p={2}
                                                                borderRadius={5}
                                                                top={0}
                                                                zIndex={99}

                                                                fontSize={'13px'}
                                                            > Total de Páginas {numPages}</Box>
                                                            <Button onClick={onOpenProyectoModal} bg={'transparent'}><Fullscreen color={'white'} /></Button>
                                                        </Flex>
                                                    </Center>

                                                    {/* <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
                                       <Page height="400" width={'350'} pageNumber={pageNumber} />
                                   </Document> */}

                                                    {/* <Document file={src} onLoadSuccess={onDocumentLoadSuccess} height="400" width={'350'}> */}
                                                    <Document file={src} onLoadSuccess={onDocumentLoadSuccess} style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
                                                        {Array.from(new Array(numPages), (el, index) => (
                                                            <Page
                                                                key={index}
                                                                pageNumber={index + 1}
                                                                width={widthv}
                                                                height={heightv}
                                                                renderMode="pdf"
                                                                clip={{ x: 100, y: 100, width: 400, height: 400 }}
                                                                style={{ marginBottom: '5px' }}
                                                                scale={scale}
                                                            />
                                                        ))}

                                                    </Document>

                                                </Box>

                                                <Flex justify={'center'} position={'relative'} top={-5}>
                                                    {/* <Center>

                                           {pageNumber > 1 &&
                                               <Button onClick={changePageBack}
                                                   bg={'gray.800'} color={'white'}
                                                   _hover={{ bg: 'gray.700' }}
                                                   rounded={'full'}
                                                   h={'40px'}

                                                   mr={1}
                                               >
                                                   <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z" fill="#fff"></path></g></svg>
                                               </Button>
                                           }
                                           {
                                               pageNumber < numPages &&
                                               <Button onClick={changePageNext}
                                                   bg={'gray.800'} color={'white'}
                                                   _hover={{ bg: 'gray.700' }}
                                                   rounded={'full'}
                                                   h={'40px'}

                                               >
                                                   <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#fff"></path></g></svg>

                                               </Button>
                                           }
                                       </Center> */}

                                                </Flex>


                                            </Box>
                                    }


                                </>
                            </RenderIf>
                        </Box>
                        <RenderIf isTrue={type == "presentacion"}>
                            <>
                                {
                                    orientation === 'landscape' ?
                                        <></> :
                                        <Box h={'36.7vh'}
                                            position={'absolute'}
                                            bgImage={`url(${Fondo})`}
                                            bottom={0}
                                            w={'99.89%'}
                                            zIndex={8}>

                                        </Box>
                                }
                            </>
                        </RenderIf>
                        <Box zIndex={9999} bg={'red.50'}>
                            <Botones zIndex={999999} />
                        </Box>
                    </RenderIf>
                </Flex>


            </Box>
        </>

    );
};
//REDUX
const mapStateProps = (state) => ({
    proyectoRData: state.proyectoReducer.proyectoRData,
    Imagen: state.avatarReducer.Image,
    isLoading: state.avatarReducer.isLoading,
    error: state.avatarReducer.error,
})

const ShareProject = connect(mapStateProps, { get_avatar, get_proyecto })(_ShareProject)

export default ShareProject;

