import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Logo from '../../assets/LOGOS_NCTECH-01.svg'
import Cover from '../../assets/image1.jpg'
import {Input, VStack,Toast, SimpleGrid,LinkOverlay, HStack, Image, Stack, useToast, Button, 
        Text, Heading, Container, Box, Center, Collapse, Fade, ScaleFade, Slide, SlideFade, 
        useDisclosure, Flex, Spacer, Link } from "@chakra-ui/react"
import { left } from '@popperjs/core';
import { FaLinkedin, FaLinkedinIn, FaWhatsappSquare } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';
import { FaFacebook, FaGoogle} from 'react-icons/fa'
import {useParams} from "react-router-dom";
async function loginUser(credentials, urlStrapi) {
    return fetch(urlStrapi + '/auth/local', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
        })
        .then(data => data.json())
}

  export default function Login({ setToken }) {
    const toast = useToast()
    let { access_token } = useParams()
    const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI)
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async e => {
      e.preventDefault();
      const token = await loginUser({
        //username,
        //password
      identifier: username,
      password: password,
      },urlStrapi);
      //console.log(token.statusCode || token.jwt)
      if(token.statusCode == 400){
        toas()
      }
      else{
        setToken(token);
        //console.log(token.jwt)
      }
    }


   


    const toas = () => {
      toast({
      title: "Error.",
      description: "Error al iniciar sesión, verifica tus datos y vuelve a intentar",
      status: "error",
      duration: 5000,
      isClosable: true,
      })
    }
    const forgot = () => {
      window.location = 'forgotpassword'
    }
    function redirect(){
      window.location = '/signup'
    }
    function privacidad(){
      console.log("aviso de privacidad")
    }
    function LoginFacebook(){
      window.location = 'https://s-api-users.herokuapp.com/connect/facebook'
    }
    function LoginLinkedin(){
      window.location = 'https://s-api-users.herokuapp.com/connect/linkedin'
    }
    function LoginGoogle(){
      window.location = 'https://s-api-users.herokuapp.com/connect/google'
    }

   

  return(
   
    <Stack direction={{base:'column', lg:'row'}} m={'0px'} spacing={0}>
       <Box mt={0} visibility={{base:'hidden', lg:'visible'}} w={{base:'0px',lg:'100%'}} 
            h={{base:'0px',lg:"100vh"}} className={'bg-login-color'} borderWidth={{base:'0px' ,lg:'1px'}}>
        <Image src={Logo} w={'132px'} mt={'5'} ml={'10'} position={'absolute'} />
         {/* Logo    */}
         
        <Center>
          
          <Box justifyContent={'center'} w={'100%'}>
            <Image src={Cover} w={{base:'100%', md:'100%', lg:'100%'}} h={'100vh'} objectFit={'cover'} />
          </Box>
        </Center>
         {/* Ilustración */}
         <HStack spacing={'10'}>
                    <Box bottom={10} position={'absolute'} left={10} visibility={{ base: 'hidden', lg: 'visible' }}>
                        <FaWhatsappSquare fontSize={'20pt'} color={'white'} />
                    </Box>
                    <Spacer />
                    <Box bottom={10} position={'absolute'} left={10} visibility={{ base: 'hidden', lg: 'visible' }}>
                        <SiGmail fontSize={'20pt'} color={'white'} />
                    </Box>
                    <Box bottom={10} position={'absolute'} left={20} visibility={{ base: 'hidden', lg: 'visible' }}>
                        <FaLinkedin fontSize={'20pt'} color={'white'} />
                    </Box>
            </HStack>
          
         {/* Redes Sociales */}
      </Box>
      {/* Fin primera sección */}
      <Box w={{base: '100%', lg:'60%'}} h="100vh" bg="gray.50" m={0}>
        {/* Inicio-Logo */}
          <Box mt={10}>
            <Image visibility={{base:'visible', lg:'hidden'}} src={Logo} w={'132px'} mt={'5'} ml={'10'} />
          </Box>
         {/* Fin-Logo */}
         {/* Inicio-titulos */}
        <Center mt={{base:24, lg:0, "2xl":24}}>
          <VStack spacing={1}>
            <Text fontFamily={'system-ui, sans-serif'} fontWeight={'medium'} fontSize={'33px'} color={'gray.600'}>Bienvenido</Text>
            <Text mx={5} textAlign={'center'}  fontSize={'sm'} fontWeight={'normal'} color={'gray.500'} fontFamily={'system-ui, sans-serif'}>No te pierdas cualquier actualización sobre tú proyecto, es por ello te invitamos a</Text>
            <Text fontFamily={'system-ui, sans-serif'} fontWeight={'bold'} fontSize={'43px'} color={'gray.700'}>Iniciar sesión</Text>
          </VStack>
        </Center>
         {/* Fin-titulos */}
        {/* Inicio-formulario */}
        <Flex justify={'center'} my={5}>
          <Box mx={2}>
          <Button colorScheme='facebook' leftIcon={<FaFacebook />} onClick={LoginFacebook} >
            Facebook
          </Button>
          </Box>
          <Box mx={2}>
          <Button colorScheme='linkedin' leftIcon={<FaLinkedinIn />} onClick={LoginLinkedin}>
            Linkedin
          </Button>
          </Box>
          <Box mx={2}>
          <Button  variant={'outline'} colorScheme='red' leftIcon={<FaGoogle />} onClick={LoginGoogle}>
            Google
          </Button>
          </Box>
        </Flex>
        <Flex justify={'center'}>
          <Box>o</Box>
          
        </Flex>
        <Stack mt={{base:5, lg:10}}>
          <form onSubmit={handleSubmit}>

            <VStack w={'100%'}  p={3}>
              <Text align={'left'} w={'70%'} color={'gray.600'}>Introduce tu correo electrónico</Text>
              <Center w={'100%'}>
                <Input h={14} onChange={e => setUserName(e.target.value)} w={'70%'} type="email"
                 placeholder="ejemplo@ejemplo.com" />
              </Center>
            </VStack>
            <VStack w={'100%'}  p={3}>
              <Text align={'left'} w={'70%'} color={'gray.600'}>Introduce tu contraseña</Text>
              <Center w={'100%'}>
                <Input h={14} onChange={e => setPassword(e.target.value)} w={'70%'} type="password" 
                placeholder="Password" />
              </Center>
              <HStack right={20} w={'70%'}>
                <Button fontSize={'13px'} fontWeight={'regular'} variant={'link'} colorScheme={'orange'} 
                className='font cursor-pointer' onClick={() => privacidad()}>Aviso de privacidad</Button>
                <Spacer />
                <Button fontSize={'13px'} fontWeight={'regular'} variant={'link'} colorScheme={'orange'} 
                className='font cursor-pointer' onClick={() => forgot()}>¿Olvidaste tú contraseña?</Button>
              </HStack>
            </VStack>
            <Center mt={10}>
            {username == "" || password == "" ? 
                <Button h={'60px'} variant={'outline'} w="300px" colorScheme={'red'} fontWeight={'medium'}
                  className="font">
                  INICIAR</Button> :
                <Button h={'60px'} type="submit" variant={'outline'} w="300px" bg={'yellow.400'} fontWeight={'medium'}
                  className="font">
                  INICIAR
                </Button>}
            </Center>
          </form>
        </Stack>
        {/* fin-formulario */}
        {/* Inicio Redes Sociales Mobile */}
        <Center>
          <SimpleGrid bottom={14} position={'absolute'} columns={3} spacing={5}>
          <Box visibility={{ base: 'hidden', lg: 'hidden' }}>
            <FaWhatsappSquare fontSize={'20pt'} color={'gray'} />
            </Box>
            <Box visibility={{ base: 'hidden', lg: 'hidden' }}>
            <SiGmail fontSize={'20pt'} color={'gray'} />
            </Box>
            <Box visibility={{ base: 'hidden', lg: 'hidden' }}>
            <FaLinkedin fontSize={'20pt'} color={'gray'} />
            </Box>

          </SimpleGrid>
          
        </Center>
         {/* Fin Redes Sociales */}
      </Box> 
    </Stack>

  )
}
Login.propTypes = {
    setToken: PropTypes.func.isRequired
  }
