import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Logo from '../../assets/logo-nctech-1-1.svg'
import Cover from '../../assets/image2.jpg'
import Facebook from '../../assets/facebook.svg'
import Instagram from '../../assets/instagram.svg'
import Twitter from '../../assets/twitter.svg'
import { FaLinkedin, FaLinkedinIn, FaWhatsappSquare } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';
import {
    Input, VStack, Toast, SimpleGrid, LinkOverlay, HStack, Image, Stack, useToast, Button,
    Text, Heading, Container, Box, Center, Collapse, Fade, ScaleFade, Slide, SlideFade,
    useDisclosure, Flex, Spacer, Link
} from "@chakra-ui/react"

export default function Forgot() {
    const toast = useToast()
    const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI)
    const [useremail, setUseremail] = useState("")

    const forgot = () => {
        console.log(useremail)
        axios
            .post(urlStrapi + '/auth/forgot-password', {
                email: useremail, // user's email
            })
            .then(response => {
                window.location = 'forgotconfirmate'
            })
            .catch(error => {
                //console.log('An error occurred:', error.response);
                toasError(error.response.data.data[0].messages[0].message)
            });


    }
    const toasError = (e) => {
        toast({
            title: "Error.",
            description: e,
            status: "error",
            duration: 5000,
            isClosable: true,
        })
    }
    const toas = () => {
        toast({
            title: "Error.",
            description: "El campo correo no puede estar vacio, verifica y vuelve a intentar.",
            status: "error",
            duration: 5000,
            isClosable: true,
        })
    }
    function redirect() {
        window.location = '/'
    }
    return (

        <Stack direction={{ base: 'column', lg: 'row' }} m={'0px'} spacing={0}>
            <Box mt={0} visibility={{ base: 'hidden', lg: 'visible' }} w={{ base: '0px', lg: '100%' }}
                h={{ base: '0px', lg: "100vh" }} className={'bg-login-color'} borderWidth={{ base: '0px', lg: '1px' }}>
                    <Link href="/">
                        <Image src={Logo} w={'132px'} mt={'5'} ml={'10'} position={'absolute'} />
                    </Link>
                {/* Logo 
                <Center mt={{ lg: 16, "2xl": 32 }} >
                    <VStack spacing={1}>
                        <Text align={'left'} fontFamily={'Roboto'} fontWeight={'bold'} fontSize={'43px'} color={'gray.700'}>¿Olvidaste tú contraseña?</Text>
                    </VStack>
                </Center>*/}
                <Spacer mt={{ lg: 0, "2xl": 0 }}></Spacer>

                <Center>
                    <Box >
                    <Image src={Cover} w={{lg:'100%'}} h={'100vh'} objectFit={'cover'} />
                    </Box>
                </Center>
                {/* Ilustración */}
                <HStack spacing={'10'}>
                    <Box bottom={10} position={'absolute'} left={10} visibility={{ base: 'hidden', lg: 'visible' }}>
                        <FaWhatsappSquare fontSize={'20pt'} color={'gray'} />
                    </Box>
                    <Spacer />
                    <Box bottom={10} position={'absolute'} left={10} visibility={{ base: 'hidden', lg: 'visible' }}>
                        <SiGmail fontSize={'20pt'} color={'gray'} />
                    </Box>
                    <Box bottom={10} position={'absolute'} left={20} visibility={{ base: 'hidden', lg: 'visible' }}>
                        <FaLinkedin fontSize={'20pt'} color={'gray'} />
                    </Box>
                </HStack>

                {/* Redes Sociales */}
            </Box>
            {/* Fin primera sección */}
            <Box w={{ base: '100%', lg: '60%' }} h="100vh" bg="gray.50" m={0}>
                {/* Inicio-Logo */}
                <Box mt={10}>
                    <Link href="/">
                        <Image visibility={{ base: 'visible', lg: 'hidden' }} src={Logo} w={'132px'} mt={'5'} ml={'10'} />
                    </Link>
                </Box>
                {/* Fin-Logo */}
                {/* Inicio-titulos */}

                {/* Fin-titulos */}
                {/* Inicio-formulario */}
                <Stack mt={{ base: 36, lg: 16, "2xl": 52 }}>
                    <VStack w={'100%'} p={3}>
                        <Text align={{base:'center'}} fontFamily={'Roboto'} fontWeight={'bold'} fontSize={'38px'} color={'gray.700'}>¿Olvidaste tú contraseña?</Text>
                        <Text align={'center'} w={'70%'} fontWeight={'regular'} fontSize={'17px'} color={'gray.600'} fontFamily={'Roboto'} >Por favor, escribe tu dirección de e-mail y haz clic en <strong>Restablecer contraseña</strong></Text>
                        <Text align={'center'} w={'70%'} fontWeight={'regular'} fontSize={'17px'} color={'gray.600'} fontFamily={'Roboto'}>Te enviaremos un enlace con el cual podras cambiarla.</Text>
                        <Center w={'100%'}>
                            <Input h={14} onChange={e => setUseremail(e.target.value)} w={'70%'} type="email"
                                placeholder="ejemplo@ejemplo.com" />
                        </Center>
                    </VStack>
                    <Center mt={10}>
                        {useremail == "" ?
                            <Button onClick={() => toas()} h={'60px'} variant={'outline'} w="300px" colorScheme={'red'} fontWeight={'medium'}
                                className="font">
                                Restablecer Contraseña</Button> :
                            <Button h={'60px'} onClick={() => forgot()} variant={'outline'} w="300px" bg={'yellow.400'} fontWeight={'medium'}
                                className="font">
                                Restablecer Contraseña
                            </Button>}

                    </Center>
                </Stack>
                {/* fin-formulario */}
                {/* Inicio Redes Sociales Mobile */}
                <Center>
                    <SimpleGrid bottom={14} position={'absolute'} columns={3} spacing={5}>
                        <Box visibility={{ base: 'visible', lg: 'hidden' }}>
                        <FaWhatsappSquare fontSize={'20pt'} color={'gray'} />
                        </Box>
                        <Box visibility={{ base: 'visible', lg: 'hidden' }}>
                        <SiGmail fontSize={'20pt'} color={'gray'} />
                        </Box>
                        <Box visibility={{ base: 'visible', lg: 'hidden' }}>
                        <FaLinkedin fontSize={'20pt'} color={'gray'} />
                        </Box>

                    </SimpleGrid>

                </Center>
                {/* Fin Redes Sociales */}
            </Box>
        </Stack>



        // <section className=" h-screen bg-gray-50 overflow-x-hidden">
        //   <div className="relative py-20 px-4 mx-auto  h-screen">
        //     <div className="relative max-w-4xl mx-auto ">
        //       <div className=" absolute inset-0 bg-purple-200 my-24 -ml-4 -mr-4"></div>
        //       <div className="flex justify-center relative py-16 md:pt-32 md:pb-20 px-4 sm:px-8 bg-white">
        //         <div className="max-w-lg mx-auto text-center">
        //           <h3 className="mb-8 text-4xl md:text-5xl font-bold font-heading text-gray-900 font">MINIPOS</h3>
        //           <Text fontSize={'13pt'} mb={'10'} color={'gray.900'} className="font"><strong>No te preocupes,</strong> te ayudaremos a recuperar tú contraseña.</Text>
        //             <Text fontWeight={'normal'} fontSize={'11pt'} color={'gray.600'} mb={'2'} className='font'>Ingresa tú correo electronico, te enviaremos las intrucciones para recuperar tu contraseña.</Text>
        //             <input onChange={e => setUseremail(e.target.value)} className="font w-full mb-4 px-12 py-6 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md" type="email" placeholder="ejemplo@ejemplo.com"/>
        //             <div className="flex flex-col">
        //             {useremail == "" ?  <button onClick={()=>toas()} className="font mt-12 md:mt-16 bg-purple-800 hover:bg-purple-900 text-white font-bold font-heading py-5 px-8 rounded-md uppercase">RECUPERAR</button> : <button onClick={()=>forgot()} className="font mt-12 md:mt-16 bg-purple-800 hover:bg-purple-900 text-white font-bold font-heading py-5 px-8 rounded-md uppercase">RECUPERAR</button>}
        //             <Button onClick={redirect} variant={'link'} colorScheme={'purple'} mt={'10'} className="font">regresar al incio!</Button>
        //             </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </section>

    )
}
